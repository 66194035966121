import React from "react";
// import AsideTable from "../aside-table/aside-table";
import Ballot from "../../smart/ballot/ballot";
import Spreading from "../spreading/spreading";
import AsideBanners from "../../smart/aside-banners/aside-banners";
import {useAppSelector} from "../../../core/hooks";
import {getCompetitionsLoadingStatus} from "../../../core/store/competitions-data/selectors";
import {getWidgetTablesLoadingStatus} from "../../../core/store/widget-tables-data/selectors";
import {getActualVoteLoadingStatus} from "../../../core/store/votes-data/selectors";
import Loader from "../loader/loader";
import VideoPlayer from '../../smart/video-player/video-player'

function Aside() {
  const competitionsLoading = useAppSelector(getCompetitionsLoadingStatus);
  const widgetTablesLoading = useAppSelector(getWidgetTablesLoadingStatus);
  const actualVoteLoading = useAppSelector(getActualVoteLoadingStatus);
  const asideLoading = competitionsLoading || widgetTablesLoading || actualVoteLoading;

  return (
    <>
      <Loader hidden={!asideLoading}/>
      <div className={`aside ${asideLoading ? 'hidden' : ''}`}>
        {/* <AsideTable/> */}
        <VideoPlayer/>
        <Ballot/>
        <AsideBanners/>
        <Spreading/>
      </div>
    </>
  );
}

export default Aside;
