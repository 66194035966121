import { CompetitionsTablesState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import {CompetitionTable } from "../../../api";

export const getCompetitionsTablesLoadingStatus = (state: State): boolean =>
  state[NameSpace.CompetitionsTables].isCompetitionsTablesLoading;
export const getCompetitionTableLoadingStatus = (state: State): boolean =>
  state[NameSpace.CompetitionsTables].isCompetitionTableLoading;
export const getCompetitionsTablesErrorStatus = (
  state: State
): CompetitionsTablesState["isCompetitionsTablesError"] =>
  state[NameSpace.CompetitionsTables].isCompetitionsTablesError;
export const getCompetitionErrorStatus = (
  state: State
): CompetitionsTablesState["isCompetitionTableError"] =>
  state[NameSpace.CompetitionsTables].isCompetitionTableError;
export const getCompetitionsTables = (state: State): CompetitionTable[] =>
  state[NameSpace.CompetitionsTables].competitionsTables;
export const getCurrentCompetitionTable = (state: State): CompetitionTable | null =>
  state[NameSpace.CompetitionsTables].currentCompetitionTable;
