import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {fetchOrganization, fetchOrganizations} from '../api-actions';
import {OrganizationsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {districts} from "../../constants/common";
import {Option} from "../../types/common";

const initialState: OrganizationsState = {
  organizationsLoading: false,
  organizationLoading: false,
  organizationsError: false,
  organizationError: false,
  organizations: [],
  organization: null,
  filterDistrictStatus: [districts[0]],
  filterSportStatus: {
    value: 'all',
    label: 'Все виды спорта'
  },
  filterFreeStatus: false,
};

export const organizationsData = createSlice({
  name: NameSpace.Organizations,
  initialState,
  reducers: {
    filteringOrganizationsByDistrict: (state, action: PayloadAction<{filterDistrictStatus: Option[]}>) => {
      const {filterDistrictStatus} = action.payload;
      state.filterDistrictStatus = filterDistrictStatus;
    },
    filteringOrganizationsBySport: (state, action: PayloadAction<{filterSportStatus: Option}>) => {
      const {filterSportStatus} = action.payload;
      state.filterSportStatus = filterSportStatus;
    },
    filteringOrganizationsByFree: (state, action: PayloadAction<{filterFreeStatus: boolean}>) => {
      const {filterFreeStatus} = action.payload;
      state.filterFreeStatus = filterFreeStatus;
    },
    resetOrganizationsFilters: (state) => {
      state.filterDistrictStatus = initialState.filterDistrictStatus;
      state.filterSportStatus= initialState.filterSportStatus;
      state.filterFreeStatus= initialState.filterFreeStatus;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOrganizations.pending, (state) => {
        state.organizationsLoading = true;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        state.organizations = action.payload;
        state.organizationsLoading = false;
      })
      .addCase(fetchOrganizations.rejected, (state) => {
        state.organizationsError = true;
        state.organizationsLoading = false;
      })
      .addCase(fetchOrganization.pending, (state) => {
        state.organizationLoading = true;
      })
      .addCase(fetchOrganization.fulfilled, (state, action) => {
        state.organization = action.payload;
        state.organizationLoading = false;
      })
      .addCase(fetchOrganization.rejected, (state) => {
        state.organizationError = true;
        state.organizationLoading = false;
      });
  }
});

export const {
  filteringOrganizationsByDistrict,
  filteringOrganizationsBySport,
  filteringOrganizationsByFree,
  resetOrganizationsFilters,
} = organizationsData.actions;
