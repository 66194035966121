import React from 'react';
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {parse} from "date-fns";

function Data({author, date}: { author?: string, date?: string }) {
  const dateTime = date ? date : '';
  const parsedDateTime = parse(date || '', "dd.MM.yyyy HH:mm:ss", new Date());
  const publishedTime = dateTime ? simpleDateFormat(parsedDateTime, 'HH:mm') : 'Время неизвестно';
  const publishedDate = dateTime ? simpleDateFormat(parsedDateTime, 'dd MMMM yyyy') : 'Дата неизвестно'

  return (
    <div className="data">
      <p className="data__text"><span className="data__blue">Автор:</span>{author}</p>
      <p className="data__text">{publishedTime}</p>
      <p className="data__text">{publishedDate}</p>
    </div>
  );
}

export default Data;
