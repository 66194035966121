import React from "react";
import {NavigationType} from "../../../core/types/navigation";
import {Link} from "react-router-dom";

function NavItem({navigation}: { navigation: NavigationType }) {
  return (
    <li className="nav__item">
      <Link className="nav__link" to={navigation.url}>
        <svg className="nav__icon" width="25" height="25">
          <use xlinkHref={`images/sprite.svg#${navigation.icon}-blue`}/>
        </svg>
        <p className="nav__text">{navigation.title}</p>
      </Link>
    </li>
  );
}

export default NavItem
