import React, {useState} from "react";
import ImagesItem from "../../ordinary/images-item/images-item";
import Fancybox from "../fancybox/fancybox";
import {AlbumPhoto} from "../../../api";
import Texts from "../../ordinary/texts/texts";
import {PHOTOS_PER_PAGE} from "../../../core/constants/common";
import Pagination from "../../ordinary/pagination/pagination";

function ImagesList({photos}: { photos?: AlbumPhoto[] }) {
  const [currentPage, setCurrentPage] = useState(0);
  const photosPerPage = PHOTOS_PER_PAGE;
  const pageCount = Math.ceil((photos?.length || 0) / photosPerPage);
  const indexOfLastPhoto = (currentPage + 1) * photosPerPage;
  const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
  const currentPhotos = photos ? photos.slice(indexOfFirstPhoto, indexOfLastPhoto) : [];

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div hidden={photos ? photos.length === 0 : true}>
        <div className="gap gap--40">
          <Fancybox
            options={{
              Toolbar: true,
              Navigation: false,
              Carousel: {
                infinite: false,
              },
            }}
          >
            <ul className="images">
              {currentPhotos?.map((photo) => <ImagesItem key={photo.photo_id} photo={photo}/>)}
            </ul>
          </Fancybox>
          <Pagination pageCount={pageCount} currentPage={currentPage} onPageClick={handlePageClick}/>
        </div>
      </div>
      <Texts
        texts="На данный момент этот фотоальбом пуст."
        hidden={photos ? photos.length > 0 : false}
      />
    </>
  );
}

export default ImagesList;
