import React, {useEffect} from 'react';
import NewContent from "../../components/ordinary/new-content/new-content";
import Documents from "../../components/ordinary/documents/documents";
import Photos from "../../components/ordinary/photos/photos";
import Videos from "../../components/ordinary/videos/videos";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import AsideNews from "../../components/simple/aside-news/aside-news";
import NewsList from "../../components/ordinary/news-list/news-list";
import Title from "../../components/ordinary/title/title";
import {AppRoute} from "../../core/constants/routes";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  fetchPost,
  fetchPosts
} from "../../core/store/api-actions";
import {useParams} from "react-router-dom";
import Texts from "../../components/ordinary/texts/texts";
import {
  getCurrentPost,
  getPostLoadingStatus,
  getPosts,
  getPostsErrorStatus,
  getPostsLoadingStatus
} from "../../core/store/posts-data/selectors";
import {getTopItems} from "../../core/utils/array-prepare-helpers";
import {ApiPostsIdGetWithEnum} from "../../api";
import Loader from "../../components/simple/loader/loader";

function NewPage() {
  const post = useAppSelector(getCurrentPost);
  const postError = useAppSelector(getPostsErrorStatus) || post === null;
  const currentURL = window.location.href;
  const crumbsRoutes = [AppRoute.Main, AppRoute.NewsPage, {title: post?.title || '', url: currentURL}];
  const posts = useAppSelector(getPosts);
  const topPosts = getTopItems(posts, 3);
  const postsError = useAppSelector(getPostsErrorStatus);
  const dispatch = useAppDispatch();
  const {id: postId} = useParams();

  const postLoading = useAppSelector(getPostLoadingStatus);
  const postsLoading = useAppSelector(getPostsLoadingStatus);
  const newPageLoading = postLoading || postsLoading;

  useEffect(() => {
    dispatch(fetchPosts({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchPost({
      postId: Number(postId),
      _with: [ApiPostsIdGetWithEnum.Documents, ApiPostsIdGetWithEnum.Videos, ApiPostsIdGetWithEnum.Albums]
    }));
  }, [postId]);

  return (
    <div className="main__wrap">
      <Loader hidden={!newPageLoading}/>
      <div className={`gap gap--60 ${newPageLoading ? 'hidden' : ''}`}>
        <div className={`gap gap--25 ${postError ? 'hidden' : ''}`}>
          <Crumbs routes={crumbsRoutes}/>
          <div className="gap gap--60">
            <NewContent post={post}/>
            <Documents documents={post?.documents}/>
            <Photos albums={post?.albums}/>
            <Videos videos={post?.videos}/>
          </div>
        </div>
        <Texts texts={`Произошла ошибка при загрузке новости с индикатором ${postId}`} hidden={!postError}/>
        <div className="gap gap--30">
          <Title title={"Читайте так же"}/>
          <NewsList posts={topPosts}/>
          <Texts texts="На данный момент отсутствуют интересные новости." hidden={postsError || posts.length > 0}/>
          <Texts texts="Произошла ошибка при загрузке интересных новостей" hidden={!postsError}/>
        </div>
      </div>
      <AsideNews/>
    </div>
  );
}

export default NewPage;
