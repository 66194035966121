const isMobile = window.innerWidth <= 880;

export const postsSliderConfig = {
  container: 'slider',
  controlsContainer: `.js-posts-controls`,
  controls: true,
  nav: false,
  items: 1,
  loop: false,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayOverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  touch: isMobile,
  gutter: 5,
};

export const navsSliderConfig = {
  container: 'slider-nav',
  controls: false,
  nav: false,
  items: 2,
  loop: false,
  autoplay: false,
  speed: 1000,
  gutter: 0,
};

export const albumsSliderConfig = {
  container: 'slider',
  controlsContainer: `.js-albums-controls`,
  controls: true,
  nav: false,
  items: 3,
  loop: true,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayOverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  touch: true,
  mouseDrag: true,
  gutter: 25,
  responsive: {
    320: {
      items: 1,
    },
    480: {
      items: 2,
    },
    780: {
      items: 3,
    },
  },
};

export const videosSliderConfig = {
  container: 'slider',
  controlsContainer: `.js-videos-controls`,
  controls: true,
  nav: false,
  items: 3,
  loop: true,
  autoplay: true,
  autoplayTimeout: 10000,
  autoplayOverPause: true,
  autoplayButtonOutput: false,
  speed: 1000,
  touch: true,
  mouseDrag: true,
  gutter: 25,
  responsive: {
    320: {
      items: 1,
    },
    480: {
      items: 2,
    },
    780: {
      items: 3,
    },
  },
};