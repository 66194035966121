import React from 'react';
import RedactorStiles from "../redactor-styles/redactor-styles";
import HashLink from "../hash-link/hash-link";
import Source from "../source/source";
import {Post} from "../../../api";

function NewContent({post}: { post?: Post | null }) {
  return (
    <div className="gap gap--40">
      <RedactorStiles post={post}/>
      <div className="gap">
        <HashLink tags={post?.tags}/>
        <Source source={post?.source}/>
      </div>
    </div>
  );
}

export default NewContent;
