import React from "react";

function ContentPlay({length}: { length?: number | undefined }) {
  return (
    <div className="content__icon">
      <svg className="content__play" width="30" height="30">
        <use xlinkHref="images/sprite.svg#play"/>
      </svg>
      <p className="content__number">{length}</p>
    </div>
  );
}

export default ContentPlay;
