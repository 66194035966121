import { RoutesType } from "../types/app-route";

export const AppRoute: RoutesType = {
  Main: {
    title: "Главная",
    url: "/",
  },
  Error: {
    title: "Страница ошибки",
    url: "*",
  },
  AboutUs: {
    title: "О нас",
    url: "/about-us",
  },
  NewsPage: {
    title: "Новости",
    url: "/news",
  },
  NewPage: {
    title: "Страница новости",
    url: "/news/:id",
  },
  Calendar: {
    title: "Календарь",
    url: "/calendar",
  },
  Competitions: {
    title: "Соревнования",
    url:'#'
    // "/competitions",
  },
  CompetitionTable: {
    title: "Таблица соревнования",
    url:'#'
    // "/competition-table",
  },
  CompetitionPage: {
    title: "Страница соревнования",
    url:'#'
    // "/competitions/:id",
  },
  CompetitionCalendar: {
    title: "Календарь соревнования",
    url:'#'
    // "/competition-calendar",
  },
  CompetitionNewsPage: {
    title: "Страница новости соревнования",
    url:'#'
    // "/competitions/:competitionId/competition-news-page/:postId",
  },
  Albums: {
    title: "Фото",
    url:'#'
    // "/albums",
  },
  Album: {
    title: "Альбом",
    url:'#'
    // "/albums/:id",
  },
  Videos: {
    title: "Видео",
    url: "/videos",
  },
  Video: {
    title: "Страница видео",
    url: "/videos/:id",
  },
  AboutStaticPages: {
    title: "Статические страницы на странице О нас",
    url: "/about-pages",
  },
  AboutStaticPage: {
    title: "Статическая страница на странице О нас",
    url: "/about-pages/:alias",
  },
  StaticPages: {
    title: "Статические страницы",
    url: "/pages",
  },
  StaticPage: {
    title: "Статическая страница",
    url: "/pages/:alias",
  },
  Schools: {
    title: "Школы Санкт-Петербурга",
    url: "/schools",
  },
  SearchPages: {
    title: 'Поиск',
    url: '/search-pages'
  },
  SearchPostsPage: {
    title: 'Поиск новостей',
    url: '/search-pages/posts'
  },
  SearchEventsPage: {
    title: 'Поиск мероприятий',
    url: '/search-pages/events'
  },
  SearchMatchesPage: {
    title: 'Поиск матчей',
    url: '/search-pages/matches'
  },
  VideoPlaylistsPage: {
    title: 'Видео плейлисты',
    url: '/playlists'
  },
  VideoPlaylistPage: {
    title: "Видео плейлист",
    url:"/playlists/:id",
  },
};
