import {createSlice} from '@reduxjs/toolkit';
import {fetchAlbum, fetchAlbums} from '../api-actions';
import {AlbumsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: AlbumsState = {
  isAlbumsLoading: false,
  isAlbumLoading: false,
  isAlbumsError: false,
  isAlbumError: false,
  albums: [],
  currentAlbum: null,
};

export const albumsData = createSlice({
  name: NameSpace.Albums,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAlbums.pending, (state) => {
        state.isAlbumsLoading = true;
      })
      .addCase(fetchAlbums.fulfilled, (state, action) => {
        state.albums = action.payload;
        state.isAlbumsLoading = false;
      })
      .addCase(fetchAlbums.rejected, (state) => {
        state.isAlbumsError = true;
        state.isAlbumsLoading = false;
      })
      .addCase(fetchAlbum.pending, (state) => {
        state.isAlbumLoading = true;
      })
      .addCase(fetchAlbum.fulfilled, (state, action) => {
        state.currentAlbum = action.payload;
        state.isAlbumLoading = false;
      })
      .addCase(fetchAlbum.rejected, (state) => {
        state.isAlbumError = true;
        state.isAlbumLoading = false;
      });
  }
});