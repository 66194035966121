import React from "react";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {filteringOrganizationsByFree} from "../../../core/store/organizations-data/organizations-data";
import {getOrganizationsFilterFreeStatus} from "../../../core/store/organizations-data/selectors";

function Check() {
  const dispatch = useAppDispatch();
  const filterFreeStatus = useAppSelector(getOrganizationsFilterFreeStatus);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = event.target;
    dispatch(filteringOrganizationsByFree({filterFreeStatus: checked}));
  };

  return (
    <label className="check">
      <input
        id="free"
        type="checkbox"
        className="check__input"
        checked={filterFreeStatus}
        onChange={handleCheckboxChange}
      />
      <span className="check__indicator">
        <span className="check__check"></span>
      </span>
      <span className="check__text">Только бесплатные</span>
    </label>
  );
}

export default Check;

