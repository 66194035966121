import React, {useEffect} from 'react';
import Aside from "../../components/simple/aside/aside";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import ImagesList from "../../components/smart/images-list/images-list";
import {AppRoute} from "../../core/constants/routes";
import {useParams} from "react-router-dom";
import {fetchAlbum} from "../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  getAlbumErrorStatus,
  getAlbumLoadingStatus,
  getCurrentAlbum
} from "../../core/store/albums-data/selectors";
import Texts from "../../components/ordinary/texts/texts";
import HeaderPage from "../../components/ordinary/header-page/header-page";
import Loader from "../../components/simple/loader/loader";

function AlbumPage() {
  const {id: albumId} = useParams();
  const dispatch = useAppDispatch();
  const album = useAppSelector(getCurrentAlbum);
  const albumError = useAppSelector(getAlbumErrorStatus);
  const albumLoading = useAppSelector(getAlbumLoadingStatus);
  const currentURL = window.location.href;
  const crumbsRoutes = [AppRoute.Main, AppRoute.Albums, {title: album?.title || '', url: currentURL}];

  useEffect(() => {
    dispatch(fetchAlbum(Number(albumId)));
  }, [albumId]);

  return (
    <div className="main__wrap">
      <Loader hidden={!albumLoading}/>
      <div className={`gap gap--40 ${albumLoading ? 'hidden' : ''}`}>
        <div className={`gap gap--40 ${albumError ? 'hidden' : ''}`}>
          <Crumbs routes={crumbsRoutes}/>
          <div className="gap gap--30">
            <HeaderPage title={album?.title} author={album?.author} date={album?.publishedDt}/>
          </div>
          <ImagesList photos={album?.photos}/>
        </div>
        <Texts texts={`Произошла ошибка при загрузке альбома с индикатором ${albumId}`} hidden={!albumError}/>
      </div>
      <Aside/>
    </div>
  );
}

export default AlbumPage;
