import React from "react";

function ContentHint({length}: { length: number | undefined }) {
  return (
    <div className="content__images">
      <svg className="content__play" width="20" height="20">
        <use xlinkHref="images/sprite.svg#camera"/>
      </svg>
      <p className="content__number">{length}</p>
    </div>
  );
}

export default ContentHint
