import React from "react";
import Subtitle from "../subtitle/subtitle";
import {CompetitionTable} from "../../../api";
import TablePages from "../table-pages/table-pages";

function TeamTable({table}: { table: CompetitionTable }) {
  return (
    <div className="gap gap--20">
      <Subtitle title={table?.public_title}/>
      <TablePages table={table.table}/>
    </div>
  );
}

export default TeamTable;
