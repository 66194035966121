import React from "react";
import {BounceLoader} from "react-spinners";

function Loader({hidden}: { hidden?: boolean }) {
  return (
    <div className={`loader ${hidden ? 'hidden' : ''}`}>
      <BounceLoader color="#0B4C89"/>
    </div>
  );
}

export default Loader;
