import React from 'react';
import DocumentsItem from "../documents-item/documents-item";
import {HdbkDocument} from "../../../api";

function DocumentsList({documents, hidden}: { documents?: HdbkDocument[], hidden: boolean }) {
  return (
    <ul className={`documents ${hidden ? 'hidden' : ''}`}>
      {documents?.map((document) => <DocumentsItem key={document.document_id} document={document}/>)}
    </ul>
  );
}

export default DocumentsList;
