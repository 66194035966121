import React, {
  useEffect,
  useState
} from "react";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import ContentListImg from "../../components/ordinary/content-list-img/content-list-img";
import Aside from "../../components/simple/aside/aside";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {fetchAlbums} from "../../core/store/api-actions";
import {
  getAlbums,
  getAlbumsErrorStatus,
  getAlbumsLoadingStatus,
} from "../../core/store/albums-data/selectors";
import Texts from "../../components/ordinary/texts/texts";
import {sortItemsByPublicationDate} from "../../core/utils/array-prepare-helpers";
import {AppRoute} from "../../core/constants/routes";
import {ALBUMS_PER_PAGE} from "../../core/constants/common";
import Pagination from "../../components/ordinary/pagination/pagination";
import Loader from "../../components/simple/loader/loader";

function AlbumsPage() {
  const albums = useAppSelector(getAlbums);
  const albumsError = useAppSelector(getAlbumsErrorStatus);
  const albumsLoading = useAppSelector(getAlbumsLoadingStatus);
  const sortedAlbums = sortItemsByPublicationDate(albums);
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.Albums];
  const albumsPerPage = ALBUMS_PER_PAGE;
  const pageCount = Math.ceil((sortedAlbums?.length || 0) / albumsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfLastAlbum = (currentPage + 1) * albumsPerPage;
  const indexOfFirstAlbum = indexOfLastAlbum - albumsPerPage;
  const currentAlbums = sortedAlbums ? sortedAlbums.slice(indexOfFirstAlbum, indexOfLastAlbum) : [];

  useEffect(() => {
    dispatch(fetchAlbums());
  }, [dispatch]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!albumsLoading}/>
      <div className={`gap gap--35 ${albumsLoading ? 'hidden' : ''}`}>
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap">
          <Title title="Фото"/>
          <ContentListImg albums={currentAlbums}/>
          <Texts
            texts="Здесь находятся фотоальбомы нашего портала, но на данный момент они еще не опубликованы. Ожидайте, так как в скором времени они появятся!"
            hidden={albumsError || sortedAlbums.length > 0}
          />
          <Texts
            texts="Произошла ошибка при загрузке фотоальбомов."
            hidden={!albumsError}
          />
        </div>
        <Pagination pageCount={pageCount} currentPage={currentPage} onPageClick={handlePageClick}/>
      </div>
      <Aside/>
    </div>
  );
}

export default AlbumsPage;
