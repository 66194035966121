import {useAppDispatch} from "../../../core/hooks";
import {DropdownTypes} from "../../../core/constants/filters";
import React, {useEffect, useState} from "react";
import Dropdown from "../dropdown/dropdown";
import {HdbkSportKind} from "../../../api";
import {transformSportsToOptions} from "../../../core/utils/array-prepare-helpers";
import {PAGE, SPORTS_PER_LIST} from "../../../core/constants/common";
import {fetchSports} from "../../../core/store/api-actions";

function SportsFilter({items}: { items: string }) {
  const dispatch = useAppDispatch();
  const [sports, setSports] = useState<HdbkSportKind[]>([]);
  const [page, setPage] = useState(PAGE);
  const pageSize = SPORTS_PER_LIST;
  const [hasMoreData, setHasMoreData] = useState(true); // State to track if there's more data to load

  const sportsOptions = transformSportsToOptions(sports);

  useEffect(() => {
    const fetchAndAppendSports = async () => {
      if (!hasMoreData) return; // Stop fetching if there's no more data

      const resultAction = await dispatch(fetchSports({page, pageSize}));
      if (fetchSports.fulfilled.match(resultAction)) {
        const newSports = resultAction.payload;
        if (newSports.length === 0) {
          setHasMoreData(false);
        } else {
          setSports((prevSports) => [...prevSports, ...newSports]);
        }
      }
    };

    fetchAndAppendSports();
  }, [dispatch, page, pageSize, hasMoreData]);

  const handleScrollToBottom = (e: WheelEvent | TouchEvent) => {
    const target = e.target as HTMLElement;
    const {scrollTop, scrollHeight, clientHeight} = target;

    if (scrollHeight - scrollTop === clientHeight && hasMoreData) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <li className="filter__item">
      <Dropdown
        options={sportsOptions}
        type={DropdownTypes.Sport}
        items={items}
        onMenuScrollToBottom={handleScrollToBottom}
      />
    </li>
  );
}

export default SportsFilter;
