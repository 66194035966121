import React from "react";
import ContentItemVideo from "../content-item-video/content-item-video";
import {Video} from "../../../api";

function ContentListVideo({videos}: { videos: Video[] }) {
  return (
    <ul className={`content ${videos.length === 0 ? 'hidden' : ''}`}>
      {videos.map((video) => <ContentItemVideo key={video?.video_id} video={video}/>)}
    </ul>
  );
}

export default ContentListVideo
