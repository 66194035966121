import React from "react";
import { AppRoute } from '../../../core/constants/routes'
import { Link, useLocation } from 'react-router-dom'

function ButtonsPlaylist() {
  const location = useLocation()

  const buttons = [
    { title: 'Плейлисты', route: AppRoute.VideoPlaylistsPage.url },
    { title: 'Видео', route: AppRoute.Videos.url }
  ];

  return (
    <ul className="buttons">
    {buttons.map(button => {
      const isActive = location.pathname === button.route;
      const className = isActive ? 'buttons__item--active js-active' : '';
      return (
        <li key={button.route} className={`buttons__item ${className}`}>
          <Link className="buttons__link" to={button.route}>
            {button.title}
          </Link>
        </li>
      );
    })}
  </ul>
  );
}

export default ButtonsPlaylist;
