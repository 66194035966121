import React from "react";
import Title from "../title/title";
import TablePages from "../table-pages/table-pages";
import Subtitle from "../subtitle/subtitle";
import {MatchPost} from "../../../api";

function TablePage({match}: { match: MatchPost }) {
  return (
    <div className="gap gap--30">
      <Title title="таблица соревновний"/>
      <div className="gap gap-20">
        <Subtitle title={match.title}/>
        <TablePages table={match.table}/>
      </div>
    </div>
  );
}

export default TablePage;
