import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { fetchEventPost, fetchEventPosts } from "../api-actions";
import { EventPostsState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import {Option} from "../../types/common";

const initialState: EventPostsState = {
  isEventPostsLoading: false,
  isEventPostLoading: false,
  isEventPostsError: false,
  isEventPostError: false,
  eventPosts: [],
  currentEventPost: null,
  filterSportStatus: {
    value: 'all',
    label: 'Все виды спорта'
  },
  filterSeasonStatus: {
    value: 'all',
    label: 'Все сезоны'
  },
  filterMonthStatus: {
    value: 'all',
    label: 'Все месяцы'
  },
  filterPastFutureStatus: {
    value: 'all',
    label: 'Все'
  },
};

export const eventPostsData = createSlice({
  name: NameSpace.EventPosts,
  initialState,
  reducers: {
    filteringEventsBySport: (state, action: PayloadAction<{filterSportStatus: Option}>) => {
      const {filterSportStatus} = action.payload;
      state.filterSportStatus = filterSportStatus;
    },
    filteringEventsBySeason: (state, action: PayloadAction<{filterSeasonStatus: Option}>) => {
      const {filterSeasonStatus} = action.payload;
      state.filterSeasonStatus = filterSeasonStatus;
    },
    filteringEventsByMonth: (state, action: PayloadAction<{filterMonthStatus: Option}>) => {
      const {filterMonthStatus} = action.payload;
      state.filterMonthStatus = filterMonthStatus;
    },
    filteringEventsByPastFuture: (state, action: PayloadAction<{filterPastFutureStatus: Option}>) => {
      const {filterPastFutureStatus} = action.payload;
      state.filterPastFutureStatus = filterPastFutureStatus;
    },
    resetEventsFilters: (state) => {
      state.filterSportStatus = initialState.filterSportStatus;
      state.filterSeasonStatus = initialState.filterSeasonStatus;
      state.filterMonthStatus = initialState.filterMonthStatus;
      state.filterPastFutureStatus = initialState.filterPastFutureStatus;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEventPosts.pending, (state) => {
        state.isEventPostsLoading = true;
      })
      .addCase(fetchEventPosts.fulfilled, (state, action) => {
        state.eventPosts = action.payload;
        state.isEventPostsLoading = false;
      })
      .addCase(fetchEventPosts.rejected, (state) => {
        state.isEventPostsError = true;
        state.isEventPostsLoading = false;
      })
      .addCase(fetchEventPost.pending, (state) => {
        state.isEventPostLoading = true;
      })
      .addCase(fetchEventPost.fulfilled, (state, action) => {
        state.currentEventPost = action.payload;
        state.isEventPostLoading = false;
      })
      .addCase(fetchEventPost.rejected, (state) => {
        state.isEventPostError = true;
        state.isEventPostLoading = false;
      });
  },
});

export const {
  filteringEventsBySport,
  filteringEventsBySeason,
  filteringEventsByMonth,
  filteringEventsByPastFuture,
  resetEventsFilters,
} = eventPostsData.actions;

