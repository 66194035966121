import React, {
  useState,
  useEffect
} from "react";
import {isSafari} from "../../../core/utils/common-helpers";
import {useLocation} from "react-router-dom";

function Burger() {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleRouteChange = () => {
      setBurgerOpen(false);
      if (isSafari()) {
        document.body.classList.remove("fixed-safari");
        document.documentElement.classList.remove("fixed-safari");
      } else {
        document.body.classList.remove("fixed");
      }
    };

    handleRouteChange();

    const unlisten = () => {
    };

    return () => {
      unlisten();
    };
  }, [location]);

  const handleBurgerClick = () => {
    setBurgerOpen(!burgerOpen);
    if (isSafari()) {
      document.body.classList.toggle("fixed-safari");
      document.documentElement.classList.toggle("fixed-safari");
    } else {
      document.body.classList.toggle("fixed");
    }
  };

  return (
    <button className={`burger ${burgerOpen ? 'open' : ''}`} onClick={handleBurgerClick}>
      <span></span>
    </button>
  );
}

export default Burger;