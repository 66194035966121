import React, {ChangeEvent} from "react";
import {VoteOption} from "../../../api";
import {SendVoteType} from "../../../core/types/common";

function BallotCheckbox({option, setFormData}: {
  option: VoteOption;
  setFormData: React.Dispatch<React.SetStateAction<SendVoteType>>;
}) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      vote_id: Number(name),
      option_id: Number(value)
    }));
  };
  return (
    <label className="ballot__checkbox">
      <p className="ballot__label">{option.title}</p>
      <input
        className="ballot__input"
        type="radio"
        value={option.option_id}
        name={option.vote_id.toString()}
        onChange={handleChange}
      />
      <span className="ballot__indicator">
        <span className="ballot__check"></span>
      </span>
    </label>
  );
}

export default BallotCheckbox;
