import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {
  getCompetitionsTables,
  getCompetitionsTablesErrorStatus,
} from "../../../core/store/competitions-tables-data/selectors";
import {useParams} from "react-router-dom";
import {fetchCompetitionsTables} from "../../../core/store/api-actions";
import {sortItemsByRank} from "../../../core/utils/array-prepare-helpers";
import Texts from "../../ordinary/texts/texts";
import TeamTables from "../../ordinary/team-tables/team-tables";
import TeamTexts from "../../ordinary/team-texts/team-texts";
import {getCurrentCompetition} from "../../../core/store/competitions-data/selectors";

function CompetitionTable() {
  const dispatch = useAppDispatch();
  const {id: competitionId} = useParams();
  const competition = useAppSelector(getCurrentCompetition);
  const competitionsTables = useAppSelector(getCompetitionsTables);
  const competitionsTablesError = useAppSelector(
    getCompetitionsTablesErrorStatus
  );
  const sortedTables = sortItemsByRank(competitionsTables);

  useEffect(() => {
    dispatch(fetchCompetitionsTables(Number(competitionId)));
  }, [competitionId, dispatch]);

  return (
    <div className="gap gap--50">
      <TeamTables tables={sortedTables}/>
      <Texts
        texts="Таблицы отсутствуют"
        hidden={competitionsTablesError || sortedTables.length > 0}
      />
      <Texts
        texts="Произошла ошибка при загрузке таблиц"
        hidden={!competitionsTablesError}
      />
      <div className={`gap gap--20 ${!competition ? "hidden" : ""}`}>
        <TeamTexts description={competition?.description}/>
      </div>
    </div>
  );
}

export default CompetitionTable;
