import { PostsState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { Post } from "../../../api";

export const getPostsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Posts].isPostsLoading;
export const getPostLoadingStatus = (state: State): boolean =>
  state[NameSpace.Posts].isPostLoading;
export const getPostsErrorStatus = (state: State): PostsState["isPostsError"] =>
  state[NameSpace.Posts].isPostsError;
export const getPostErrorStatus = (state: State): PostsState["isPostError"] =>
  state[NameSpace.Posts].isPostError;
export const getPosts = (state: State): Post[] => state[NameSpace.Posts].posts;
export const getPostsOnMain = (state: State): Post[] =>
  state[NameSpace.Posts].postsOnMain;
export const getPostsInSlider = (state: State): Post[] =>
  state[NameSpace.Posts].postsInSlider;
export const getPostsInSidebar = (state: State): Post[] =>
  state[NameSpace.Posts].postsInSidebar;
export const getCurrentPost = (state: State): Post | null =>
  state[NameSpace.Posts].currentPost;
