import React, {
  useEffect,
  useState
} from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  initialFormState,
  SendRequestStatus
} from "../../../core/constants/common";
import {validateEmail} from "../../../core/utils/form-validate-helpers";
import {sendSubscription} from "../../../core/store/api-actions";
import {getSendSubscriptionStatus} from "../../../core/store/subscriptions-data/selectors";

function SpreadingForm() {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const sendSubscriptionStatus = useAppSelector(getSendSubscriptionStatus);

  useEffect(() => {
    if (sendSubscriptionStatus === SendRequestStatus.UnSuccess) {
      setErrors({...errors, general: "Что-то пошло не так. Пожалуйста, попробуйте еще раз позже."});
    } else {
      setErrors({});
    }
  }, [sendSubscriptionStatus]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const {name, value} = event.target;

    setFormData({...formData, [name]: value});
    if (name === "email") {
      const isValid = validateEmail(value);
      setErrors({...errors, [name]: isValid ? "" : "Пожалуйста, укажите корректный email"});
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newErrors: { [key: string]: string } = {};
    Object.keys(formData).forEach((name) => {
      if (name === "email") {
        const isValid = formData[name].trim() !== "" && validateEmail(formData[name]);
        newErrors[name] = isValid ? "" : "Пожалуйста, укажите корректный email";
      }
    });

    const emailErrorPresent = newErrors["email"] !== undefined && newErrors["email"] !== "";

    if (Object.keys(newErrors).length === 0 || !emailErrorPresent) {
      dispatch(sendSubscription(formData));
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <form className="spreading__form" onSubmit={handleSubmit}>
      <div className="spreading__group">
        <input
          type="text"
          className="spreading__input"
          placeholder="Введите email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {Object.entries(errors).map(([fieldName, errorMessage]) => (
          <p className="spreading__help" key={fieldName}>{errorMessage}</p>
        ))}
      </div>
      <button className="button spreading__button" type="submit">подписаться</button>
    </form>
  );
}

export default SpreadingForm;