import React from "react";
import ButtonsTableCalendar from "../buttons-table-calendar/buttons-table-calendar";

function CompetitionViewSwitcher({selectedView, onSwitchView}: {
  selectedView: "table" | "calendar";
  onSwitchView: (view: "table" | "calendar") => void;
}) {
  return (
    <div className="gap gap--30">
      <ButtonsTableCalendar
        selectedView={selectedView}
        onSwitchView={onSwitchView}
      />
    </div>
  );
}

export default CompetitionViewSwitcher;
