import {createSlice} from '@reduxjs/toolkit';
import {fetchVideoPlaylist, fetchVideoPlaylists} from '../api-actions';
import {VideoPlaylistsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: VideoPlaylistsState = {
  isVideoPlaylistsLoading: false,
  isVideoPlaylistLoading: false,
  isVideoPlaylistsError: false,
  isVideoPlaylistError: false,
  videoPlaylists: [],
  currentVideoPlaylist: null,
};

export const videoPlayListsData = createSlice({
  name: NameSpace.Albums,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchVideoPlaylists.pending, (state) => {
        state.isVideoPlaylistsLoading = true;
      })
      .addCase(fetchVideoPlaylists.fulfilled, (state, action) => {
        state.videoPlaylists = action.payload;
        state.isVideoPlaylistsLoading = false;
      })
      .addCase(fetchVideoPlaylists.rejected, (state) => {
        state.isVideoPlaylistsError = true;
        state.isVideoPlaylistsLoading = false;
      })
      .addCase(fetchVideoPlaylist.pending, (state) => {
        state.isVideoPlaylistLoading = true;
      })
      .addCase(fetchVideoPlaylist.fulfilled, (state, action) => {
        state.currentVideoPlaylist = action.payload;
        state.isVideoPlaylistLoading = false;
      })
      .addCase(fetchVideoPlaylist.rejected, (state) => {
        state.isVideoPlaylistError = true;
        state.isVideoPlaylistLoading = false;
      });
  }
});
