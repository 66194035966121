import React, {useEffect} from 'react';
import Aside from "../../components/simple/aside/aside";
import {AppRoute} from "../../core/constants/routes";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import HeaderPage from "../../components/ordinary/header-page/header-page";
import {useParams} from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector} from "../../core/hooks";
import {
  getCurrentVideo,
  getVideoErrorStatus,
  getVideoLoadingStatus
} from "../../core/store/videos-data/selectors";
import {fetchVideo} from "../../core/store/api-actions";
import HtmlRenderer from "../../components/smart/html-renderer/html-renderer";
import Texts from "../../components/ordinary/texts/texts";
import Loader from "../../components/simple/loader/loader";

function VideoPage() {
  const {id: videoId} = useParams();
  const dispatch = useAppDispatch();
  const video = useAppSelector(getCurrentVideo);
  const videoError = useAppSelector(getVideoErrorStatus);
  const videoLoading = useAppSelector(getVideoLoadingStatus);
  const currentURL = window.location.href;
  const crumbsRoutes = [AppRoute.Main, AppRoute.Videos, {title: video?.title || '', url: currentURL}];

  useEffect(() => {
    dispatch(fetchVideo(Number(videoId)));
  }, [videoId]);

  return (
    <div className="main__wrap">
      <Loader hidden={!videoLoading}/>
      <div className={`gap gap--40 ${videoLoading ? 'hidden' : ''}`}>
        <div className={`gap ${videoError ? 'hidden' : ''}`}>
          <Crumbs routes={crumbsRoutes}/>
          <div className="gap gap--30">
            <div className="gap gap--20">
              <HeaderPage title={video?.title} author={video?.author} date={video?.publishedDt}/>
              <div className="video-iframe">
                <HtmlRenderer htmlString={video?.code || ''}/>
              </div>
            </div>
            <div className="redactor-styles">
              <HtmlRenderer htmlString={video?.description || ''}/>
            </div>
          </div>
        </div>
        <Texts
          texts={`Произошла ошибка при загрузке видео с индикатором ${videoId}`}
          hidden={!videoError}
        />
      </div>
      <Aside/>
    </div>
  );
}

export default VideoPage;
