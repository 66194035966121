import React from "react";
import Subtitle from "../subtitle/subtitle";

function TeamTexts({description}: { description?: string }) {
  return (
    <div className="gap gap--30">
      <Subtitle title="Описание соревнования"/>
      <div className="redactor-styles">{description}
      </div>
    </div>
  );
}

export default TeamTexts;
