import React from "react";

function AboutItem() {
  return (
    <>
      <li className="about__item">
        <img className="about__img" src="/images/persons/chief-editor.png" width="160" height="160" alt="Сергиенко Иван Дмитриевич"/>
        <div className="about__content">
          <p className="about__name">Сергиенко Иван Дмитриевич</p>
          <p className="about__post">Главный редактор</p>
        </div>
      </li>
      <li className="about__item">
        <img className="about__img" src="/images/persons/commissioning-editor.png" width="160" height="160" alt="Шестакова Александара Дмитриевна"/>
        <div className="about__content">
          <p className="about__name">Шестакова Александара Дмитриевна</p>
          <p className="about__post">Выпускающий редактор</p>
        </div>
      </li>
    </>
  )
}

export default AboutItem;
