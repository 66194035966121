import {createSlice} from '@reduxjs/toolkit';
import {NameSpace} from "../../constants/api-constants";
import {SubscriptionsState} from "../../types/state";
import {SendRequestStatus} from "../../constants/common";
import {sendSubscription} from "../api-actions";

const initialState: SubscriptionsState = {
  sendStatus: SendRequestStatus.Normal,
};

export const subscriptionData = createSlice({
  name: NameSpace.Subscriptions,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(sendSubscription.pending, (state) => {
        state.sendStatus = SendRequestStatus.Pending;
      })
      .addCase(sendSubscription.fulfilled, (state) => {
        state.sendStatus = SendRequestStatus.Success;
      })
      .addCase(sendSubscription.rejected, (state) => {
        state.sendStatus = SendRequestStatus.UnSuccess;
      });
  }
});
