import { createSlice } from "@reduxjs/toolkit";
import { fetchPost, fetchPosts } from "../api-actions";
import { PostsState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";

const initialState: PostsState = {
  isPostsLoading: false,
  isPostLoading: false,
  isPostsError: false,
  isPostError: false,
  posts: [],
  postsOnMain: [],
  postsInSlider: [],
  postsInSidebar: [],
  currentPost: null,
};

export const postsData = createSlice({
  name: NameSpace.Posts,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.isPostsLoading = true;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.isPostsLoading = false;
        if (action.meta.arg.onMain) {
          state.postsOnMain = action.payload;
        } else if (action.meta.arg.inSlider) {
          state.postsInSlider = action.payload;
        } else if (action.meta.arg.inSidebar) {
          state.postsInSidebar = action.payload;
        } else {
          state.posts = action.payload;
        }
      })
      .addCase(fetchPosts.rejected, (state) => {
        state.isPostsError = true;
        state.isPostsLoading = false;
      })
      .addCase(fetchPost.pending, (state) => {
        state.isPostLoading = true;
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        state.currentPost = action.payload;
        state.isPostLoading = false;
      })
      .addCase(fetchPost.rejected, (state) => {
        state.isPostError = true;
        state.isPostLoading = false;
      });
  },
});
