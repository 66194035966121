import { EventPostsState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { EventPost } from "../../../api";
import {Option} from "../../types/common";

export const getEventPostsLoadingStatus = (state: State): boolean =>
  state[NameSpace.EventPosts].isEventPostsLoading;
export const getEventPostLoadingStatus = (state: State): boolean =>
  state[NameSpace.EventPosts].isEventPostLoading;
export const getEventPostsErrorStatus = (
  state: State
): EventPostsState["isEventPostsError"] =>
  state[NameSpace.EventPosts].isEventPostsError;
export const getEventPostErrorStatus = (
  state: State
): EventPostsState["isEventPostError"] =>
  state[NameSpace.EventPosts].isEventPostError;
export const getEventPosts = (state: State): EventPost[] =>
  state[NameSpace.EventPosts].eventPosts;
export const getCurrentEventPost = (state: State): EventPost | null =>
  state[NameSpace.EventPosts].currentEventPost;
export const getEventsFilterSportStatus = (state: State): Option => state[NameSpace.EventPosts].filterSportStatus;
export const getEventsFilterSeasonStatus = (state: State): Option => state[NameSpace.EventPosts].filterSeasonStatus;
export const getEventsFilterMonthStatus = (state: State): Option => state[NameSpace.EventPosts].filterMonthStatus;
export const getEventsFilterPastFutureStatus = (state: State): Option => state[NameSpace.EventPosts].filterPastFutureStatus;

