import React, {
  useEffect,
  useState
} from "react";

function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(window.scrollY > 200);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const triggerScroll = 200;
    setIsVisible(scrollY > triggerScroll);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="top">
      <a className={`top__link ${isVisible ? '' : 'hidden'}`} onClick={scrollToTop} id="scroll-to-top">
        <svg className="top__icon" width="50" height="50">
          <use xlinkHref="images/sprite.svg#arrow-top"/>
        </svg>
      </a>
    </div>
  );
}

export default ScrollToTop;
