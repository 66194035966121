import React, {
  useEffect,
  useState
} from "react";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import Aside from "../../components/simple/aside/aside";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {fetchVideoPlaylists} from "../../core/store/api-actions";
import Texts from "../../components/ordinary/texts/texts";
import {sortItemsByPublicationDate} from "../../core/utils/array-prepare-helpers";
import {AppRoute} from "../../core/constants/routes";
import {PLAYLISTS_PER_PAGE} from "../../core/constants/common";
import Pagination from "../../components/ordinary/pagination/pagination";
import Loader from "../../components/simple/loader/loader";
import {getVideoPlayLists, getVideoPlayListsErrorStatus, getVideoPlayListsLoadingStatus} from '../../core/store/video-playlists-data/selectors'
import ButtonsPlaylist from '../../components/smart/buttons-playlist/buttons-playlist'
import ContentPlaylist from '../../components/ordinary/content-playlist/content-playlist'

function VideoPlaylistsPage() {
  const playlists = useAppSelector(getVideoPlayLists);
  const playlistsError = useAppSelector(getVideoPlayListsErrorStatus);
  const playlistsLoading = useAppSelector(getVideoPlayListsLoadingStatus);
  const sortedPlaylists = sortItemsByPublicationDate(playlists);
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.VideoPlaylistsPage];
  const playlistsPerPage = PLAYLISTS_PER_PAGE;
  const pageCount = Math.ceil((sortedPlaylists?.length || 0) / playlistsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfLastPlaylist = (currentPage + 1) * playlistsPerPage;
  const indexOfFirstPlaylist = indexOfLastPlaylist - playlistsPerPage;
  const currentPlaylists = sortedPlaylists ? sortedPlaylists.slice(indexOfFirstPlaylist, indexOfLastPlaylist) : [];
  useEffect(() => {
    dispatch(fetchVideoPlaylists());
  }, [dispatch]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!playlistsLoading}/>
      <div className={`gap gap--35 ${playlistsLoading ? 'hidden' : ''}`}>
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap">
          <ButtonsPlaylist/>
          <Title title="Плейлисты"/>
          <ContentPlaylist playlists={currentPlaylists}/>
          <Texts
            texts="Здесь находятся видео плейлисты нашего портала, но на данный момент они еще не опубликованы. Ожидайте, так как в скором времени они появятся!"
            hidden={playlistsError || sortedPlaylists.length > 0}
          />
          <Texts
            texts="Произошла ошибка при загрузке видео плейлистов."
            hidden={!playlistsError}
          />
        </div>
        <Pagination pageCount={pageCount} currentPage={currentPage} onPageClick={handlePageClick}/>
      </div>
      <Aside/>
    </div>
  );
}

export default VideoPlaylistsPage;
