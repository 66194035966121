import React from "react";
import FilterItem4 from "../../smart/filter-item-4/filter-item-4";
import Texts from "../texts/texts";
import {Organization} from "../../../api";

function Filter4({organizationsErrorStatus, organizations, filteredOrganizations}: {
  organizationsErrorStatus: boolean,
  organizations: Organization[],
  filteredOrganizations: Organization[]
}) {
  return (
    <div className="gap">
      <ul className="filter filter--4">
        <FilterItem4/>
      </ul>
      <Texts texts="Произошла ошибка при загрузке школ Санкт-Петербурга." hidden={!organizationsErrorStatus}/>
      <Texts
        texts="По заданным параметрам информации не найдено."
        text="Попробуйте другие."
        hidden={!(organizations.length > 0 && !filteredOrganizations.length)}
      />
    </div>
  );
}

export default Filter4;
