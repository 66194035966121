import React, {useEffect} from "react";
import AsideItem from "../../ordinary/aside-item/aside-item";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getPostsErrorStatus,
  getPostsInSidebar
} from "../../../core/store/posts-data/selectors";
import {fetchPosts} from "../../../core/store/api-actions";
import Texts from "../../ordinary/texts/texts";
import { ApiPostsGetInSidebarEnum } from '../../../api'

function AsideList() {
  const posts = useAppSelector(getPostsInSidebar);
  const postsErrorStatus = useAppSelector(getPostsErrorStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPosts({pageSize:10, inSidebar:ApiPostsGetInSidebarEnum.NUMBER_1}));
  }, [dispatch]);

  return (
    <>
      <ul className="aside__list">
        {posts.map((post) => <AsideItem key={post.post_id} post={post}/>)}
      </ul>
      <Texts
        texts="На данный момент на сайдбаре отсутствуют новости."
        hidden={postsErrorStatus || posts.length > 0}
      />
      <Texts
        texts="Произошла ошибка при загрузке новостей для сайдбара"
        hidden={!postsErrorStatus}
      />
    </>
  );
}

export default AsideList
