import React from "react";
import Social from "../../smart/social/social";

function FooterSocial() {
  return (
    <div className="footer__social">
      <Social/>
      <p className="footer__except">Переходи в наши социальные сети</p>
    </div>
  );
}

export default FooterSocial;
