import React from "react";
import {
  Map,
  Placemark,
  YMaps
} from "@pbe/react-yandex-maps";
import {Organization} from "../../../api";
import BalloonContent from "../balloon-content/balloon-content";

function Maps({organizations}: { organizations: Organization[] }) {
  const yandexMapsApiKey = process.env.REACT_APP_YANDEX_MAPS_API_KEY;

  if (!yandexMapsApiKey) {
    console.error("API ключ Yandex Maps не найден. Убедитесь, что он установлен в переменных окружения.");
    return null;
  }

  const defaultState = {
    center: [59.939042, 30.315817],
    zoom: 12,
    controls: [],
  };

  return (
    <div className="maps">
      <YMaps query={{apikey: yandexMapsApiKey}}>
        <Map
          defaultState={defaultState}
          width="100%"
          height="100%"
          modules={["control.ZoomControl", "control.FullscreenControl"]}
        >
          {organizations.map((organization) => (
            <Placemark
              key={organization.organization_id}
              geometry={[parseFloat(organization.latitude || '0'), parseFloat(organization.longitude || '0')]}
              modules={["geoObject.addon.balloon"]}
              properties={{
                balloonContentBody: BalloonContent.toString(organization),
              }}
              options={{
                hideIconOnBalloonOpen: false,
                iconLayout: 'default#image',
                iconImageHref: '/images/location-blue.svg',
                iconImageSize: window.innerWidth <= 530 ? [30, 30] : [50, 50],
                iconImageOffset: window.innerWidth <= 530 ? [-15, -30] : [-25, -50],
                balloonOffset: window.innerWidth <= 530 ? [0, -25] : [0, -45]
              }}
            />
          ))}
        </Map>
      </YMaps>
    </div>
  );
}

export default Maps;
