import React from 'react';
import FooterNav from "../footer-nav/footer-nav";
import FooterList from "../footer-list/footer-list";
import FooterSocial from "../footer-social/footer-social";
import FooterInfo from "../footer-info/footer-info";

function Footer() {
  return (
    <footer id="footer" className="footer">
      <div className="footer__container">
        <FooterNav/>
        <FooterList/>
        <FooterSocial/>
      </div>
      <FooterInfo/>
    </footer>
  );
}

export default Footer;
