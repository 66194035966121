import React from "react";
import HashtagsItem from "../hashtags-item/hashtags-item";
import {Tag} from "../../../api";

function Hashtags({tags, query}: {
  tags?: Tag[],
  query?: string
}) {
  return (
    <ul className="hashtags">
      {tags?.map((tag) =>
        <HashtagsItem
          key={tag.item_id}
          tag={tag} query={query}
        />
      )}
    </ul>
  );
}

export default Hashtags
