import React from 'react';
import NewsList from "../news-list/news-list";
import {DefaultPost} from "../../../api";

function News({posts}: { posts: DefaultPost[] }) {
  return (
    <NewsList posts={posts}/>
  );
}

export default News;
