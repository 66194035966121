import React from "react";
import {
  Link,
  useLocation
} from "react-router-dom";
import {NavigationType} from "../../../core/types/navigation";

function NavigationItem({navigation}: { navigation: NavigationType }) {
  const location = useLocation();
  const isCurrentPage = navigation.url === location.pathname;

  return (
    <li className={`navigation__item ${isCurrentPage ? 'navigation__item--active' : ''}`}>
      <Link className="navigation__link" to={navigation.url}>
        <div className="navigation__border">
          <svg className="navigation__icon" width="25" height="25">
            <use xlinkHref={`images/sprite.svg#${navigation.icon}`}/>
          </svg>
        </div>
        <p className="navigation__text">{navigation.title}</p>
      </Link>
    </li>
  )
}

export default NavigationItem
