import React from "react";
import {VideoPlaylist} from "../../../api";
import ContentPlaylistItem from '../content-playlist-item/content-playlist-item'

function ContentPlaylist({playlists}: { playlists: VideoPlaylist[] }) {
  return (
    <ul className={`content ${playlists.length === 0 ? 'hidden' : ''}`}>
      {playlists.map((playlist) => <ContentPlaylistItem key={playlist.playlist_id} playlist={playlist}/>)}
    </ul>
  );
}

export default ContentPlaylist
