import React from "react";
import {VoteOption} from "../../../api";

function BallotResult({option, isWinner}: {
  option: VoteOption;
  isWinner: boolean;
}) {
  return (
    <label className={`ballot__checkbox ${isWinner ? 'ballot__checkbox--active' : ''}`}>
      <p className="ballot__label">{option.title}</p>
      <p className="ballot__number">{option.answersCount}</p>
    </label>
  );
}

export default BallotResult
