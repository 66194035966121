import React from "react";
import {Tag} from "../../../api";

function HashLinkItem({tag}: { tag: Tag }) {
  return (
    <li className="hashlink__item">
      <a className="hashlink__link" href="#">
        <p className="hashlink__blue">#</p>
        <p className="hashlink__text">{tag.name}</p>
      </a>
    </li>
  )
}

export default HashLinkItem
