import React from 'react';
import New from "../../components/smart/new/new";
import ContentVideo from "../../components/smart/content-video/content-video";
import Aside from "../../components/simple/aside/aside";
import Promo from "../../components/smart/promo/promo";
import Partners from "../../components/smart/partners/partners";
import Banners from "../../components/smart/banners/banners";
import Loader from "../../components/simple/loader/loader";
import {useAppSelector} from "../../core/hooks";
import {getPostsLoadingStatus} from "../../core/store/posts-data/selectors";
import {getBannersLoadingStatus} from "../../core/store/banners-data/selectors";
import {getVideosLoadingStatus} from "../../core/store/videos-data/selectors";
import {getAlbumsLoadingStatus} from "../../core/store/albums-data/selectors";
import {getPartnersLoadingStatus} from "../../core/store/partners-data/selectors";
import {getCompetitionsLoadingStatus} from "../../core/store/competitions-data/selectors";
import {getWidgetTablesLoadingStatus} from "../../core/store/widget-tables-data/selectors";
import {getActualVoteLoadingStatus} from "../../core/store/votes-data/selectors";

function Main() {
  const postsLoading = useAppSelector(getPostsLoadingStatus);
  const bannersLoading = useAppSelector(getBannersLoadingStatus);
  const videosLoading = useAppSelector(getVideosLoadingStatus);
  const albumsLoading = useAppSelector(getAlbumsLoadingStatus);
  const partnersLoading = useAppSelector(getPartnersLoadingStatus);
  const competitionsLoading = useAppSelector(getCompetitionsLoadingStatus);
  const widgetTablesLoading = useAppSelector(getWidgetTablesLoadingStatus);
  const actualVoteLoading = useAppSelector(getActualVoteLoadingStatus);
  const asideLoading = competitionsLoading || widgetTablesLoading || actualVoteLoading;
  const mainPageLoading = postsLoading || bannersLoading || videosLoading || albumsLoading || partnersLoading || asideLoading;

  return (
    <>
      <Loader hidden={!mainPageLoading}/>
      <div className={`gap gap--50 ${mainPageLoading ? 'hidden' : ''}`}>
        <Promo/>
        <Banners/>
        <div className="main__wrap">
          <div className="gap gap--60">
            <New/>
            <ContentVideo/>
            {/*<ContentImg/>*/}
          </div>
          <Aside/>
        </div>
        <Partners/>
      </div>
    </>
  );
}

export default Main;
