import React, {useEffect} from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {fetchActualVote} from "../../../core/store/api-actions";
import {
  getActualVote,
  getActualVoteErrorStatus,
  getSendVoteStatus
} from "../../../core/store/votes-data/selectors";
import Texts from "../../ordinary/texts/texts";
import {Vote} from "../../../api";
import BallotFormResult from "../../ordinary/ballot-form-result/ballot-form-result";
import {SendRequestStatus} from "../../../core/constants/common";
import BallotForm from "../ballot-form/ballot-form";

function Ballot() {
  const dispatch = useAppDispatch();
  const actualVote: Vote | null = useAppSelector(getActualVote);
  const actualVoteError = useAppSelector(getActualVoteErrorStatus);
  const sendVoteStatus = useAppSelector(getSendVoteStatus);

  useEffect(() => {
    dispatch(fetchActualVote());
  }, [dispatch]);

  return (
    <>
      <div className={`ballot ${actualVote === null ? 'hidden' : ''}`}>
        <p className="ballot__text">{actualVote?.title}</p>
        <BallotForm vote={actualVote} hidden={sendVoteStatus === SendRequestStatus.Success}/>
        <BallotFormResult vote={actualVote} hidden={sendVoteStatus !== SendRequestStatus.Success}/>
      </div>
      <Texts texts="Произошла ошибка при загрузке актуального опроса" hidden={!actualVoteError}/>
    </>
  );
}

export default Ballot;
