import React, {
  useEffect,
  useState
} from "react";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";
import {ApiPagesAliasGetAliasEnum} from "../../../api";

const CookieComponent = () => {
  const cookieName = 'cookies-show';
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookie = getCookie();
    if (!cookie) {
      setTimeout(() => {
        setIsVisible(true);
      }, 500);
    }
  }, []);

  const getCookie = (): string | null => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === cookieName) {
        return value;
      }
    }
    return null;
  };

  const setCookie = (): void => {
    const expirationDays = 30;
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    const cookieValue = '1';
    document.cookie = `${cookieName}=${cookieValue}; expires=${expirationDate.toUTCString()}; path=/`;
  };

  const handleAccept = () => {
    setCookie();
    setIsVisible(false);
  };

  return (
    <div className={`cookie ${isVisible ? '' : 'hidden'}`}>
      <div className="cookie__texts">
        <p className="cookie__text">Мы используем файлы cookie для аналитики и авторизации. Продолжая использовать сайт или нажав кнопку «я согласен», Вы подтверждаете, что согласны с Политикой использования cookie.</p>
        <p className="cookie__text">В случае несогласия Вам следует покинуть сайт или отключить файлы cookie в настройках браузера и/или Вашего устройства.</p>
      </div>
      <div className="cookie__buttons">
        <Link className="cookie__button cookie__button--aqua button" to={`${AppRoute.StaticPages.url}/${ApiPagesAliasGetAliasEnum.Privacy}`}>Подробнее</Link>
        <button className="button cookie__button js-cookie-banner-accept" onClick={handleAccept}>Я согласен</button>
      </div>
    </div>
  );
};

export default CookieComponent;
