import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchCompetition, fetchCompetitions } from "../api-actions";
import { CompetitionsState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { Option } from "../../types/common";
import {filterItemsByOneSport, transformCompetitionsToOptions} from "../../utils/array-prepare-helpers";

const initialState: CompetitionsState = {
  isCompetitionsLoading: false,
  isCompetitionLoading: false,
  isCompetitionsError: false,
  isCompetitionError: false,
  competitions: [],
  currentCompetition: null,
  filterSportStatus: {
    value: "all",
    label: "Все виды спорта",
  },
  filterSeasonStatus: {
    value: "all",
    label: "Все сезоны",
  },
  filterWidgetSportStatus: {
    value: "all",
    label: "Все виды спорта",
  },
  widgetFilterCompetitions: [],
  filterWidgetCompetitionStatus: {
    value: "null",
    label: "Соревнований нет",
  },
};

export const competitionsData = createSlice({
  name: NameSpace.Competitions,
  initialState,
  reducers: {
    filteringCompetitionsBySport: (
      state,
      action: PayloadAction<{ filterSportStatus: Option }>
    ) => {
      const { filterSportStatus } = action.payload;
      state.filterSportStatus = filterSportStatus;
    },
    filteringCompetitionsBySeason: (
      state,
      action: PayloadAction<{ filterSeasonStatus: Option }>
    ) => {
      const { filterSeasonStatus } = action.payload;
      state.filterSeasonStatus = filterSeasonStatus;
    },
    resetCompetitionsFilters: (
      state
    ) => {
      state.filterSportStatus = initialState.filterSportStatus;
      state.filterSeasonStatus = initialState.filterSeasonStatus;
    },
    filteringWidgetTablesBySport: (
      state,
      action: PayloadAction<{ filterWidgetSportStatus: Option }>
    ) => {
      const {filterWidgetSportStatus} = action.payload;
      state.filterWidgetSportStatus = filterWidgetSportStatus;
      const filteredCompetitions = filterItemsByOneSport(state.competitions, state.filterWidgetSportStatus.label);
      state.widgetFilterCompetitions = state.filterWidgetSportStatus.value === 'all' ? state.competitions : filteredCompetitions;
      const competitionsOptions = transformCompetitionsToOptions(state.widgetFilterCompetitions);
      const isStatusIncluded = competitionsOptions.some(option => option.value === state.filterWidgetCompetitionStatus.value && option.label === state.filterWidgetCompetitionStatus.label);
      state.filterWidgetCompetitionStatus = state.widgetFilterCompetitions.length > 0 ? isStatusIncluded ? state.filterWidgetCompetitionStatus : competitionsOptions[0]: initialState.filterWidgetCompetitionStatus;
    },
    filteringWidgetTablesByCompetition: (
      state,
      action: PayloadAction<{ filterWidgetCompetitionStatus: Option }>
    ) => {
      const { filterWidgetCompetitionStatus } = action.payload;
      state.filterWidgetCompetitionStatus = filterWidgetCompetitionStatus;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCompetitions.pending, (state) => {
        state.isCompetitionsLoading = true;
      })
      .addCase(fetchCompetitions.fulfilled, (state, action) => {
        state.competitions = action.payload;
        state.widgetFilterCompetitions = action.payload;
        state.filterWidgetCompetitionStatus = state.widgetFilterCompetitions.length > 0 ? transformCompetitionsToOptions(state.widgetFilterCompetitions)[0]: initialState.filterWidgetCompetitionStatus;
        state.isCompetitionsLoading = false;
      })
      .addCase(fetchCompetitions.rejected, (state) => {
        state.isCompetitionsError = true;
        state.isCompetitionsLoading = false;
      })
      .addCase(fetchCompetition.pending, (state) => {
        state.isCompetitionLoading = true;
      })
      .addCase(fetchCompetition.fulfilled, (state, action) => {
        state.currentCompetition = action.payload;
        state.isCompetitionLoading = false;
      })
      .addCase(fetchCompetition.rejected, (state) => {
        state.isCompetitionError = true;
        state.isCompetitionLoading = false;
      });
  },
});
export const {
  filteringCompetitionsBySport,
  filteringCompetitionsBySeason,
  resetCompetitionsFilters,
  filteringWidgetTablesBySport,
  filteringWidgetTablesByCompetition
} = competitionsData.actions;
