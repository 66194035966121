import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {fetchDefaultPost, fetchDefaultPosts} from '../api-actions';
import {DefaultPostsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {Option} from "../../types/common";

const initialState: DefaultPostsState = {
  isDefaultPostsLoading: false,
  isDefaultPostLoading: false,
  isDefaultPostsError: false,
  isDefaultPostError: false,
  defaultPosts: [],
  currentDefaultPost: null,
  filterSportStatus: {
    value: 'all',
    label: 'Все виды спорта'
  },
  sorterTopStatus: false,
  sorterExclusiveStatus: false,
  filterDateRangeStatus: {
    startDate: null,
    endDate: null,
  },
};

export const defaultPostsData = createSlice({
  name: NameSpace.DefaultPosts,
  initialState,
  reducers: {
    filteringPostsBySport: (state, action: PayloadAction<{filterSportStatus: Option}>) => {
      const {filterSportStatus} = action.payload;
      state.filterSportStatus = filterSportStatus;
    },
    sortingPostsByTop: (state, action: PayloadAction<{sorterTopStatus: boolean}>) => {
      const {sorterTopStatus} = action.payload;
      state.sorterTopStatus = sorterTopStatus;
    },
    sortingPostsByExclusive: (state, action: PayloadAction<{sorterExclusiveStatus: boolean}>) => {
      const {sorterExclusiveStatus} = action.payload;
      state.sorterExclusiveStatus = sorterExclusiveStatus;
    },
    resetPostsFilters: (state) => {
      state.sorterExclusiveStatus = initialState.sorterExclusiveStatus;
      state.sorterTopStatus = initialState.sorterTopStatus;
      state.filterSportStatus = initialState.filterSportStatus;
      state.filterDateRangeStatus = initialState.filterDateRangeStatus;
    },
    setPostsDateFilter: (state, action: PayloadAction<{ startDate: Date | null; endDate: Date | null }>) => {
      const { startDate, endDate } = action.payload;
      state.filterDateRangeStatus.startDate = startDate;
      state.filterDateRangeStatus.endDate = endDate;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDefaultPosts.pending, (state) => {
        state.isDefaultPostsLoading = true;
      })
      .addCase(fetchDefaultPosts.fulfilled, (state, action) => {
        state.defaultPosts = action.payload;
        state.isDefaultPostsLoading = false;
      })
      .addCase(fetchDefaultPosts.rejected, (state) => {
        state.isDefaultPostsError = true;
        state.isDefaultPostsLoading = false;
      })
      .addCase(fetchDefaultPost.pending, (state) => {
        state.isDefaultPostLoading = true;
      })
      .addCase(fetchDefaultPost.fulfilled, (state, action) => {
        state.currentDefaultPost = action.payload;
        state.isDefaultPostLoading = false;
      })
      .addCase(fetchDefaultPost.rejected, (state) => {
        state.isDefaultPostError = true;
        state.isDefaultPostLoading = false;
      });
  }
});

export const {
  filteringPostsBySport,
  sortingPostsByTop,
  sortingPostsByExclusive,
  resetPostsFilters,
  setPostsDateFilter
} = defaultPostsData.actions;
