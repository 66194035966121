import React, {
  useEffect,
  useState
} from "react";
import {useAppDispatch, useAppSelector} from "../../../core/hooks";
import {useParams} from "react-router-dom";
import {fetchCompetitionsMatches} from "../../../core/store/api-actions";
import {
  getCompetitionsMatches,
  getCompetitionsMatchesErrorStatus,
} from "../../../core/store/competitions-matches-data/selectors";
import {sortItemsByPublicationDate} from "../../../core/utils/array-prepare-helpers";
import Match from "../../ordinary/match/match";
import Texts from "../../ordinary/texts/texts";
import Pagination from "../../ordinary/pagination/pagination";
import {MATCHES_PER_PAGE} from "../../../core/constants/common";

function CompetitionCalendar() {
  const dispatch = useAppDispatch();
  const {id: competitionId} = useParams();
  const competitionsMatches = useAppSelector(getCompetitionsMatches);
  const competitionsMatchesError = useAppSelector(getCompetitionsMatchesErrorStatus);
  const sortedMatches = sortItemsByPublicationDate(competitionsMatches);
  const matchesPerPage = MATCHES_PER_PAGE;
  const pageCount = Math.ceil((sortedMatches?.length || 0) / matchesPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfLastMatch = (currentPage + 1) * matchesPerPage;
  const indexOfFirstMatch = indexOfLastMatch - matchesPerPage;
  const currentMatches = sortedMatches ? sortedMatches.slice(indexOfFirstMatch, indexOfLastMatch) : [];

  useEffect(() => {
    dispatch(fetchCompetitionsMatches(Number(competitionId)));
  }, [competitionId, dispatch]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo(0, 0);
  };

  return (
    <div className="gap gap--20">
      <Match matches={currentMatches}/>
      <Texts
        texts="Произошла ошибка при загрузке матчей"
        hidden={!competitionsMatchesError}
      />
      <Texts
        texts="Матчи ещё не опубликованы"
        hidden={competitionsMatchesError || competitionsMatches.length > 0}
      />
      <div hidden={pageCount < 2}>
        <Pagination
          pageCount={pageCount}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </div>
    </div>
  );
}

export default CompetitionCalendar;
