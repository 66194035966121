import { CompetitionsMatchesState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { MatchPost } from "../../../api";

export const getCompetitionsMatchesLoadingStatus = (state: State): boolean =>
  state[NameSpace.CompetitionsMatches].isCompetitionsMatchesLoading;
export const getCompetitionMatchLoadingStatus = (state: State): boolean =>
  state[NameSpace.CompetitionsMatches].isCompetitionMatchLoading;
export const getCompetitionsMatchesErrorStatus = (
  state: State
): CompetitionsMatchesState["isCompetitionsMatchesError"] =>
  state[NameSpace.CompetitionsMatches].isCompetitionsMatchesError;
export const getCompetitionErrorStatus = (
  state: State
): CompetitionsMatchesState["isCompetitionMatchError"] =>
  state[NameSpace.CompetitionsMatches].isCompetitionMatchError;
export const getCompetitionsMatches = (state: State): MatchPost[] =>
  state[NameSpace.CompetitionsMatches].competitionsMatches;
export const getCurrentCompetitionMatch = (state: State): MatchPost | null =>
  state[NameSpace.CompetitionsMatches].currentCompetitionMatch;
