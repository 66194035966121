import React from "react";
import { VideoPlaylist } from "../../../api";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import ContentPlay from '../../simple/content-play/content-play'

function ContentPlaylistItem({ playlist }: { playlist: VideoPlaylist }) {
  const publishedDate: string | undefined = playlist.published_dt;
  const formattedDate: string | null = publishedDate
    ? simpleDateFormat(new Date(publishedDate), "dd MMMM yyyy")
    : "Дата не известна";

  return (
    <li className="content__item">
      <Link
        className="content__link"
        to={`${AppRoute.VideoPlaylistsPage.url}/${playlist.playlist_id}`}
      >
        <div className="content__imgs">
          <img
            className="content__img"
            src={
              playlist._links?.previewUrl?.href ||
              "/images/defaults/video.svg"
            }
            alt={playlist.title}
          />
          <ContentPlay length={playlist.videos?.length} />
        </div>
        <div className="content__texts">
          <p className="content__date">{formattedDate}</p>
          <p className="content__title">{playlist.title}</p>
        </div>
      </Link>
    </li>
  );
}

export default ContentPlaylistItem;
