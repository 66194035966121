import React from "react";

function RequisiteItem() {
  return (
    <>
      <li className="requisite__item">
        <p className="requisite__indication">Полное фирменное наименование:</p>
        <p className="requisite__name">В Спорте СПб</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">Сокращенное фирменное наименование:</p>
        <p className="requisite__name">ВСпорте</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">Наименование на английском языке:</p>
        <p className="requisite__name">VSporte</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">ОГРН:</p>
        <p className="requisite__name">123456789456123</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">ИНН:</p>
        <p className="requisite__name">123456789456123</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">КПП:</p>
        <p className="requisite__name">123456789456123</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">ОКТМО:</p>
        <p className="requisite__name">123456789456123</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">ОКВЭД:</p>
        <p className="requisite__name">123</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">ОКПО:</p>
        <p className="requisite__name">123456789456123</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">Юридический адрес:</p>
        <p className="requisite__name">СПб, Миллионная ул., 12 оф. 1</p>
      </li>
      <li className="requisite__item">
        <p className="requisite__indication">Фактический адрес:</p>
        <p className="requisite__name">СПб, Миллионная ул., 12 оф. 1</p>
      </li>
    </>
  );
}

export default RequisiteItem;
