import React, {
  FormEvent,
  useState
} from 'react';
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {getSearchString} from "../../../core/store/searches-data/selectors";
import {savingSearchString} from "../../../core/store/searches-data/searches-data";
import {
  fetchSearchedEvents,
  fetchSearchedMatches,
  fetchSearchedPosts
} from "../../../core/store/api-actions";
import {useNavigate} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function Search() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate()
  const searchString = useAppSelector(getSearchString);
  const [search, setSearch] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearch(event.target.value);
    dispatch(savingSearchString({searchString: event.target.value}))
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (searchString.length > 0) {
      dispatch(fetchSearchedMatches(searchString));
      dispatch(fetchSearchedPosts(searchString));
      dispatch(fetchSearchedEvents(searchString));
    }
    navigate(AppRoute.SearchPostsPage.url);
    setSearch('');
  };

  return (
    <form
      className="search"
      onSubmit={handleSubmit}
    >
      <div className="search__container">
        <input
          className="search__input"
          type="text"
          id="q"
          name="q"
          placeholder="введите ваш запрос"
          onChange={handleChange}
          value={search}
        />
        <button className="search__button" type="submit">
          <svg className="search__icon" width="20" height="20">
            <use xlinkHref="images/sprite.svg#search"/>
          </svg>
        </button>
      </div>
    </form>
  );
}

export default Search;
