import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {fetchSearchedEvents, fetchSearchedMatches, fetchSearchedPosts} from '../api-actions';
import {SearchesState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {SendRequestStatus} from "../../constants/common";

const initialState: SearchesState = {
  searchedPosts: [],
  searchedEvents: [],
  searchedMatches: [],
  searchedPostsTotal: 0,
  searchedEventsTotal: 0,
  searchedMatchesTotal: 0,
  searchedPostsLoading: false,
  searchedEventsLoading: false,
  searchedMatchesLoading: false,
  searchedPostsError: false,
  searchedEventsError: false,
  searchedMatchesError: false,
  searchStatus: SendRequestStatus.Normal,
  searchString: '',
};

export const searchesData = createSlice({
  name: NameSpace.Posts,
  initialState,
  reducers: {
    savingSearchString: (state, action: PayloadAction<{searchString: string}>) => {
      const {searchString} = action.payload;
      state.searchString = searchString;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSearchedPosts.pending, (state) => {
        state.searchedPostsLoading = true;
      })
      .addCase(fetchSearchedPosts.fulfilled, (state, action) => {
        state.searchStatus = SendRequestStatus.Success;
        state.searchedPosts = action.payload.items;
        state.searchedPostsTotal = action.payload.total;
        state.searchedPostsLoading = false;
      })
      .addCase(fetchSearchedPosts.rejected, (state) => {
        state.searchStatus = SendRequestStatus.UnSuccess;
        state.searchedPostsError = true;
        state.searchedPostsLoading = false;
      })
      .addCase(fetchSearchedEvents.pending, (state) => {
        state.searchedEventsLoading = true;
      })
      .addCase(fetchSearchedEvents.fulfilled, (state, action) => {
        state.searchStatus = SendRequestStatus.Success;
        state.searchedEvents = action.payload.items;
        state.searchedEventsTotal = action.payload.total;
        state.searchedEventsLoading = false;
      })
      .addCase(fetchSearchedEvents.rejected, (state) => {
        state.searchStatus = SendRequestStatus.UnSuccess;
        state.searchedEventsError = true;
        state.searchedEventsLoading = false;
      })
      .addCase(fetchSearchedMatches.pending, (state) => {
        state.searchedMatchesLoading = true;
      })
      .addCase(fetchSearchedMatches.fulfilled, (state, action) => {
        state.searchStatus = SendRequestStatus.Success;
        state.searchedMatches= action.payload.items;
        state.searchedMatchesTotal = action.payload.total;
        state.searchedMatchesLoading = false;
      })
      .addCase(fetchSearchedMatches.rejected, (state) => {
        state.searchStatus = SendRequestStatus.UnSuccess;
        state.searchedMatchesError = true;
        state.searchedMatchesLoading = false;
      })
  }
});

export const {
  savingSearchString
} = searchesData.actions;