import React, {
  FormEvent,
  useState
} from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  fetchSearchedEvents,
  fetchSearchedMatches,
  fetchSearchedPosts
} from "../../../core/store/api-actions";
import {validateSearchForm} from "../../../core/utils/form-validate-helpers";
import {getSearchString} from "../../../core/store/searches-data/selectors";
import {savingSearchString} from "../../../core/store/searches-data/searches-data";

function Searching() {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const searchString = useAppSelector(getSearchString);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validationErrors = validateSearchForm(searchString);
    if ('search' in validationErrors && validationErrors.search) {
      setErrors({...errors, search: ''});
    }
    dispatch(savingSearchString({searchString: event.target.value}))
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newErrors = validateSearchForm(searchString);
    setErrors((prevErrors) => ({...prevErrors, ...newErrors}));

    if (Object.keys(newErrors).length === 0) {
      dispatch(fetchSearchedMatches(searchString));
      dispatch(fetchSearchedPosts(searchString));
      dispatch(fetchSearchedEvents(searchString));
    }
  };

  return (
    <div className="searching-wrapper">
      <form className="searching" onSubmit={handleSubmit}>
        <input
          className="searching__input"
          type="text"
          placeholder="Введите поиск"
          id="query"
          name="query"
          value={searchString}
          onChange={handleChange}
        />
        <button className="button searching__button" type="submit">
          <p className="searching__text">Найти</p>
          <svg className="searching__icon" width="20" height="20">
            <use xlinkHref="images/sprite.svg#search"/>
          </svg>
        </button>
      </form>
      <div className={`searching-wrapper__helps ${Object.keys(errors).length === 0 ? 'hidden' : ''}`}>
        {Object.entries(errors).map(([fieldName, errorMessage]) => (
          <p className="searching-wrapper__help" key={fieldName}>{errorMessage}</p>
        ))}
      </div>
    </div>
  )
}

export default Searching;
