import {MatchPostsState, State} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {MatchPost} from "../../../api";


export const getMatchPostsLoadingStatus = (state: State): boolean => state[NameSpace.MatchPosts].isMatchPostsLoading;
export const getMatchPostLoadingStatus = (state: State): boolean => state[NameSpace.MatchPosts].isMatchPostLoading;
export const getMatchPostsErrorStatus = (state: State): MatchPostsState['isMatchPostsError'] => state[NameSpace.MatchPosts].isMatchPostsError;
export const getMatchPostErrorStatus = (state: State): MatchPostsState['isMatchPostError'] => state[NameSpace.MatchPosts].isMatchPostError;
export const getMatchPosts = (state: State): MatchPost[] => state[NameSpace.MatchPosts].matchPosts;
export const getCurrentMatchPost = (state: State): MatchPost | null => state[NameSpace.MatchPosts].currentMatchPost;

