import React from "react";

function Texts({texts, text, hidden}: { texts: string; text?: string; hidden?: boolean }) {
  return (
    <p className={`texts ${hidden ? 'hidden' : ''}`}>
      {texts} <span className="texts">{text}</span>
    </p>
  );
}

export default Texts;
