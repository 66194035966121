import React from "react";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function SearchMobile() {
  return (
    <Link className="search-mobile" to={AppRoute.SearchPostsPage.url}>
      <svg className="search-mobile__icon" width="35" height="35"><use xlinkHref="images/sprite.svg#search"/></svg>
    </Link>
  );
}

export default SearchMobile;
