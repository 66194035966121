import React from 'react';
import Texts from "../../components/ordinary/texts/texts";
import Aside from "../../components/simple/aside/aside";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import ButtonsSearch from "../../components/simple/buttons-search/buttons-search";
import Searching from "../../components/smart/searching/searching";
import {AppRoute} from "../../core/constants/routes";
import CalendarList from "../../components/ordinary/calendar-list/calendar-list";
import {useAppSelector} from "../../core/hooks";
import {
  getSearchedEvents,
  getSearchedEventsError,
  getSearchedEventsLoading,
  getSearchString
} from "../../core/store/searches-data/selectors";
import Loader from "../../components/simple/loader/loader";

function SearchEventsPage() {
  const crumbsRoutes = [
    AppRoute.Main,
    {title: 'Поиск', url: AppRoute.SearchPostsPage.url},
    AppRoute.SearchPostsPage
  ];
  const searchedEvents = useAppSelector(getSearchedEvents);
  const searchedEventsError = useAppSelector(getSearchedEventsError);
  const searchedEventsLoading = useAppSelector(getSearchedEventsLoading);
  const searchString = useAppSelector(getSearchString);

  return (
    <div className="main__wrap">
      <div className="gap gap--25">
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap">
          <Title title="Поиск"/>
          <div className="gap gap--30">
            <Searching/>
            <ButtonsSearch/>
          </div>
          <Loader hidden={!searchedEventsLoading}/>
          <div className={`gap gap--25 ${searchedEventsLoading ? 'hidden' : ''}`}>
            <CalendarList
              events={searchedEvents || []}
              query={searchString}
            />
            <Texts
              texts="По Вашему запросу мероприятий не найдено." text="Попробуйте ввести другой."
              hidden={searchedEventsError || (searchedEvents !== undefined && searchedEvents.length > 0) || searchString.length === 0}
            />
            <Texts
              texts="Что-то пошло не так. Пожалуйста, попробуйте еще раз позже."
              hidden={!searchedEventsError}
            />
            <Texts
              texts="Введите ключевое слово или фразу для начала поиска."
              hidden={searchedEventsError || searchString.length > 0}
            />
          </div>
        </div>
      </div>
      <Aside/>
    </div>
  );
}

export default SearchEventsPage;
