import React from "react";
import {Page} from "../../../api";
import {
  Link,
  useLocation
} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function ButtonsItem({page}: { page: Page }) {
  const location = useLocation();
  const isActive = location.pathname === `${AppRoute.AboutStaticPages.url}/${page.alias}`;
  const className = isActive ? 'buttons__item--active js-active' : '';

  return (
    <li className={`buttons__item ${className}`}>
      <Link className="buttons__link" to={`${AppRoute.AboutStaticPages.url}/${page.alias}`}>{page.title}</Link>
    </li>
  );
}


export default ButtonsItem
