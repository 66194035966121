import React from "react";
import PartnersItem from "../partners-item/partners-item";
import {HdbkPartner} from "../../../api";

function PartnersList({className, partners}: { className?: string, partners: HdbkPartner[] }) {
  return (
    <ul className={`partners ${className ? className : ''} ${partners.length === 0 ? 'hidden' : ''}`}>
      {partners.map((partner) => <PartnersItem key={partner.partner_id} partner={partner}/>)}
    </ul>
  );
}

export default PartnersList
