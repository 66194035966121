import {createSlice} from '@reduxjs/toolkit';
import { fetchCompetitionTable, fetchCompetitionsTables } from '../api-actions';
import {CompetitionsTablesState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: CompetitionsTablesState = {
  isCompetitionsTablesLoading: false,
  isCompetitionTableLoading: false,
  isCompetitionsTablesError: false,
  isCompetitionTableError: false,
  competitionsTables: [],
  currentCompetitionTable: null,
};

export const competitionsTablesData = createSlice({
  name: NameSpace.CompetitionsTables,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCompetitionsTables.pending, (state) => {
        state.isCompetitionsTablesLoading = true;
      })
      .addCase(fetchCompetitionsTables.fulfilled, (state, action) => {
        state.competitionsTables = action.payload;
        state.isCompetitionsTablesLoading = false;
      })
      .addCase(fetchCompetitionsTables.rejected, (state) => {
        state.isCompetitionsTablesError = true;
        state.isCompetitionsTablesLoading = false;
      })
      .addCase(fetchCompetitionTable.pending, (state) => {
        state.isCompetitionTableLoading = true;
      })
      .addCase(fetchCompetitionTable.fulfilled, (state, action) => {
        state.currentCompetitionTable = action.payload;
        state.isCompetitionTableLoading = false;
      })
      .addCase(fetchCompetitionTable.rejected, (state) => {
        state.isCompetitionTableError = true;
        state.isCompetitionTableLoading = false;
      });
  }
});
