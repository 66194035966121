import React from "react";
import RequisiteItem from "../requisite-item/requisite-item";

function RequisiteList () {
  return (
    <ul className="requisite">
      <RequisiteItem/>
    </ul>
  );
}

export default RequisiteList;
