import React, {useEffect} from "react";
import Check from "../check/check";
import DropdownMulti from "../dropdown-multi/dropdown-multi";
import {ItemsForFilter} from "../../../core/constants/filters";
import Reset from "../reset/reset";
import SportsFilter from "../sports-filter/sports-filter";
import {useAppDispatch} from "../../../core/hooks";
import {resetOrganizationsFilters} from "../../../core/store/organizations-data/organizations-data";

function FilterItem4() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetOrganizationsFilters());
  }, [dispatch]);

  return (
    <>
      <li className="filter__item">
        <DropdownMulti/>
      </li>
      <SportsFilter items={ItemsForFilter.Organizations}/>
      <li className="filter__item">
        <Check/>
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.Organizations}/>
      </li>
    </>
  );
}

export default FilterItem4;
