import { CompetitionsState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { Competition } from "../../../api";
import { Option } from '../../types/common'

export const getCompetitionsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Competitions].isCompetitionsLoading;
export const getCompetitionLoadingStatus = (state: State): boolean =>
  state[NameSpace.Competitions].isCompetitionLoading;
export const getCompetitionsErrorStatus = (
  state: State
): CompetitionsState["isCompetitionsError"] =>
  state[NameSpace.Competitions].isCompetitionsError;
export const getCompetitionErrorStatus = (
  state: State
): CompetitionsState["isCompetitionError"] =>
  state[NameSpace.Competitions].isCompetitionError;
export const getCompetitions = (state: State): Competition[] =>
  state[NameSpace.Competitions].competitions;
export const getCurrentCompetition = (state: State): Competition | null =>
  state[NameSpace.Competitions].currentCompetition;
export const getCompetitionsFilterSportStatus = (state: State): Option => state[NameSpace.Competitions].filterSportStatus;
export const getCompetitionsFilterSeasonStatus = (state: State): Option => state[NameSpace.Competitions].filterSeasonStatus;
export const getWidgetTablesFilterSportStatus = (state: State): Option => state[NameSpace.Competitions].filterWidgetSportStatus;
export const getWidgetTablesFilterCompetitionStatus = (state: State): Option => state[NameSpace.Competitions].filterWidgetCompetitionStatus;
export const getFilterCompetitions = (state: State): Competition[] => state[NameSpace.Competitions].widgetFilterCompetitions;