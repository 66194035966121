import React from 'react';
import Texts from "../../components/ordinary/texts/texts";
import Aside from "../../components/simple/aside/aside";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import ButtonsSearch from "../../components/simple/buttons-search/buttons-search";
import Searching from "../../components/smart/searching/searching";
import {AppRoute} from "../../core/constants/routes";
import {useAppSelector} from "../../core/hooks";
import {
  getSearchedMatches,
  getSearchedMatchesError,
  getSearchedMatchesLoading,
  getSearchString
} from "../../core/store/searches-data/selectors";
import Match from "../../components/ordinary/match/match";
import Loader from "../../components/simple/loader/loader";

function SearchMatchesPage() {
  const crumbsRoutes = [
    AppRoute.Main,
    {title: 'Поиск', url: AppRoute.SearchPostsPage.url},
    AppRoute.SearchMatchesPage
  ];
  const searchedMatches = useAppSelector(getSearchedMatches);
  const searchedMatchesError = useAppSelector(getSearchedMatchesError);
  const searchedMatchesLoading = useAppSelector(getSearchedMatchesLoading);
  const searchString = useAppSelector(getSearchString);

  return (
    <div className="main__wrap">
      <div className="gap gap--25">
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap">
          <Title title="Поиск"/>
          <div className="gap gap--30">
            <Searching/>
            <ButtonsSearch/>
          </div>
          <Loader hidden={!searchedMatchesLoading}/>
          <div className={`gap gap--25 ${searchedMatchesLoading ? 'hidden' : ''}`}>
            <Match
              matches={searchedMatches}
              query={searchString}
            />
            <Texts
              texts="По Вашему запросу не найдено ни одного матча." text="Попробуйте ввести другой."
              hidden={searchedMatchesError || (searchedMatches !== undefined && searchedMatches.length > 0) || searchString.length === 0}
            />
            <Texts
              texts="Что-то пошло не так. Пожалуйста, попробуйте еще раз позже."
              hidden={!searchedMatchesError}
            />
            <Texts
              texts="Введите ключевое слово или фразу для начала поиска."
              hidden={searchedMatchesError || searchString.length > 0}
            />
          </div>
        </div>
      </div>
      <Aside/>
    </div>
  );
}

export default SearchMatchesPage;
