import React from 'react';
import PhotosItem from "../photos-item/photos-item";
import {Album} from "../../../api";
import {albumsSliderConfig} from "../../../core/config/slider-config";
import TinySlider from "tiny-slider-react";

function PhotosList({albums}: { albums?: Album[] }) {
  if (albums && albums.length > 0) {
    return (
      <ul className="photos__list">
        <TinySlider settings={albumsSliderConfig}>
          {albums?.map((album) => <PhotosItem key={album.album_id} album={album}/>)}
        </TinySlider>
      </ul>
    );
  }
  return null;
}

export default PhotosList;
