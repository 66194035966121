import React from "react";
import HeaderPage from "../header-page/header-page";
import {MatchPost} from "../../../api";
import MatchItem from "../match-item/match-item";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function Command({match}: { match: MatchPost }) {
  if (!match) {
    return null;
  }

  return (
    <div className="gap gap--20">
      <HeaderPage
        title={match.title}
        author={match.author}
        date={match.publishedDt}
      />
      <div className="gap gap--30">
        <MatchItem match={match}/>
        <div className="redactor-styles">
          <HtmlRenderer htmlString={match?.text || "Информация отсутствует"}/>
        </div>
      </div>
    </div>
  );
}

export default Command;
