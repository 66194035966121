import React from "react";
import { Tag } from "../../../api";
import { highlightSearchQuery } from "../../../core/utils/common-helpers";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function HashtagsItem({ tag, query }: { tag: Tag; query?: string }) {
  return (
    <li className="hashtags__texts">
      <p className="hashtags__multicolored">#</p>
      <div className="hashtags__text">
        <HtmlRenderer htmlString={highlightSearchQuery(tag.name, query)} />
      </div>
    </li>
  );
}

export default HashtagsItem;
