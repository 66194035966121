import React from "react";
import CompetitionTable from "../../smart/competition-table/competition-table";
import CompetitionCalendar from "../../smart/competition-calendar/competition-calendar";

function CompetitionContent({selectedView}: { selectedView: "table" | "calendar" }) {
  return (
    <div>
      {selectedView === "table" ? (
        <CompetitionTable/>
      ) : (
        <CompetitionCalendar/>
      )}
    </div>
  );
}

export default CompetitionContent;
