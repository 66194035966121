import React, {useEffect, useState} from 'react';
import Aside from "../../components/simple/aside/aside";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import {AppRoute} from "../../core/constants/routes";
import {useParams} from "react-router-dom";
import {fetchVideoPlaylist} from "../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import Texts from "../../components/ordinary/texts/texts";
import HeaderPage from "../../components/ordinary/header-page/header-page";
import Loader from "../../components/simple/loader/loader";
import { getCurrentVideoPlayList, getVideoPlayListErrorStatus, getVideoPlayListLoadingStatus } from '../../core/store/video-playlists-data/selectors'
import ContentListVideo from '../../components/ordinary/content-list-video/content-list-video'
import Pagination from '../../components/ordinary/pagination/pagination'
import { VIDEOS_PER_PAGE } from '../../core/constants/common'
import { sortItemsByPublicationDate } from '../../core/utils/array-prepare-helpers'

function VideoPlaylistPage() {
  const {id: playlistId} = useParams();
  const dispatch = useAppDispatch();
  const playlist = useAppSelector(getCurrentVideoPlayList);
  const isPlaylistError = useAppSelector(getVideoPlayListErrorStatus);
  const isPlaylistLoading = useAppSelector(getVideoPlayListLoadingStatus);
  const sortedVideos = sortItemsByPublicationDate(playlist?.videos)
  const currentURL = window.location.href;
  const crumbsRoutes = [AppRoute.Main, AppRoute.VideoPlaylistsPage, {title: playlist?.title || '', url: currentURL}];
  const [currentPage, setCurrentPage] = useState(0);
  const videosPerPage = VIDEOS_PER_PAGE;
  const pageCount = Math.ceil((sortedVideos?.length || 0) / videosPerPage);
  const indexOfLastVideo = (currentPage + 1) * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = sortedVideos ? sortedVideos.slice(indexOfFirstVideo, indexOfLastVideo) : [];

  useEffect(() => {
    dispatch(fetchVideoPlaylist(Number(playlistId)));
  }, [playlistId]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!isPlaylistLoading}/>
      <div className={`gap gap--40 ${isPlaylistLoading ? 'hidden' : ''}`}>
        <div className={`gap gap--40 ${isPlaylistError ? 'hidden' : ''}`}>
          <Crumbs routes={crumbsRoutes}/>
          <div className="gap gap--30">
            <HeaderPage title={playlist?.title} author={playlist?.author} date={playlist?.publishedDt}/>
          </div>
          <ContentListVideo videos={currentVideos}/>
        </div>
        <Texts
            texts="Здесь находятся видео нашего портала, но на данный момент они еще не опубликованы. Ожидайте, так как в скором времени они появятся!"
            hidden={isPlaylistError || (playlist?.videos?.length ?? 0) > 0}
          />
          <Texts
            texts="Произошла ошибка при загрузке видео."
            hidden={!isPlaylistError}
          />
          <Pagination pageCount={pageCount} currentPage={currentPage} onPageClick={handlePageClick}/>
      </div>
      <Aside/>
    </div>
  );
}

export default VideoPlaylistPage;
