import React from 'react';

function Error() {
  return (
    <div className="error">
      <h2 className="error__title">Страница не найдена</h2>
      <svg className="error__icon" width="310" height="175">
        <use xlinkHref="images/sprite.svg#error"/>
      </svg>
      <div className="gap gap--30">
        <p className="error__subtitle">Простите, но у нас возникли проблемы с поиском страницы, которую Вы запрашиваете</p>
        <p className="error__text">Возможно, запрашиваемая Вами страница была перенесена или удалена. Также возможно, Вы допустили небольшую опечатку при вводе адреса – такое случается даже с нами, поэтому еще раз внимательно проверьте или вернитесь на <a className="error__link" href="#">Главную</a></p>
      </div>
    </div>
  );
}

export default Error;
