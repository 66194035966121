import React from "react";
import CompetitionItem from "../competition-item/competition-item";
import {Competition} from "../../../api";

function CompetitionList({competitions}: { competitions: Competition[] }) {
  return (
    <ul className={`competition ${competitions.length === 0 ? "hidden" : ""}`}>
      {competitions.map((competition) => <CompetitionItem key={competition?.competition_id} competition={competition}/>)}
    </ul>
  );
}

export default CompetitionList;
