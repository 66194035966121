import {Post} from "../../../api";
import React from "react";
import TinySlider from "tiny-slider-react";
import {navsSliderConfig} from "../../../core/config/slider-config";
import PromoNav from "../promo-nav/promo-nav";

function PromoNavs({sortedPosts, navSliderRef}: {
  sortedPosts: Post[],
  navSliderRef: React.MutableRefObject<null>
}) {
  return (
    <div className="info">
      <TinySlider settings={navsSliderConfig} ref={navSliderRef}>
        {sortedPosts.map((post) => <PromoNav key={post.post_id} post={post}/>)}
      </TinySlider>
    </div>
  );
}

export default PromoNavs;