import React, {useEffect} from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getBanners,
  getBannersErrorStatus
} from "../../../core/store/banners-data/selectors";
import {fetchBanners} from "../../../core/store/api-actions";
import BannerItem from "../../ordinary/banner-item/banner-item";
import Texts from "../../ordinary/texts/texts";
import {
  filterBannersByPosition,
  sortItemsByRank
} from "../../../core/utils/array-prepare-helpers";

function Banners() {
  const banners = useAppSelector(getBanners);
  const filteredBanners = filterBannersByPosition(banners, 'after-news-slider');
  const rankedBanners = sortItemsByRank(filteredBanners);
  const bannersErrorStatus = useAppSelector(getBannersErrorStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchBanners());
  }, [dispatch]);

  return (
    <>
      <div className={`gap gap--40 hide-880 ${rankedBanners.length === 0 ? 'hidden' : ''}`}>
        {rankedBanners.map((banner) => <BannerItem key={banner.banner_id} banner={banner}/>)}
      </div>
      <Texts
        texts="Произошла ошибка при загрузке баннеров для главной страницы"
        hidden={!bannersErrorStatus}
      />
    </>
  );
}

export default Banners;
