import React, {useEffect} from 'react';
import TagsItem from '../tags-item/tags-item';
import {fetchSports} from "../../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {getHeaderSports} from "../../../core/store/sports-data/selectors";
import { ApiSportKindsGetOnMainEnum } from '../../../api'

function Tags({className}: { className?: string }) {
  const dispatch = useAppDispatch();
  const sports = useAppSelector(getHeaderSports);
  useEffect(() => {
    dispatch(fetchSports({pageSize:6, onMain: ApiSportKindsGetOnMainEnum.NUMBER_1}));
  }, [dispatch]);

  return (
    <ul className={`tags ${className ? className : ''}`}>
      {sports.map((sport) => <TagsItem sport={sport} key={sport.sport_kind_id}/>)}
    </ul>
  );
}

export default Tags;
