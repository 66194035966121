import React from "react";
import { Organization } from "../../../api";
import ReactDOMServer from "react-dom/server";

function BalloonContent({ organization }: { organization: Organization }) {
  const src =
    organization._links?.photo?.href === undefined ||
    organization._links?.photo?.href.includes("photo_placeholder_thumb.png")
      ? "/images/defaults/school.svg"
      : organization._links?.photo?.href;

  const formattedTimes = Object.values(organization.times || {})
    .sort((a, b) => a.day_number - b.day_number)
    .map((time) => ({
      dayLabel: getDayLabel(time.day_number),
      start: time.start,
      end: time.end,
    }));
  return (
    <div className="balloon">
      <div className="balloon__texts">
        <div className="balloon__row">
          <div className="balloon__group">
            <p className="balloon__title">{organization.full_name}</p>
            <p className="balloon__address">{organization.address}</p>
          </div>
          <img
            className="balloon__image show-530"
            src={src}
            width="100px"
            height="100px"
            alt={organization.full_name}
          />
        </div>
        <ul className="balloon__infos">
          <li className="balloon__info">
            <p className="balloon__label">Е-mail:</p>
            <p className="balloon__text">{organization.email}</p>
          </li>
          <li className="balloon__info">
            <p className="balloon__label">Телефон:</p>
            <p className="balloon__text">{organization.phone}</p>
          </li>
          <li className="balloon__info">
            <p className="balloon__label">Время работы:</p>
            <ul className="balloon__work-hours">
              {formattedTimes.map((time) => (
                <li className="balloon__work-hours__item" key={time.dayLabel}>
                  <span className="balloon__work-hours__day">{`${time.dayLabel}`}</span>
                  <span className="balloon__work-hours__time">{`${formatTime(time.start)} - ${formatTime(time.end)}`}</span>
                </li>
              ))}
            </ul>
          </li>
          <li className="balloon__info">
            <p className="balloon__label">Социальные сети:</p>
            <ul className="balloon__social">
              {organization.socials?.map((social) => (
                <li key={social.social_id} className="balloon__social__item">
                  <a
                    className="balloon__social__link"
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg className="search__icon" width="25" height="25">
                      <use xlinkHref={`images/sprite.svg#${social.social}`} />
                    </svg>
                    <span className="balloon__social__text">{social.url}</span>
                  </a>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
      <img
        className="balloon__image hide-530"
        src={src}
        width="100px"
        height="100px"
        alt={organization.full_name}
      />
    </div>
  );
}

function getDayLabel(dayNumber: number): string {
  switch (dayNumber) {
    case 1:
      return "ПН";
    case 2:
      return "ВТ";
    case 3:
      return "СР";
    case 4:
      return "ЧТ";
    case 5:
      return "ПТ";
    case 6:
      return "СБ";
    case 7:
      return "ВС";
    default:
      return "";
  }
}

function formatTime(time: string): string {
  const parts = time.split(":");
  const hours = parts[0].length === 1 ? `0${parts[0]}` : parts[0];
  const minutes = parts[1].length === 1 ? `0${parts[1]}` : parts[1];
  return `${hours}:${minutes}`;
}

BalloonContent.toString = (organization: Organization) =>
  ReactDOMServer.renderToString(<BalloonContent organization={organization} />);

export default BalloonContent;
