import {createSlice} from '@reduxjs/toolkit';
import {fetchCompetitionMatch, fetchCompetitionsMatches} from '../api-actions';
import {CompetitionsMatchesState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: CompetitionsMatchesState = {
  isCompetitionsMatchesLoading: false,
  isCompetitionMatchLoading: false,
  isCompetitionsMatchesError: false,
  isCompetitionMatchError: false,
  competitionsMatches: [],
  currentCompetitionMatch: null,
};

export const competitionsMatchesData = createSlice({
  name: NameSpace.CompetitionsMatches,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCompetitionsMatches.pending, (state) => {
        state.isCompetitionsMatchesLoading = true;
      })
      .addCase(fetchCompetitionsMatches.fulfilled, (state, action) => {
        state.competitionsMatches = action.payload;
        state.isCompetitionsMatchesLoading = false;
      })
      .addCase(fetchCompetitionsMatches.rejected, (state) => {
        state.isCompetitionsMatchesError = true;
        state.isCompetitionsMatchesLoading = false;
      })
      .addCase(fetchCompetitionMatch.pending, (state) => {
        state.isCompetitionMatchLoading = true;
      })
      .addCase(fetchCompetitionMatch.fulfilled, (state, action) => {
        state.currentCompetitionMatch = action.payload;
        state.isCompetitionMatchLoading = false;
      })
      .addCase(fetchCompetitionMatch.rejected, (state) => {
        state.isCompetitionMatchError = true;
        state.isCompetitionMatchLoading = false;
      });
  }
});
