import React from "react";
import {HdbkPartner} from "../../../api";
import {Link} from "react-router-dom";

function PartnersItem({partner}: { partner: HdbkPartner }) {
  return (
    <li className="partners__item">
      <Link className="partners__link" to={partner.url} target="_blank">
        <img className="partners__img" src={partner.logoUrl?.includes('logo_placeholder_thumb.png') ? '/images/defaults/person.svg' : partner.logoUrl} alt={partner.name}/>
      </Link>
    </li>
  );
}

export default PartnersItem
