export function isSafari(): boolean {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function range(start: number, end: number, step: number = 1): number[] {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
}

export const highlightSearchQuery = (text: string, searchQuery?: string) => {
  if (!searchQuery || !text) return text;
  const escapedSearchQuery = searchQuery.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedSearchQuery})`, 'gi');
  return text.replace(regex, `<span class="highlight">$1</span>`);
};

export const isReactPlayerSupported = (url: string): boolean => {
  return (
    url.includes('youtube.com') ||
    url.includes('youtu.be') ||
    url.includes('vimeo.com') ||
    url.includes('twitch.tv') ||
    url.includes('facebook.com')
  );
};
