import {Option} from "../types/common";
import {getYear} from "date-fns";
import {range} from "../utils/common-helpers";

export enum ItemsForFilter {
  Posts = 'posts',
  Events = 'events',
  Organizations = 'organizations',
  Competitions= 'competitions',
  WidgetCompetitions= 'widget-competitions',
}

export const Sorters = {
  Top: {
    value: 'top',
    label: 'Топ'
  },
  Exclusive: {
    value: 'exclusive',
    label: 'Эксклюзив'
  },
};

export enum DropdownTypes {
  Sport = 'sport',
  Competition = 'competition',
  District = 'district',
  Season = 'season',
  Month = 'month',
  PastFuture = 'pastFuture',
  Undefined = 'undefined'
}

export enum DropdownPlaceholders {
  Sport = 'Вид спорта',
  Competition = 'Соревнование',
  District = 'Район',
  Season = 'Сезон',
  Month = 'Месяц',
  PastFuture = 'Прошедшие и Будущие',
  Undefined = 'Не работает'
}

export const years = range(1990, getYear(new Date()) + 1, 1);

export const yearsAsOptions: Option[] = years.map((year) => ({
  value: year,
  label: String(year),
}));

export const filterMonths = [
  'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
  'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

export const filterMonthsObjects = [
  {value: 'all', label: 'Все месяцы'},
  {value: 'January', label: 'Январь'},
  {value: 'February', label: 'Февраль'},
  {value: 'March', label: 'Март'},
  {value: 'April', label: 'Апрель'},
  {value: 'May', label: 'Май'},
  {value: 'June', label: 'Июнь'},
  {value: 'July', label: 'Июль'},
  {value: 'August', label: 'Август'},
  {value: 'September', label: 'Сентябрь'},
  {value: 'October', label: 'Октябрь'},
  {value: 'November', label: 'Ноябрь'},
  {value: 'December', label: 'Декабрь'},
];

export const filterPastFuture = [
  {value: 'all', label: 'Все'},
  {value: 'future', label: 'Будущие'},
  {value: 'past', label: 'Прошедшие'},
];
