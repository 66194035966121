import {createSlice} from '@reduxjs/toolkit';
import {fetchBanner, fetchBanners} from '../api-actions';
import {BannersState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: BannersState = {
  isBannersLoading: false,
  isBannerLoading: false,
  isBannersError: false,
  isBannerError: false,
  banners: [],
  currentBanner: null,
};

export const bannersData = createSlice({
  name: NameSpace.Banners,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBanners.pending, (state) => {
        state.isBannersLoading = true;
      })
      .addCase(fetchBanners.fulfilled, (state, action) => {
        state.banners = action.payload;
        state.isBannersLoading = false;
      })
      .addCase(fetchBanners.rejected, (state) => {
        state.isBannersError = true;
        state.isBannersLoading = false;
      })
      .addCase(fetchBanner.pending, (state) => {
        state.isBannerLoading = true;
      })
      .addCase(fetchBanner.fulfilled, (state, action) => {
        state.currentBanner = action.payload;
        state.isBannerLoading = false;
      })
      .addCase(fetchBanner.rejected, (state) => {
        state.isBannerError = true;
        state.isBannerLoading = false;
      });
  }
});