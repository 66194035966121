import React from "react";

function Contacts({address}: { address?: string }) {
  return (
    <div className="contacts">
      <h2 className="contacts__title">{address || 'Адрес не указан'}</h2>
      <div className="contacts__content">
        <a className="contacts__link" href="mailto:redaction@vsporte.ru">Е-mail:<span className="map__black">redaction@vsporte.ru</span></a>
        <a className="contacts__link" href="tel:+79114564466">Телефон редакции:<span className="map__black">+7 (911) 456 - 44 - 66</span></a>
      </div>
    </div>
  );
}

export default Contacts
