import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {DefaultPost, EventPost, MatchPost} from "../../../api";

export const getSearchedPostsLoading = (state: State): boolean => state[NameSpace.Searches].searchedPostsLoading;
export const getSearchedPostsError = (state: State): boolean => state[NameSpace.Searches].searchedPostsError;
export const getSearchedPosts = (state: State): DefaultPost[] | undefined => state[NameSpace.Searches].searchedPosts;
export const getSearchedPostsTotal = (state: State): number | undefined => state[NameSpace.Searches].searchedPostsTotal;
export const getSearchedEventsLoading = (state: State): boolean => state[NameSpace.Searches].searchedEventsLoading;
export const getSearchedEventsError = (state: State): boolean => state[NameSpace.Searches].searchedEventsError;
export const getSearchedEvents = (state: State): EventPost[] | undefined => state[NameSpace.Searches].searchedEvents;
export const getSearchedEventsTotal = (state: State): number | undefined => state[NameSpace.Searches].searchedEventsTotal;
export const getSearchedMatchesLoading = (state: State): boolean => state[NameSpace.Searches].searchedMatchesLoading;
export const getSearchedMatchesError = (state: State): boolean => state[NameSpace.Searches].searchedMatchesError;
export const getSearchedMatches = (state: State): MatchPost[] | undefined => state[NameSpace.Searches].searchedMatches;
export const getSearchedMatchesTotal = (state: State): number | undefined => state[NameSpace.Searches].searchedMatchesTotal;
export const getSearchStatus = (state: State): string => state[NameSpace.Searches].searchStatus;
export const getSearchString = (state: State): string => state[NameSpace.Searches].searchString;