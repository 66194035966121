import React, {
  useEffect,
  useState
} from "react";
import Aside from "../../components/simple/aside/aside";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Subtitle from "../../components/ordinary/subtitle/subtitle";
import {AppRoute} from "../../core/constants/routes";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  getCompetitionErrorStatus,
  getCompetitionLoadingStatus,
  getCurrentCompetition,
} from "../../core/store/competitions-data/selectors";
import {useParams} from "react-router-dom";
import {fetchCompetition} from "../../core/store/api-actions";
import CompetitionViewSwitcher from "../../components/smart/competition-view-switcher/competition-view-switcher";
import CompetitionContent from "../../components/ordinary/competition-content/competition-content";
import {getCompetitionsTablesLoadingStatus} from "../../core/store/competitions-tables-data/selectors";
import {getCompetitionsMatchesLoadingStatus} from "../../core/store/competitions-matches-data/selectors";
import Loader from "../../components/simple/loader/loader";

function CompetitionPage() {
  const dispatch = useAppDispatch();
  const {id: competitionId} = useParams();
  const competition = useAppSelector(getCurrentCompetition);
  const competitionError = useAppSelector(getCompetitionErrorStatus) || competition === null;
  const [selectedView, setSelectedView] = useState<"table" | "calendar">("table");
  const currentURL = window.location.href;
  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.Competitions,
    {title: competition?.full_name || "", url: currentURL},
  ];

  const competitionLoading = useAppSelector(getCompetitionLoadingStatus);
  const competitionsTablesLoading = useAppSelector(getCompetitionsTablesLoadingStatus);
  const competitionsMatchesLoading = useAppSelector(getCompetitionsMatchesLoadingStatus);
  const competitionPageLoading = competitionLoading || competitionsTablesLoading || competitionsMatchesLoading;

  useEffect(() => {
    dispatch(fetchCompetition(Number(competitionId)));
  }, [competitionId]);

  const handleSwitchView = (view: "table" | "calendar") => {
    setSelectedView(view);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!competitionPageLoading}/>
      <div className={`gap gap--35 ${competitionPageLoading ? 'hidden' : ''}`}>
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap gap--50">
          <div className="gap gap--30">
            <Subtitle
              title={competitionError ? `Произошла ошибка при загрузке соревнования с идентификатором ${competitionId}` : competition?.full_name}
            />
            <CompetitionViewSwitcher
              selectedView={selectedView}
              onSwitchView={handleSwitchView}
            />
            <CompetitionContent selectedView={selectedView}/>
          </div>
        </div>
      </div>
      <Aside/>
    </div>
  );
}

export default CompetitionPage;
