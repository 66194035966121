import React from "react";
import ButtonsItem from "../../ordinary/buttons-item/buttons-item";
import {useAppSelector} from "../../../core/hooks";
import {
  getPages,
  getPagesErrorStatus
} from "../../../core/store/pages-data/selectors";
import Texts from "../../ordinary/texts/texts";

function Buttons() {
  const pages = useAppSelector(getPages);
  const pagesError = useAppSelector(getPagesErrorStatus)

  return (
    <>
      <ul className={`buttons ${pages.length === 0 ? 'hidden' : ''}`}>
        {pages.map((page) => <ButtonsItem key={page.page_id} page={page}/>)}
      </ul>
      <Texts
        texts="Произошла ошибка при загрузке навигации статических страниц"
        hidden={!pagesError}
      />
    </>
  );
}


export default Buttons;
