import {createSlice} from '@reduxjs/toolkit';
import {
  fetchPage,
  fetchPageAlias,
  fetchPages
} from '../api-actions';
import {PagesState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {ApiPagesAliasGetAliasEnum} from "../../../api";
import {filterItemsByAbout} from "../../utils/array-prepare-helpers";

const initialState: PagesState = {
  isPagesLoading: false,
  isPageLoading: false,
  isPagesError: false,
  isPageError: false,
  pages: [],
  currentPage: null,
  mainAlias: ''
};

export const pagesData = createSlice({
  name: NameSpace.Pages,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPages.pending, (state) => {
        state.isPagesLoading = true;
      })
      .addCase(fetchPages.fulfilled, (state, action) => {
        state.pages = filterItemsByAbout(action.payload);
        const redactionPage = state.pages.find(page => page.alias === ApiPagesAliasGetAliasEnum.Redaction);
        if (redactionPage) {
          state.mainAlias = redactionPage.alias;
        } else {
          state.mainAlias = state.pages.length > 0 ? state.pages[0].alias : ''
        }
        state.isPagesLoading = false;
      })
      .addCase(fetchPages.rejected, (state) => {
        state.mainAlias = "*";
        state.isPagesError = true;
        state.isPagesLoading = false;
      })
      .addCase(fetchPage.pending, (state) => {
        state.isPageLoading = true;
      })
      .addCase(fetchPage.fulfilled, (state, action) => {
        state.currentPage = action.payload;
        state.isPageLoading = false;
      })
      .addCase(fetchPage.rejected, (state) => {
        state.isPageError = true;
        state.isPageLoading = false;
      })
      .addCase(fetchPageAlias.pending, (state) => {
        state.isPageLoading = true;
      })
      .addCase(fetchPageAlias.fulfilled, (state, action) => {
        state.currentPage = action.payload;
        state.isPageLoading = false;
      })
      .addCase(fetchPageAlias.rejected, (state) => {
        state.isPageError = true;
        state.isPageLoading = false;
      })
  }
});
