import React from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  sortingPostsByExclusive,
  sortingPostsByTop
} from "../../../core/store/default-posts-data/default-posts-data";
import {
  getSorterExclusiveStatus,
  getSorterTopStatus
} from "../../../core/store/default-posts-data/selectors";
import {
  ItemsForFilter,
  Sorters
} from "../../../core/constants/filters";

function Sorter({title, value, items}: { title: string, value: string, items: string }) {
  const dispatch = useAppDispatch();
  const sorterTopStatus = useAppSelector(getSorterTopStatus);
  const sorterExclusiveStatus = useAppSelector(getSorterExclusiveStatus);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {checked} = event.target;
    switch (items) {
      case ItemsForFilter.Posts:
        switch (value) {
          case Sorters.Top.value:
            dispatch(sortingPostsByTop({sorterTopStatus: checked}));
            break;
          case Sorters.Exclusive.value:
            dispatch(sortingPostsByExclusive({sorterExclusiveStatus: checked}));
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="sorts">
      <input
        className="sorts__input"
        type="checkbox"
        id={value}
        checked={(value === "top" && sorterTopStatus) || (value === "exclusive" && sorterExclusiveStatus)}
        onChange={handleCheckboxChange}
      />
      <label className="sorts__label" htmlFor={value}>{title}</label>
    </div>
  );
}

export default Sorter;
