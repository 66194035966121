import {DefaultPostsState, State} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {DefaultPost} from "../../../api";
import {DateRangeType, Option} from "../../types/common";

export const getDefaultPostsLoadingStatus = (state: State): boolean => state[NameSpace.DefaultPosts].isDefaultPostsLoading;
export const getDefaultPostLoadingStatus = (state: State): boolean => state[NameSpace.DefaultPosts].isDefaultPostLoading;
export const getDefaultPostsErrorStatus = (state: State): DefaultPostsState['isDefaultPostsError'] => state[NameSpace.DefaultPosts].isDefaultPostsError;
export const getDefaultPostErrorStatus = (state: State): DefaultPostsState['isDefaultPostError'] => state[NameSpace.DefaultPosts].isDefaultPostError;
export const getDefaultPosts = (state: State): DefaultPost[] => state[NameSpace.DefaultPosts].defaultPosts;
export const getCurrentDefaultPost = (state: State): DefaultPost | null => state[NameSpace.DefaultPosts].currentDefaultPost;
export const getPostsFilterSportStatus = (state: State): Option => state[NameSpace.DefaultPosts].filterSportStatus;
export const getSorterTopStatus = (state: State): boolean => state[NameSpace.DefaultPosts].sorterTopStatus;
export const getSorterExclusiveStatus = (state: State): boolean => state[NameSpace.DefaultPosts].sorterExclusiveStatus;
export const getFilterDateRangeStatus = (state: State): DateRangeType | null => state[NameSpace.DefaultPosts].filterDateRangeStatus;