import React, {useEffect} from "react";
import Title from "../../components/ordinary/title/title";
import HashLink from "../../components/ordinary/hash-link/hash-link";
import Source from "../../components/ordinary/source/source";
import Documents from "../../components/ordinary/documents/documents";
import Photos from "../../components/ordinary/photos/photos";
import Videos from "../../components/ordinary/videos/videos";
import Texts from "../../components/ordinary/texts/texts";
import Command from "../../components/ordinary/command/command";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import TablePage from "../../components/ordinary/table-page/table-page";
import NewsList from "../../components/ordinary/news-list/news-list";
import {AppRoute} from "../../core/constants/routes";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  fetchCompetition,
  fetchDefaultPosts,
  fetchMatchPost,
} from "../../core/store/api-actions";
import {useParams} from "react-router-dom";
import {
  ApiPostsIdGetWithEnum,
  MatchPost
} from "../../api";
import {
  getPosts,
  getPostsErrorStatus,
  getPostsLoadingStatus,
} from "../../core/store/posts-data/selectors";
import {
  getCompetitionLoadingStatus,
  getCurrentCompetition
} from "../../core/store/competitions-data/selectors";
import {getTopItems} from "../../core/utils/array-prepare-helpers";
import AsideNews from "../../components/simple/aside-news/aside-news";
import {
  getCurrentMatchPost,
  getMatchPostErrorStatus,
  getMatchPostLoadingStatus
} from '../../core/store/match-posts-data/selectors'
import Loader from "../../components/simple/loader/loader";

function CompetitionNewsPage() {
  const dispatch = useAppDispatch();
  const currentURL = window.location.href;
  const {competitionId, postId} = useParams();
  const competition = useAppSelector(getCurrentCompetition);
  const match = useAppSelector(getCurrentMatchPost);
  const matchError = useAppSelector(getMatchPostErrorStatus);
  const postsError = useAppSelector(getPostsErrorStatus) || match === null;
  const posts = useAppSelector(getPosts);
  const topPosts = getTopItems(posts, 3);
  const convertedMatch: MatchPost | null = match as MatchPost;

  const competitionLoading = useAppSelector(getCompetitionLoadingStatus);
  const matchLoading = useAppSelector(getMatchPostLoadingStatus);
  const postsLoading = useAppSelector(getPostsLoadingStatus);
  const competitionNewsPageLoading = competitionLoading || matchLoading || postsLoading;

  const crumbsRoutes = [
    AppRoute.Main,
    AppRoute.Competitions,
    {
      title: competition?.full_name || "",
      url: `/competitions/${competitionId}`,
    },
    {title: match?.title || "", url: currentURL},
  ];

  useEffect(() => {
    if (competitionId) {
      dispatch(fetchCompetition(Number(competitionId)));
    }
  }, [competitionId, dispatch]);

  useEffect(() => {
    if (postId) {
      dispatch(
        fetchMatchPost({
          postId: Number(postId),
          _with: [
            ApiPostsIdGetWithEnum.Documents,
            ApiPostsIdGetWithEnum.Videos,
            ApiPostsIdGetWithEnum.Albums,
          ],
        })
      );
    }
  }, [postId, dispatch]);

  useEffect(() => {
    dispatch(fetchDefaultPosts());
  }, [dispatch]);

  return (
    <div className="main__wrap">
      <Loader hidden={!competitionNewsPageLoading}/>
      <div className={`gap gap--60 ${competitionNewsPageLoading ? 'hidden' : ''}`}>
        <div className={`gap gap--25 ${matchError ? "hidden" : ""}`}>
          <Crumbs routes={crumbsRoutes}/>
          <div className="gap gap--60">
            <div className="gap">
              <Command match={convertedMatch}/>
              <HashLink tags={match?.tags}/>
              <Source source={match?.source}/>
            </div>
            <Documents documents={match?.documents}/>
            <Photos albums={match?.albums}/>
            <Videos videos={match?.videos}/>
          </div>
        </div>
        <Texts
          texts={`Произошла ошибка при загрузке матча с индикатором ${postId}`}
          hidden={!matchError}
        />
        {match && <TablePage match={match}/>}
        <div className="gap gap--30">
          <Title title={"Читайте так же"}/>
          <NewsList posts={topPosts}/>
          <Texts
            texts="На данный момент отсутствуют интересные новости."
            hidden={postsError || posts.length > 0}
          />
          <Texts
            texts="Произошла ошибка при загрузке интересных новостей"
            hidden={!postsError}
          />
        </div>
      </div>
      <AsideNews/>
    </div>
  );
}

export default CompetitionNewsPage;
