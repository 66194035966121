import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {Organization} from "../../../api";
import {Option} from "../../types/common";

export const getOrganizationsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Organizations].organizationsLoading;
export const getOrganizationLoadingStatus = (state: State): boolean =>
  state[NameSpace.Organizations].organizationLoading;
export const getOrganizationsErrorStatus = (state: State): boolean=>
  state[NameSpace.Organizations].organizationsError;
export const getOrganizationErrorStatus = (state: State): boolean =>
  state[NameSpace.Organizations].organizationError;
export const getOrganizations = (state: State): Organization[] => state[NameSpace.Organizations].organizations;
export const getOrganization = (state: State): Organization | null =>
  state[NameSpace.Organizations].organization;
export const getOrganizationsFilterDistrictStatus = (state: State): Option[] =>
  state[NameSpace.Organizations].filterDistrictStatus;
export const getOrganizationsFilterSportStatus = (state: State): Option =>
  state[NameSpace.Organizations].filterSportStatus;
export const getOrganizationsFilterFreeStatus = (state: State): boolean =>
  state[NameSpace.Organizations].filterFreeStatus;