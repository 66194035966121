import React from "react";

function LogoProject({className}: { className?: string }) {
  return (
    <a className={`logo-project ${className ? className : ''}`} href="#">
      <svg className="logo-project__img" width="40" height="40">
        <use xlinkHref="images/sprite.svg#v-sport"/>
      </svg>
    </a>
  );
}

export default LogoProject;
