import React from "react";
import FilterItem from "../../smart/filter-item/filter-item";
import Texts from "../texts/texts";
import {DefaultPost} from "../../../api";

function Filter({postsErrorStatus, posts, filteredPosts}: {
  postsErrorStatus: boolean,
  posts: DefaultPost[],
  filteredPosts: DefaultPost[]
}) {
  return (
    <div className="gap">
      <ul className="filter filter--5">
        <FilterItem/>
      </ul>
      <Texts
        texts="Здесь находятся новости нашего портала, но на данный момент они еще не опубликованы."
        text="Ожидайте, так как в скором времени они появятся!"
        hidden={postsErrorStatus || posts.length > 0 || filteredPosts.length > 0}
      />
      <Texts texts="Произошла ошибка при загрузке новостей." hidden={!postsErrorStatus}/>
      <Texts
        texts="По заданным параметрам информации не найдено."
        text="Попробуйте другие."
        hidden={!(posts.length > 0 && !filteredPosts.length)}
      />
    </div>
  );
}

export default Filter;
