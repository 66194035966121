import React from "react";
import Spreading from "../spreading/spreading";
import AsideList from "../../smart/aside-list/aside-list";
import AsideBanners from "../../smart/aside-banners/aside-banners";
import Loader from "../loader/loader";
import {useAppSelector} from "../../../core/hooks";
import {getPostsLoadingStatus} from "../../../core/store/posts-data/selectors";
import {getBannersLoadingStatus} from "../../../core/store/banners-data/selectors";

function AsideNews() {
  const postsLoading = useAppSelector(getPostsLoadingStatus);
  const bannersLoading = useAppSelector(getBannersLoadingStatus);
  const asideNewsLoading = postsLoading || bannersLoading;

  return (
    <>
      <Loader hidden={!asideNewsLoading}/>
      <div className={`aside ${asideNewsLoading ? 'hidden' : ''}`}>
        <div className="gap gap--30">
          <h2 className="aside__title">Главные новости</h2>
          <AsideList/>
        </div>
        <AsideBanners/>
        <Spreading/>
      </div>
    </>
  );
}

export default AsideNews;
