import React from "react";
import MatchItem from "../match-item/match-item";
import {MatchPost} from "../../../api";

function MatchList({matches, query}: {
  matches: MatchPost[],
  query?: string
}) {
  return (
    <ul className="matches">
      {matches.map((match) => (
        <MatchItem
          key={match.post_id}
          match={match}
          query={query}
        />
      ))}
    </ul>
  );
}

export default MatchList;
