import React from 'react';
import {HdbkDocument} from "../../../api";

function DocumentsItem({document}: { document?: HdbkDocument }) {
  return (
    <>
      <li className="documents__item">
        <svg className="documents__icon" width="55" height="55">
          <use xlinkHref="images/sprite.svg#document"/>
        </svg>
        <div className="documents__content">
          <p className="documents__subtitle">{document?.title}</p>
          <a className="documents__link" href={document?._links?.file?.href} download={document?.title}>
            <p className="documents__text">скачать</p>
            <svg className="documents__arrow" width="25" height="10">
              <use xlinkHref="images/sprite.svg#arrow"/>
            </svg>
          </a>
        </div>
      </li>
    </>
  );
}

export default DocumentsItem;
