import React, {useEffect} from 'react';
import {isSafari} from "../../../core/utils/common-helpers";

const ModalContent = ({logo, onClose, showModal}: {
  logo: string;
  onClose: () => void;
  showModal: boolean;
}) => {

  useEffect(() => {
    const body = document.body;
    const html = document.documentElement;
    const handleBodyClass = () => {
      body.classList.toggle(isSafari() ? 'fixed-safari' : 'fixed', showModal);
      html.classList.toggle('fixed-safari', showModal);
    };
    handleBodyClass();
    return () => {
      handleBodyClass();
    };
  }, [showModal]);

  return (
    <div className="modal__content">
      <div className="modal__logos">
        <svg className="modal__logo" width="180" height="75">
          <use xlinkHref={logo}/>
        </svg>
      </div>
      <div className="modal__container">
        <div className="modal__wrap">
          <p className="modal__title">Добро пожаловать</p>
          <p className="modal__text">Приветствуем на новостном портале “Спортивный Петербург”</p>
        </div>
        <button className="modal__button button" onClick={onClose}>На старт!</button>
      </div>
      <a className="modal__close" onClick={onClose}>
        <svg className="modal__icon" width="25" height="25">
          <use xlinkHref="images/sprite.svg#close"/>
        </svg>
      </a>
    </div>
  );
};

export default ModalContent;
