import React from "react";

function AboutAuthor() {
  return (
    <>
      <li className="about__item">
        <img className="about__img" src="/images/persons/author-1.png" width="160" height="160" alt="Прокофьев Евгений Николаевич"/>
        <div className="about__content">
          <p className="about__name">Прокофьев Евгений Николаевич</p>
        </div>
      </li>
      <li className="about__item">
        <img className="about__img" src="/images/persons/author-2.png" width="160" height="160" alt="Иванова Илона Алексеевна"/>
        <div className="about__content">
          <p className="about__name">Иванова Илона Алексеевна</p>
        </div>
      </li>
      <li className="about__item">
        <img className="about__img" src="/images/persons/author-3.png" width="160" height="160" alt="Смирнов Дмитрий Сергеевич"/>
        <div className="about__content">
          <p className="about__name">Смирнов Дмитрий Сергеевич</p>
        </div>
      </li>
    </>
  )
}

export default AboutAuthor;
