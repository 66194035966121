import React from "react";
import AboutItem from "../../simple/about-item/about-item";
import AboutAuthor from "../../simple/about-author/about-author";

const componentMap: { [key: string]: JSX.Element } = {
  redaction: <AboutItem/>,
  authors: <AboutAuthor/>,
};

function AboutList({type}: { type: string }) {
  return (
    <ul className="about">
      {componentMap[type] || null}
    </ul>
  );
}

export default AboutList
