import React, {useEffect, useMemo} from 'react';
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import Maps from "../../components/ordinary/maps/maps";
import {AppRoute} from "../../core/constants/routes";
import Filter4 from "../../components/ordinary/filter-4/filter-4";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {
  getOrganizations,
  getOrganizationsErrorStatus,
  getOrganizationsFilterDistrictStatus,
  getOrganizationsFilterFreeStatus,
  getOrganizationsFilterSportStatus,
  getOrganizationsLoadingStatus
} from "../../core/store/organizations-data/selectors";
import {fetchOrganizations} from "../../core/store/api-actions";
import {
  filterItemsBySport,
  filterItemsByType,
  filterOrganizationsByDistrict
} from "../../core/utils/array-prepare-helpers";
import Loader from "../../components/simple/loader/loader";

function SchoolsPage() {
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.Schools];
  const organizations = useAppSelector(getOrganizations);
  const organizationsError = useAppSelector(getOrganizationsErrorStatus);
  const filterSportStatus = useAppSelector(getOrganizationsFilterSportStatus);
  const filterDistrictStatus = useAppSelector(getOrganizationsFilterDistrictStatus);
  const filterFreeStatus = useAppSelector(getOrganizationsFilterFreeStatus);

  const createProcessedOrganizations = useMemo(() => {
    let processedOrganizations = organizations;
    if (filterSportStatus?.label && filterSportStatus.value !== 'all') {
      processedOrganizations = filterItemsBySport(processedOrganizations, filterSportStatus.label);
    }

    if (filterDistrictStatus?.some(status => status.value !== 'all')) {
      const selectedDistrict = filterDistrictStatus.find(status => status.value !== 'all')?.label;
      if (selectedDistrict) {
        processedOrganizations = filterOrganizationsByDistrict(processedOrganizations, filterDistrictStatus);
      }
    }

    if (filterFreeStatus) {
      processedOrganizations = filterItemsByType(processedOrganizations, filterFreeStatus);
    }

    return processedOrganizations;
  }, [organizations, filterSportStatus, filterDistrictStatus, filterFreeStatus]);

  const schoolsPageLoading = useAppSelector(getOrganizationsLoadingStatus);

  useEffect(() => {
    dispatch(fetchOrganizations());
  }, [dispatch]);

  return (
    <>
      <Loader hidden={!schoolsPageLoading}/>
      <div className={`gap gap--30 ${schoolsPageLoading ? 'hidden' : ''}`}>
        <div className="gap gap--25">
          <Crumbs routes={crumbsRoutes}/>
          <div className="school__titles">
            <Title title="Школы"/>
            <Title title="санкт-петербурга"/>
          </div>
        </div>
        <Filter4
          filteredOrganizations={createProcessedOrganizations}
          organizationsErrorStatus={organizationsError}
          organizations={organizations}
        />
        <Maps organizations={createProcessedOrganizations}/>
      </div>
    </>
  );
}

export default SchoolsPage;
