import {createSlice} from '@reduxjs/toolkit';
import {fetchVideo, fetchVideos} from '../api-actions';
import {VideosState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";

const initialState: VideosState = {
  isVideosLoading: false,
  isVideoLoading: false,
  isVideosError: false,
  isVideoError: false,
  videos: [],
  currentVideo: null,
  playerVideos: [],
};

export const videosData = createSlice({
  name: NameSpace.Videos,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.isVideosLoading = true;
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        if (action.meta.arg.inPlayer){
          state.playerVideos = action.payload
        } else {
          state.videos = action.payload;
        }
        state.isVideosLoading = false;
      })
      .addCase(fetchVideos.rejected, (state) => {
        state.isVideosError = true;
        state.isVideosLoading = false;
      })
      .addCase(fetchVideo.pending, (state) => {
        state.isVideoLoading = true;
      })
      .addCase(fetchVideo.fulfilled, (state, action) => {
        state.currentVideo = action.payload;
        state.isVideoLoading = false;
      })
      .addCase(fetchVideo.rejected, (state) => {
        state.isVideoError = true;
        state.isVideoLoading = false;
      });
  }
});
