import React from 'react';
import NewsItem from "../news-item/news-item";
import {
  DefaultPost,
  Post
} from "../../../api";

function NewsList({posts, query}: {
  posts: DefaultPost[] | Post[],
  query?: string
}) {
  return (
    <ul className={`news ${posts.length === 0 ? 'hidden' : ''}`}>
      {posts.map((post) =>
        <NewsItem
          post={post}
          key={post.post_id}
          query={query}
        />
      )}
    </ul>
  );
}

export default NewsList;
