import React from 'react';

function Source({source}: { source?: string }) {
  return (
    <div className="source">
      <p className="source__title">Источник:</p>
      <p className="source__text">{source || 'Источник не указан'}</p>
    </div>
  );
}

export default Source;
