import {
  Banner,
  Competition,
  CompetitionTable,
  HdbkPartner,
  HdbkSeason,
  HdbkSportKind,
  Organization,
  VoteOption,
} from "../../api";
import {Option} from "../types/common";

export function filterItemsByMain<T extends { on_main?: number }>(
  items: T[] | null | undefined
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items.filter((item) => item && item.on_main);
}

export function filterItemsBySlider<T extends { in_slider?: number }>(
  items: T[] | null | undefined
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items.filter((item) => item && item.in_slider);
}

export function filterItemsInSidebar<T extends { in_sidebar?: number }>(
  items: T[] | null | undefined
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items.filter((item) => item && item.in_sidebar);
}

export function filterItemsByAbout<T extends { in_about?: number }>(
  items: T[] | null | undefined
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items.filter((item) => item && item.in_about);
}

export function filterBannersByPosition(
  banners: Banner[] | null | undefined,
  targetPosition: string
): Banner[] {
  if (!Array.isArray(banners)) {
    throw new Error("Входные данные не являются массивом баннеров");
  }
  return banners.filter(
    (banner) => banner && banner.position === targetPosition
  );
}

export function sortItemsByRank<T extends { rank?: number }>(
  items: T[] | null | undefined
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом");
  }

  return [...items].sort((a, b) => {
    const rankA = a.rank ?? Number.MIN_SAFE_INTEGER;
    const rankB = b.rank ?? Number.MIN_SAFE_INTEGER;

    return rankB - rankA;
  });
}

export function sortPartnersByOrder(
  partners: HdbkPartner[] | null | undefined
): HdbkPartner[] {
  if (!Array.isArray(partners)) {
    throw new Error("Входные данные не являются массивом партнеров");
  }
  return partners.slice().sort((a, b) => {
    const rankA = a.order ?? Number.MIN_SAFE_INTEGER;
    const rankB = b.order ?? Number.MIN_SAFE_INTEGER;
    return rankB - rankA;
  });
}

export function getMostRecentTable(tables: CompetitionTable[]): CompetitionTable[] {
  if (tables.length === 0) return [];
  let mostRecentTable: CompetitionTable | null = null;
  for (let i = 0; i < tables.length; i++) {
    if (!tables[i].updated_at || typeof tables[i].updated_at !== 'number') {
      console.error(`Ошибка: Некорректное значение 'updated_at' в элементе с индексом ${i}.`);
      continue;
    }
    if (!mostRecentTable || tables[i].updated_at > mostRecentTable.updated_at) {
      mostRecentTable = tables[i];
    }
  }
  if (!mostRecentTable) {
    console.error('Ошибка: Не удалось определить самый свежий элемент в массиве.');
    return [];
  }
  return [mostRecentTable];
}

export function sortItemsByPublicationDate<T extends { published_dt?: string }>(
  items: T[] | null | undefined
): T[] {
  if (!items) {
    return [];
  }
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items
    .map((item) => ({
      ...item,
      publishedDate: item.published_dt ? new Date(item.published_dt) : null,
    }))
    .sort((a, b) => {
      if (!b.publishedDate && !a.publishedDate) {
        return 0;
      } else if (!a.publishedDate) {
        return 1;
      } else if (!b.publishedDate) {
        return -1;
      } else {
        return b.publishedDate.getTime() - a.publishedDate.getTime();
      }
    });
}
export function sortByStartDate(competitions: Competition[]): Competition[] {
  const competitionsCopy = [...competitions];

  return competitionsCopy.sort((a, b) => {
    const dateA = a.start_dt ? new Date(a.start_dt).getTime() : 0;
    const dateB = b.start_dt ? new Date(b.start_dt).getTime() : 0;
    return dateA - dateB;
  });
}

export function filterItemsByDateRange<T extends { published_dt?: string }>(
  items: T[] | null | undefined,
  startDate: Date | null,
  endDate: Date | null
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  return items
    .map((item) => ({
      ...item,
      publishedDate: item.published_dt ? new Date(item.published_dt) : null,
    }))
    .filter((item) => {
      const { publishedDate } = item;
      if (!publishedDate) {
        return false;
      }
      return (
        (!startDate || (publishedDate && publishedDate >= startDate)) &&
        (!endDate || (publishedDate && publishedDate <= endDate))
      );
    });
}

export function getTopItems<T extends { published_dt?: string }>(
  items: T[] | null | undefined,
  count: number
): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }
  if (count <= 0) {
    throw new Error("Количество новостей должно быть положительным числом");
  }
  const sortedItems = sortItemsByPublicationDate(items);
  return sortedItems.slice(0, count);
}

export const transformSportsToOptions = (
  sportKinds: HdbkSportKind[] | null | undefined
): Option[] => {
  if (!sportKinds || !Array.isArray(sportKinds)) {
    throw new Error("Неверный ввод: sportKinds должен быть массивом");
  }

  const allSportsOption: Option = {
    value: "all",
    label: "Все виды спорта",
  };

  const transformedOptions = sportKinds.map((sportKind) => {
    if (!sportKind || typeof sportKind !== "object") {
      throw new Error(
        "Неверный ввод: каждый элемент sportKinds должен быть объектом"
      );
    }

    const { alias, title } = sportKind;

    if (typeof alias !== "string") {
      throw new Error("Неверный ввод: alias и title должны быть строками");
    }

    return {
      value: alias,
      label: title,
    };
  });

  return [allSportsOption, ...transformedOptions];
};

export const transformCompetitionsToOptions = (
  competitions: Competition[] | null | undefined
): Option[] => {

  if (!competitions || !Array.isArray(competitions)) {
    throw new Error("Неверный ввод: competitions должны быть массивом");
  }

  return competitions.map((competition) => {
    if (!competition || typeof competition !== "object") {
      throw new Error(
        "Неверный ввод: каждый элемент competitions должен быть объектом"
      );
    }

    const {competition_id, full_name} = competition;

    if (typeof competition_id !== "number" || typeof full_name !== "string") {
      throw new Error("Неверный ввод: competition_id должен быть числом, а full_name - строкой");
    }
    return {
      value: competition_id.toString(),
      label: full_name,
    };
  });
};

export const filterItemsByTag = <T extends { tags?: { name: string }[] }>(
  items: T[] | null | undefined,
  tagName: string | undefined
): T[] => {
  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }
  if (!tagName?.trim()) {
    throw new Error("Неверный ввод: tagName должен быть непустой строкой");
  }
  return items.filter((item) => {
    if (!item || !Array.isArray(item.tags)) {
      throw new Error(
        "Неверный ввод: каждый пост должен содержать массив тегов"
      );
    }
    return item.tags.some((tag) => {
      if (!tag) {
        throw new Error(
          "Неверный ввод: каждый тег должен иметь свойство name типа string"
        );
      }
      return tag.name === tagName;
    });
  });
};

export const filterItemsByYear = <
  T extends { event_date?: string; start_dt?: string }
>(
  items: T[] | null | undefined,
  targetSeason: string | undefined
): T[] => {
  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }

  if (typeof targetSeason !== "string" || !targetSeason.trim()) {
    throw new Error("Неверный ввод: targetSeason должен быть непустой строкой");
  }

  return items.filter((item) => {
    if (
      !item ||
      (typeof item.event_date !== "string" &&
        typeof item.start_dt !== "string")
    ) {
      throw new Error(
        "Неверный ввод: каждый item должен содержать свойство event_date или start_dt типа string"
      );
    }

    const date = item.event_date || item.start_dt || "";
    const publishedYear = new Date(date).getFullYear();
    return publishedYear === Number(targetSeason);
  });
};

export const filterItemsBySeason = <T extends { season?: { title: string } }>(
  items: T[] | null | undefined,
  seasonTitle: string | undefined
): T[] => {
  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }
  if (!seasonTitle?.trim()) {
    throw new Error("Неверный ввод: seasonTitle должен быть непустой строкой");
  }
  return items.filter((item) => {
    if (!item || !item.season || !item.season.title) {
      throw new Error(
        "Неверный ввод: каждый элемент должен содержать объект сезона с свойством title типа string"
      );
    }
    return item.season.title === seasonTitle;
  });
};

export const filterItemsByOneSport = <T extends { sportKind?: { title: string } }>(
  items: T[] | null | undefined,
  sportTitle: string | undefined
): T[] => {
  if (!items || !Array.isArray(items)) {
    console.error('Неверный ввод: items должен быть массивом');
    return [];
  }

  if (!sportTitle?.trim()) {
    console.error('Неверный ввод: sportTitle должен быть непустой строкой');
    return [];
  }

  return items.filter((item) => {
    if (!item || !item.sportKind) {
      console.error('Неверный ввод: каждый элемент должен содержать объект вид спорта');
      return false;
    }

    const { sportKind } = item;

    if (!sportKind) {
      console.error('Неверный ввод: вид спорта должен быть объектом');
      return false;
    }

    return sportKind.title === sportTitle;
  });
};

export const filterItemsByMonth = <T extends { event_date?: string }>(
  items: T[] | null | undefined,
  targetMonth: string | undefined
): T[] => {
  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }

  if (typeof targetMonth !== "string" || !targetMonth.trim()) {
    throw new Error("Неверный ввод: targetMonth должен быть непустой строкой");
  }

  return items.filter((item) => {
    if (!item || typeof item.event_date !== "string") {
      throw new Error(
        "Неверный ввод: каждый item должен содержать свойство event_date типа string"
      );
    }

    const publishedMonth = new Date(item.event_date).toLocaleString("ru-RU", {
      month: "long",
    });
    return publishedMonth.toLowerCase() === targetMonth.toLowerCase();
  });
};

export const filterItemsByPastFuture = <T extends { event_date?: string }>(
  items: T[] | null | undefined,
  targetStatus: string | undefined
): T[] => {
  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }

  if (typeof targetStatus !== "string" || !targetStatus.trim()) {
    throw new Error("Неверный ввод: targetStatus должен быть непустой строкой");
  }

  const currentDate = new Date();

  return items.filter((item) => {
    if (!item || typeof item.event_date !== "string") {
      throw new Error(
        "Неверный ввод: каждый item должен содержать свойство event_date типа string"
      );
    }

    const itemDate = new Date(item.event_date);
    if (targetStatus === "Прошедшие") {
      return itemDate < currentDate;
    } else if (targetStatus === "Будущие") {
      return itemDate >= currentDate;
    }

    return false;
  });
};


export function filterOrganizationsByDistrict(organizations: Organization[], selectedDistricts: Option[]): Organization[] {
  if (!organizations || organizations.length === 0 || !selectedDistricts || selectedDistricts.length === 0) {
    console.error('Неверный ввод: организации и выбранные районы должны быть предоставлены');
  }
  const selectedDistrictValues = selectedDistricts.map(district => district.label);
  if (selectedDistrictValues.includes("Все районы")) {
    return organizations;
  } else {
    return organizations.filter(org => {
      if (!org.address) {
        console.error('Неверный ввод: у организации должен быть указан адрес');
      }
      const matchesDistrict = selectedDistrictValues.some(district => org.address?.includes(district || ''));
      if (!matchesDistrict) {
        console.error('Неверный ввод: организация не соответствует выбранным районам');
      }
      return matchesDistrict;
    });
  }
}

export const filterItemsBySport = <T extends { sportKinds?: { title: string }[] }>(
  items: T[] | null | undefined,
  sportTitle: string | undefined
): T[] => {
  if (!items || !Array.isArray(items)) {
    console.error('Неверный ввод: items должен быть массивом');
    return [];
  }

  if (!sportTitle?.trim()) {
    console.error('Неверный ввод: sportTitle должен быть непустой строкой');
    return [];
  }

  return items.filter((item) => {
    if (!item || !Array.isArray(item.sportKinds)) {
      console.error('Неверный ввод: каждый элемент должен содержать массив видов спорта');
      return false;
    }

    return item.sportKinds.some((sportKind) => {
      if (!sportKind) {
        console.error('Неверный ввод: каждый вид спорта должен быть объектом');
        return false;
      }

      return sportKind.title === sportTitle;
    });
  });
};


export const sortItemsByTag = <T extends { tags?: { name: string }[] }>(
  items: T[] | null | undefined,
  tagName: string | undefined
): T[] => {

  if (!items || !Array.isArray(items)) {
    throw new Error("Неверный ввод: items должен быть массивом");
  }
  if (!tagName?.trim()) {
    throw new Error("Неверный ввод: tagName должен быть непустой строкой");
  }
  return [...items].sort((a, b) => {
    const tagInA = a.tags?.find((tag) => tag.name === tagName);
    const tagInB = b.tags?.find((tag) => tag.name === tagName);
    if (!tagInA && !tagInB) {
      return 0;
    }
    if (!tagInA) {
      return 1;
    }
    if (!tagInB) {
      return -1;
    }
    return tagInA.name.localeCompare(tagInB.name);
  });
};


export function filterItemsByType<T extends { type?: string }>(items: T[] | null | undefined, isGovernmental: boolean): T[] {
  if (!Array.isArray(items)) {
    throw new Error("Входные данные не являются массивом объектов");
  }

  return items.filter((item) => {
    if (!item || !item.type) {
      return false;
    }

    return isGovernmental ? item.type === "governmental" : item.type === "commercial";
  });
}

export function countVotes(options: VoteOption[] | undefined | null): number {
  if (!options || !Array.isArray(options)) {
    console.error(
      "Неверный ввод: options должен быть массивом объектов VoteOption"
    );
    return 0;
  }
  return options.reduce((totalVotes, option) => {
    if (
      option &&
      typeof option === "object" &&
      option.answersCount !== undefined
    ) {
      return totalVotes + option.answersCount;
    }
    return totalVotes;
  }, 0);
}

export function getWinner(
  options: VoteOption[] | undefined
): VoteOption | undefined {
  if (!options || !Array.isArray(options) || options.length === 0) {
    console.error(
      "Неверный ввод: options должен быть массивом объектов VoteOption с длиной больше 0"
    );
    return undefined;
  }
  const winner = options.reduce((prev, current) => {
    if (
      !current ||
      typeof current !== "object" ||
      current.answersCount === undefined
    ) {
      console.error(
        "Неверный ввод: каждый элемент options должен быть объектом VoteOption с полем answersCount"
      );
      return prev;
    }
    return (current.answersCount || 0) > (prev.answersCount || 0)
      ? current
      : prev;
  }, options[0]);

  if (!winner) {
    console.error("Не удалось определить победителя");
  }
  return winner;
}

export const transformSeasonsToOptions = (
  seasons: HdbkSeason[] | null | undefined
): (
  | {
      label: string;
      value: string;
    }
  | { label: string; value: number }
)[] => {
  if (!seasons || !Array.isArray(seasons)) {
    throw new Error("Ошибка: неверный ввод, сезоны должны быть массивом");
  }
  const allSeasonsOption: { label: string; value: string } = {
    value: "all",
    label: "Все сезоны",
  };
  const transformedOptions = seasons.map((season) => {
    if (!season || typeof season !== "object") {
      throw new Error(
        "Ошибка: неверный ввод, каждый элемент в сезонах должен быть объектом"
      );
    }
    const { season_id, title } = season;
    if (typeof season_id !== "number" || typeof title !== "string") {
      throw new Error(
        "Ошибка: неверный ввод, season_id должен быть числом, а title - строкой"
      );
    }
    return {
      value: season_id,
      label: title,
    };
  });
  return [allSeasonsOption, ...transformedOptions];
};
