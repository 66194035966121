import NewList from "../../ordinary/new-list/new-list";
import ButtonLink from "../../ordinary/button-link/button-link";
import React, {useEffect} from "react";
import Title from "../../ordinary/title/title";
import {fetchPosts} from "../../../core/store/api-actions";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getPostsErrorStatus,
  getPostsOnMain
} from "../../../core/store/posts-data/selectors";
import Texts from "../../ordinary/texts/texts";
import {AppRoute} from "../../../core/constants/routes";
import { ApiPostsGetOnMainEnum } from '../../../api'

function New() {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(getPostsOnMain);
  const postsErrorStatus = useAppSelector(getPostsErrorStatus);
  useEffect(() => {
    dispatch(fetchPosts({pageSize:6, onMain:ApiPostsGetOnMainEnum.NUMBER_1}));
  }, [dispatch]);

  return (
    <div className="container container--center">
      <Title title="Новости"/>
      <NewList posts={posts}/>
      <Texts
        texts="На данный момент на главной странице отсутствуют новости."
        hidden={postsErrorStatus || posts.length > 0}
      />
      <Texts
        texts="Произошла ошибка при загрузке новостей для главной страницы"
        hidden={!postsErrorStatus}
      />
      <ButtonLink to={AppRoute.NewsPage.url} hidden={postsErrorStatus || posts.length < 0}/>
    </div>
  );
}

export default New;
