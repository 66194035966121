import React from "react";

function Accessible() {
  return (
    <a className="accessible bvi-open" href="#">
      <svg className="accessible__icon" width="100" height="25">
        <use xlinkHref="images/sprite.svg#version"/>
      </svg>
    </a>
  );
}

export default Accessible;
