import React from "react";

function IconPlay() {
  return (
    <div className="icon">
      <svg className="icon__play" width="30" height="30">
        <use xlinkHref="images/sprite.svg#play"/>
      </svg>
    </div>
  );
}

export default IconPlay;
