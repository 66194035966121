import React, {useEffect} from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  getPostsErrorStatus,
  getPostsInSlider
} from "../../../core/store/posts-data/selectors";
import {fetchPosts} from "../../../core/store/api-actions";
import "tiny-slider/dist/tiny-slider.css";
import {ApiPostsGetInSliderEnum} from "../../../api";
import Texts from "../../ordinary/texts/texts";
import PromoSlider from "../../ordinary/promo-slider/promo-slider";

function Promo() {
  const posts = useAppSelector(getPostsInSlider);
  const postsError = useAppSelector(getPostsErrorStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPosts({pageSize:4, inSlider:ApiPostsGetInSliderEnum.NUMBER_1}));
  }, [dispatch]);

  return (
    <>
      <PromoSlider sortedPosts={posts}/>
      <Texts
        texts="Произошла ошибка при загрузке новостей для слайдера"
        hidden={!postsError}
      />
    </>
  );
}

export default Promo;
