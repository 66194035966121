import {AppRoute} from "./routes";
import {NavigationsType} from "../types/navigation";

export const Navigations: NavigationsType = [
  {
    title: 'Новости',
    url: AppRoute.NewsPage.url,
    icon: 'news'
  },
  {
    title: 'Календарь',
    url: AppRoute.Calendar.url,
    icon: 'calendar'
  },
  // {
  //   title: 'Соревнования',
  //   url: AppRoute.Competitions.url,
  //   icon: 'contest'
  // },
  {
    title: 'Школы СПБ',
    url: AppRoute.Schools.url,
    icon: 'school'
  },
  // {
  //   title: 'Фото',
  //   url: AppRoute.Albums.url,
  //   icon: 'photo'
  // },
  {
    title: 'Видео',
    url: AppRoute.VideoPlaylistsPage.url,
    icon: 'video'
  },
];
