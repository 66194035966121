import React from "react";

function PromoArrows() {
  return (
    <div className="helper__arrows js-posts-controls">
      <a className="helper__link">
        <svg className="helper__arrow" width="20" height="34">
          <use xlinkHref="images/sprite.svg#pointer"/>
        </svg>
      </a>
      <a className="helper__link helper__link--right">
        <svg className="helper__arrow" width="20" height="34">
          <use xlinkHref="images/sprite.svg#pointer"/>
        </svg>
      </a>
    </div>
  );
}

export default PromoArrows;