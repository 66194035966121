import React from "react";

function ButtonsTableCalendar({selectedView, onSwitchView,}: {
  selectedView: "table" | "calendar";
  onSwitchView: (view: "table" | "calendar") => void;
}) {
  return (
    <div className="buttons">
      <button
        className={`buttons__item ${selectedView === "table" ? "buttons__item--active js-active" : ""}`}
        onClick={() => onSwitchView("table")}
      >
        Таблица
      </button>
      <button
        className={`buttons__item ${selectedView === "calendar" ? "buttons__item--active js-active" : ""}`}
        onClick={() => onSwitchView("calendar")}
      >
        Календарь матчей
      </button>
    </div>
  );
}

export default ButtonsTableCalendar;
