import React from 'react';
import Title from "../title/title";
import DocumentsList from "../documents-list/documents-list";
import {HdbkDocument} from "../../../api";

function Documents({documents}: { documents?: HdbkDocument[] }) {
  return (
    <div className={`gap gap--30 gap--center ${documents?.length === 0 ? 'hidden' : ''}`}>
      <Title title="Документы"/>
      <DocumentsList documents={documents} hidden={documents?.length === 0}/>
    </div>
  );
}

export default Documents;
