import {Post} from "../../../api";
import React from "react";

function PromoNav({post}: { post: Post }) {
  return (
    <div className="info__slide" key={post.post_id}>
      <div className="info__item">
        <img className="info__image" src={post._links?.image460x260?.href?.includes('image_placeholder_460x260.svg') ? '/images/defaults/news1.svg' : post._links?.image460x260?.href} width="325" height="185" alt={post.title}/>
        <p className="info__context">{post.title}</p>
      </div>
    </div>
  );
}

export default PromoNav;
