import React from "react";
import {Banner} from "../../../api";
import {Link} from "react-router-dom";

function BannerItem({banner}: { banner: Banner }) {
  return (
    <Link className="banner" to={banner.link || ''} target={banner.blank ? "_blank" : "_self"}>
      <img className="banner__img" src={banner._links?.image?.href} width="1300" height="170" alt={banner.name}/>
    </Link>
  );
}

export default BannerItem;
