import React from "react";
import {Post} from "../../../api";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";

function AsideItem({post}: { post: Post }) {
  const publishedDate: string | undefined = post.published_dt;
  const formattedDate: string | null = publishedDate ? simpleDateFormat(new Date(publishedDate), 'dd MMMM yyyy') : 'Дата не известна';

  return (
    <li className="aside__item">
      <Link className="aside__link" to={`${AppRoute.NewsPage.url}/${post.post_id}`}>
        <p className="aside__date">{formattedDate}</p>
        <p className="aside__text">{post.title}</p>
      </Link>
    </li>
  );
}

export default AsideItem
