import {PartnersState, State} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {HdbkPartner} from "../../../api";

export const getPartnersLoadingStatus = (state: State): boolean => state[NameSpace.Partners].isPartnersLoading;
export const getPartnerLoadingStatus = (state: State): boolean => state[NameSpace.Partners].isPartnerLoading;
export const getPartnersErrorStatus = (state: State): PartnersState['isPartnersError'] => state[NameSpace.Partners].isPartnersError;
export const getPartnerErrorStatus = (state: State): PartnersState['isPartnerError'] => state[NameSpace.Partners].isPartnerError;
export const getPartners = (state: State): HdbkPartner[] => state[NameSpace.Partners].partners;
export const getCurrentPartner = (state: State): HdbkPartner | null => state[NameSpace.Partners].currentPartner;
