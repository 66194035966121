import { createSlice } from "@reduxjs/toolkit";
import { fetchSports } from "../api-actions";
import { SportsState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";

const initialState: SportsState = {
  isSportsLoading: false,
  isSportsError: false,
  headerSports: [],
  filterSports: [],
};

export const sportsData = createSlice({
  name: NameSpace.Sports,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSports.pending, (state) => {
        state.isSportsLoading = true;
      })
      .addCase(fetchSports.fulfilled, (state, action) => {
        if (action.meta.arg.onMain) {
          state.headerSports = action.payload;
        } else {
          state.filterSports = action.payload;
        }
        state.isSportsLoading = false;
      })
      .addCase(fetchSports.rejected, (state) => {
        state.isSportsError = true;
        state.isSportsLoading = false;
      });
  },
});
