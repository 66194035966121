import React from "react";
import CalendarItem from "../calendar-item/calendar-item";
import {EventPost} from "../../../api";

function CalendarList({events, query}: {
  events: EventPost[],
  query?: string
}) {
  return (
    <ul className={`events ${events.length === 0 ? 'hidden' : ''}`}>
      {events.map((event) =>
        <CalendarItem
          key={event.post_id}
          event={event}
          query={query}
        />
      )}
    </ul>
  );
}

export default CalendarList;
