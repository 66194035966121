import {createSlice} from '@reduxjs/toolkit';
import {WidgetTablesState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {fetchWidgetTables} from "../api-actions";

const initialState: WidgetTablesState = {
  isWidgetTablesLoading: false,
  isWidgetTablesError: false,
  widgetTables: [],
};

export const widgetTablesData = createSlice({
  name: NameSpace.WidgetTables,
  initialState,
  reducers: {
    resetWidgetTables: (state) => {state.widgetTables = [];},
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWidgetTables.pending, (state) => {
        state.isWidgetTablesLoading = true;
      })
      .addCase(fetchWidgetTables.fulfilled, (state, action) => {
        state.widgetTables = action.payload;
        state.isWidgetTablesLoading = false;
      })
      .addCase(fetchWidgetTables.rejected, (state) => {
        state.isWidgetTablesError = true;
        state.isWidgetTablesLoading = false;
      })
  }
});

export const {
  resetWidgetTables
} = widgetTablesData.actions;
