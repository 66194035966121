import React from "react";
import NavigationList from "../navigation-list/navigation-list";
import LogoProject from "../../ordinary/logo-project/logo-project";

function Navigation() {
  return (
    <div className="navigation">
      <LogoProject className="hide"/>
      <NavigationList/>
    </div>
  );
}

export default Navigation;
