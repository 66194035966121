import React from "react";
import {AppRoute} from "../../../core/constants/routes";
import {ApiPagesAliasGetAliasEnum} from "../../../api";
import {Link} from "react-router-dom";

function FooterInfo() {
  return (
    <div className="footer__wrap">
      <div className="footer__content">
        <p className="footer__copyright">© 2012-2023 «ВСпорте_СПб» Для лиц старше 12 лет</p>
        <div className="footer__links">
          <Link className="footer__link" to={`${AppRoute.StaticPages.url}/${ApiPagesAliasGetAliasEnum.Privacy}`}>Политика конфиденциальности</Link>
          <Link className="footer__link" to={`${AppRoute.StaticPages.url}/${ApiPagesAliasGetAliasEnum.Agreement}`}>Пользовательское соглашение</Link>
        </div>
        <Link className="footer__partner" to="https://sportsoft.ru/" target="_blank">Сайт создан компанией SportSoft</Link>
      </div>
    </div>
  );
}

export default FooterInfo;
