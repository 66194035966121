import React from 'react';

function Arrows({className}: { className?: string }) {
  return (
    <div className={`arrows ${className ? className : ''}`}>
      <a className="arrows__left">
        <svg className="arrows__icon" width="20" height="20">
          <use xlinkHref="images/sprite.svg#pointer"/>
        </svg>
      </a>
      <a className="arrows__right">
        <svg className="arrows__icon" width="20" height="20">
          <use xlinkHref="images/sprite.svg#pointer"/>
        </svg>
      </a>
    </div>
  );
}

export default Arrows;
