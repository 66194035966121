import {Post} from "../../../api";
import React, {
  useRef,
  useState
} from "react";
import TinySlider from "tiny-slider-react";
import {postsSliderConfig} from "../../../core/config/slider-config";
import PromoItem from "../promo-item/promo-item";
import PromoArrows from "../../simple/promo-arrows/promo-arrows";
import PromoNumbers from "../promo-numbers/promo-numbers";
import PromoNavs from "../promo-navs/promo-navs";

function PromoSlider({sortedPosts}: { sortedPosts: Post[] }) {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const mainSliderRef = useRef(null);
  const navSliderRef = useRef(null);

  // eslint-disable-next-line
  const handleSlideChange = (info: any) => {
    setCurrentIndex(info.displayIndex - 1);
    // eslint-disable-next-line
    (navSliderRef.current as any).slider.goTo(info.index);
  };

  return (
    <div className={`slider ${sortedPosts.length === 0 ? 'hidden' : ''}`}>
      <TinySlider onIndexChanged={handleSlideChange} settings={postsSliderConfig} ref={mainSliderRef}>
        {sortedPosts.map((post) => (<PromoItem key={post.post_id} post={post}/>))}
      </TinySlider>
      <div className="helper-info">
        <div className="helper">
          <PromoArrows/>
          <PromoNumbers sortedPosts={sortedPosts} currentIndex={currentIndex}/>
        </div>
        <PromoNavs sortedPosts={sortedPosts} navSliderRef={navSliderRef}/>
      </div>
    </div>
  );
}

export default PromoSlider;