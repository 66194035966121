import React from 'react';
import IconPlay from "../../simple/icon-play/icon-play";
import {Video} from "../../../api";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";

function VideosItem({video}: { video: Video }) {
  const publishedDate: string | undefined = video.published_dt;
  let formattedDate: string | null = '';
  if (publishedDate !== undefined) {
    formattedDate = simpleDateFormat(new Date(publishedDate), 'dd MMMM yyyy');
  }

  return (
    <li className="videos__item">
      <Link className="videos__link" to={`${AppRoute.Videos.url}/${video.video_id}`}>
        <div className="videos__content">
          <img className="videos__img" src={video._links?.previewUrl?.href || '/images/defaults/video.svg'} width="650" height="370" alt={video.title}/>
          <IconPlay/>
        </div>
        <p className="videos__date">{formattedDate}</p>
        <p className="videos__title">{video.title}</p>
      </Link>
    </li>
  );
}

export default VideosItem;
