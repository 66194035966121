import React, {
  useEffect,
  useMemo,
  useState
} from "react";
import Aside from "../../components/simple/aside/aside";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import CalendarList from "../../components/ordinary/calendar-list/calendar-list";
import Subtitle from "../../components/ordinary/subtitle/subtitle";
import FilterDrop from "../../components/ordinary/filter-drop/filter-drop";
import {AppRoute} from "../../core/constants/routes";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  getEventPosts,
  getEventPostsErrorStatus,
  getEventPostsLoadingStatus,
  getEventsFilterMonthStatus,
  getEventsFilterPastFutureStatus,
  getEventsFilterSeasonStatus,
  getEventsFilterSportStatus,
} from "../../core/store/events-data/selector";
import {fetchEventPosts} from "../../core/store/api-actions";
import {
  filterItemsByMonth,
  filterItemsByPastFuture,
  filterItemsByYear,
  filterItemsByTag,
  sortItemsByPublicationDate,
} from "../../core/utils/array-prepare-helpers";
import {EVENTS_PER_PAGE} from "../../core/constants/common";
import Pagination from "../../components/ordinary/pagination/pagination";
import {getSeasonsLoadingStatus} from "../../core/store/seasons-data/selectors";
import Loader from "../../components/simple/loader/loader";

function Calendar() {
  const crumbsRoutes = [AppRoute.Main, AppRoute.Calendar];
  const events = useAppSelector(getEventPosts);
  const eventsErrorStatus = useAppSelector(getEventPostsErrorStatus);
  const filterSportStatus = useAppSelector(getEventsFilterSportStatus);
  const filterSeasonStatus = useAppSelector(getEventsFilterSeasonStatus);
  const filterMonthStatus = useAppSelector(getEventsFilterMonthStatus);
  const filterPastFutureStatus = useAppSelector(getEventsFilterPastFutureStatus);
  const dispatch = useAppDispatch();
  const sortedEvents = sortItemsByPublicationDate(events);

  const eventsLoading = useAppSelector(getEventPostsLoadingStatus);
  const seasonsLoading = useAppSelector(getSeasonsLoadingStatus);
  const calendarPageLoading = eventsLoading || seasonsLoading;

  const createProcessedEvents = useMemo(() => {
    let processedEvents = events;
    if (filterSportStatus?.label && filterSportStatus.value !== 'all') {
      processedEvents = filterItemsByTag(processedEvents, filterSportStatus.label);
    }
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== 'all') {
      processedEvents = filterItemsByYear(processedEvents, filterSeasonStatus.label);
    }
    if (filterMonthStatus?.label && filterMonthStatus.value !== 'all') {
      processedEvents = filterItemsByMonth(processedEvents, filterMonthStatus.label);
    }
    if (filterPastFutureStatus?.label && filterPastFutureStatus.value !== 'all') {
      processedEvents = filterItemsByPastFuture(processedEvents, filterPastFutureStatus.label);
    }
    return processedEvents;
  }, [sortedEvents, filterSportStatus, filterSeasonStatus]);

  const eventsPerPage = EVENTS_PER_PAGE;
  const pageCount = Math.ceil((createProcessedEvents?.length || 0) / eventsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfLastEvent = (currentPage + 1) * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = createProcessedEvents ? createProcessedEvents.slice(indexOfFirstEvent, indexOfLastEvent) : [];

  useEffect(() => {
    dispatch(fetchEventPosts());
  }, [dispatch]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!calendarPageLoading}/>
      <div className={`gap gap--25 ${calendarPageLoading ? 'hidden' : ''}`}>
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap">
          <Title title="Календарь мероприятий"/>
          <FilterDrop events={sortedEvents} eventsErrorStatus={eventsErrorStatus} filteredEvents={createProcessedEvents}/>
          <div className={`gap gap--40 ${currentEvents.length === 0 ? 'hidden' : ''}`}>
            <div className="gap gap--20">
              <Subtitle title="Декабрь"/>
              <CalendarList events={currentEvents}/>
            </div>
          </div>
        </div>
        <Pagination pageCount={pageCount} currentPage={currentPage} onPageClick={handlePageClick}/>
      </div>
      <Aside/>
    </div>
  );
}

export default Calendar;
