import React from "react";
import SpreadingForm from "../../smart/spreading-form/spreading-form";
import {useAppSelector} from "../../../core/hooks";
import {getSendSubscriptionStatus} from "../../../core/store/subscriptions-data/selectors";
import {SendRequestStatus} from "../../../core/constants/common";
import Texts from "../../ordinary/texts/texts";

function Spreading() {
  const sendSubscriptionStatus = useAppSelector(getSendSubscriptionStatus);

  return (
    <>
      <Texts
        texts="Спасибо! Вы успешно подписались на рассылку лучших материалов."
        hidden={sendSubscriptionStatus !== SendRequestStatus.Success}
      />
      <div className={`spreading ${sendSubscriptionStatus === SendRequestStatus.Success || sendSubscriptionStatus === SendRequestStatus.Pending ? 'hidden' : ''}`}>
        <svg className="spreading__img" width="170" height="80">
          <use xlinkHref="images/sprite.svg#vsport"/>
        </svg>
        <p className="spreading__text">Подпишитесь на рассылку лучших материалов</p>
        <SpreadingForm/>
      </div>
    </>
  );
}

export default Spreading;
