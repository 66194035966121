import React from 'react';
import {
  OKShareButton,
  VKShareButton
} from "react-share";
import {CopyToClipboard} from "react-copy-to-clipboard";

function Sharing({shareUrl, title}: { shareUrl: string, title: string }) {
  const handleCopy = () => {
    alert('Ссылка скопирована!');
  };

  return (
    <ul className="social social--blue">
      <li className="social__item">
        <div className="social__block">
          <p className="social__text">поделиться вконтакте</p>
          <svg className="search__arrow" width="12" height="6">
            <use xlinkHref="images/sprite.svg#arrow-bottom"/>
          </svg>
        </div>
        <VKShareButton className="social__link" url={shareUrl} title={title}>
          <svg className="search__icon" width="25" height="25">
            <use xlinkHref="images/sprite.svg#vk"/>
          </svg>
        </VKShareButton>
      </li>
      <li className="social__item">
        <div className="social__block">
          <p className="social__text">поделиться одноклассники</p>
          <svg className="search__arrow" width="12" height="6">
            <use xlinkHref="images/sprite.svg#arrow-bottom"/>
          </svg>
        </div>
        <OKShareButton className="social__link" url={shareUrl}>
          <svg className="search__icon" width="25" height="25">
          <use xlinkHref="images/sprite.svg#ok"/>
          </svg>
        </OKShareButton>
      </li>
      <li className="social__item">
        <div className="social__block">
          <p className="social__text">скопировать ссылку</p>
          <svg className="search__arrow" width="12" height="6">
            <use xlinkHref="images/sprite.svg#arrow-bottom"/>
          </svg>
        </div>
        <CopyToClipboard text={shareUrl} onCopy={handleCopy}>
          <a className="social__link">
            <svg className="search__icon" width="25" height="25">
              <use xlinkHref="images/sprite.svg#website"/>
            </svg>
          </a>
        </CopyToClipboard>
      </li>
    </ul>
  );
}

export default Sharing;
