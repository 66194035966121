import React from "react";
import {Link} from "react-router-dom";

function Button({url}: { url?: string }) {
  return (
    <Link className="button btn" to={url || ''}>
      <p className="button__text">Подробнее</p>
      <svg className="button__icon" width="25" height="10">
        <use xlinkHref="images/sprite.svg#arrow"/>
      </svg>
    </Link>
  )
}

export default Button
