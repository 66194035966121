import React, {
  ReactElement,
  useEffect
} from 'react';
import Header from '../../simple/header/header';
import Footer from '../../simple/footer/footer';
import Navigation from "../../simple/navigation/navigation";
import WelcomeModal from "../welcome-modal/welcome-modal";
import ScrollToTop from "../scroll-to-top/scroll-to-top";
import {useLocation} from "react-router-dom";
import CookieComponent from "../cookie-component/cookie-component";
import {useAppDispatch} from "../../../core/hooks";
import {fetchPages} from "../../../core/store/api-actions";

function Layout({children}: { children: ReactElement }) {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  useEffect(() => {
    dispatch(fetchPages())
  }, [dispatch]);

  return (
    <>
      <div className="body-container">
        <Navigation/>
        <div className="main-wrapper">
          <Header/>
          <main className="main">
            <div className="main__container">
              {children}
              <CookieComponent/>
            </div>
          </main>
          <ScrollToTop/>
          <Footer/>
        </div>
      </div>
      <WelcomeModal/>
    </>
  );
}

export default Layout;
