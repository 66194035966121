import React from "react";
import ContentItemImg from "../content-item-img/content-item-img";
import {Album} from "../../../api";

function ContentListImg({albums}: { albums: Album[] }) {
  return (
    <ul className={`content ${albums.length === 0 ? 'hidden' : ''}`}>
      {albums.map((album) => <ContentItemImg key={album.album_id} album={album}/>)}
    </ul>
  );
}

export default ContentListImg
