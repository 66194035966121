import {State} from "../../types/state";
import {NameSpace} from "../../constants/api-constants";
import {Page} from "../../../api";

export const getPagesLoadingStatus = (state: State): boolean =>
  state[NameSpace.Pages].isPagesLoading;
export const getPageLoadingStatus = (state: State): boolean =>
  state[NameSpace.Pages].isPageLoading;
export const getPagesErrorStatus = (state: State): boolean =>
  state[NameSpace.Pages].isPagesError;
export const getPagesMainAlias = (state: State): string =>
  state[NameSpace.Pages].mainAlias;
export const getPageErrorStatus = (state: State): boolean =>
  state[NameSpace.Pages].isPageError;
export const getPages = (state: State): Page[] => state[NameSpace.Pages].pages;
export const getCurrentPage = (state: State): Page | null =>
  state[NameSpace.Pages].currentPage;
