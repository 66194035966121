import React from 'react';
import Title from "../title/title";
import Arrows from "../arrows/arrows";
import VideosList from "../videos-list/videos-list";
import {Video} from "../../../api";

function Videos({videos}: { videos?: Video[] }) {
  return (
    <div className={`gap gap--30 gap--center ${videos?.length === 0 ? 'hidden' : ''}`}>
      <Title title="Видео"/>
      <div className="videos">
        <VideosList videos={videos}/>
        <Arrows className="arrows--video js-videos-controls"/>
      </div>
    </div>
  );
}

export default Videos;
