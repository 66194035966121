import React from "react";
import {RouteType} from "../../../core/types/app-route";
import {Link} from "react-router-dom";

function CrumbsItem({route}: { route: RouteType }) {
  return (
    <li className="crumbs__item">
      <Link className="crumbs__link" to={route.url}>{route.title}</Link>
    </li>
  );
}

export default CrumbsItem
