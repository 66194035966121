import React from 'react';
import VideosItem from "../videos-item/videos-item";
import {Video} from "../../../api";
import TinySlider from "tiny-slider-react";
import {videosSliderConfig} from "../../../core/config/slider-config";

function VideosList({videos}: { videos?: Video[] }) {
  if (videos && videos.length > 0) {
    return (
      <ul className="videos__list">
        <TinySlider settings={videosSliderConfig}>
          {videos?.map((video) => <VideosItem key={video.video_id} video={video}/>)}
        </TinySlider>
      </ul>
    );
  }
  return null;
}

export default VideosList;
