import React, {useEffect} from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {transformSeasonsToOptions} from "../../../core/utils/array-prepare-helpers";
import {fetchSeasons} from "../../../core/store/api-actions";
import Dropdown from "../dropdown/dropdown";
import {getSeasons} from "../../../core/store/seasons-data/selectors";
import {
  DropdownTypes,
  filterMonthsObjects,
  filterPastFuture,
  ItemsForFilter
} from "../../../core/constants/filters";
import Reset from "../reset/reset";
import SportsFilter from "../sports-filter/sports-filter";
import {resetEventsFilters} from "../../../core/store/events-data/events-data";

function FilterItemDrop() {
  const dispatch = useAppDispatch();
  const seasons = useAppSelector(getSeasons);
  const seasonsOptions = transformSeasonsToOptions(seasons);

  useEffect(() => {
    dispatch(fetchSeasons());
    dispatch(resetEventsFilters());
  }, [dispatch]);

  return (
    <>
      <SportsFilter items={ItemsForFilter.Events}/>
      <li className="filter__item">
        <Dropdown options={seasonsOptions} type={DropdownTypes.Season} items={ItemsForFilter.Events}/>
      </li>
      <li className="filter__item">
        <Dropdown options={filterMonthsObjects} type={DropdownTypes.Month} items={ItemsForFilter.Events}/>
      </li>
      <li className="filter__item">
        <Dropdown options={filterPastFuture} type={DropdownTypes.PastFuture} items={ItemsForFilter.Events}/>
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.Events}/>
      </li>
    </>
  );
}

export default FilterItemDrop;
