import {State, VideoPlaylistsState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {VideoPlaylist} from "../../../api";

export const getVideoPlayListsLoadingStatus = (state: State): boolean => state[NameSpace.VideoPlaylists].isVideoPlaylistsLoading;
export const getVideoPlayListLoadingStatus = (state: State): boolean => state[NameSpace.VideoPlaylists].isVideoPlaylistLoading;
export const getVideoPlayListsErrorStatus = (state: State): VideoPlaylistsState['isVideoPlaylistsError'] => state[NameSpace.VideoPlaylists].isVideoPlaylistsError;
export const getVideoPlayListErrorStatus = (state: State): VideoPlaylistsState['isVideoPlaylistError'] => state[NameSpace.VideoPlaylists].isVideoPlaylistError;
export const getVideoPlayLists = (state: State): VideoPlaylist[] => state[NameSpace.VideoPlaylists].videoPlaylists;
export const getCurrentVideoPlayList = (state: State): VideoPlaylist | null => state[NameSpace.VideoPlaylists].currentVideoPlaylist;
