import ButtonLink from "../../ordinary/button-link/button-link";
import React, {useEffect} from "react";
import ContentListVideo from "../../ordinary/content-list-video/content-list-video";
import Title from "../../ordinary/title/title";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {fetchVideos} from "../../../core/store/api-actions";
import {
  getVideos,
  getVideosErrorStatus
} from "../../../core/store/videos-data/selectors";
import Texts from "../../ordinary/texts/texts";
import {AppRoute} from "../../../core/constants/routes";
import { ApiVideosGetOnMainEnum } from '../../../api'

function ContentVideo() {
  const dispatch = useAppDispatch();
  const videos = useAppSelector(getVideos);
  const videosError = useAppSelector(getVideosErrorStatus);
  useEffect(() => {
    dispatch(fetchVideos({pageSize:6, onMain:ApiVideosGetOnMainEnum.NUMBER_1}));
  }, [dispatch]);

  return (
    <div className="gap gap--30 gap--center">
      <Title title="Видео"/>
      <ContentListVideo videos={videos}/>
      <Texts
        texts="На данный момент на главной странице отсутствует видео."
        hidden={videosError || videos.length > 0}
      />
      <Texts
        texts="Произошла ошибка при загрузке видео для главной страницы"
        hidden={!videosError}
      />
      <ButtonLink to={AppRoute.Videos.url} hidden={videosError || videos.length < 0}/>
    </div>
  );
}

export default ContentVideo;
