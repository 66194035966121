import React from "react";
import AboutList from "../about-list/about-list";

function AboutBlock({title, type}: { title: string, type: string }) {
  return (
    <div className="gap gap--20">
      <h2 className="subtitle">{title}</h2>
      <AboutList type={type}/>
    </div>
  )
}

export default AboutBlock
