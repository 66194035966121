import React from 'react';
import Texts from "../../components/ordinary/texts/texts";
import Aside from "../../components/simple/aside/aside";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import ButtonsSearch from "../../components/simple/buttons-search/buttons-search";
import Searching from "../../components/smart/searching/searching";
import {AppRoute} from "../../core/constants/routes";
import NewsList from "../../components/ordinary/news-list/news-list";
import {useAppSelector} from "../../core/hooks";
import {
  getSearchedPosts,
  getSearchedPostsError,
  getSearchedPostsLoading,
  getSearchString
} from "../../core/store/searches-data/selectors";
import Loader from "../../components/simple/loader/loader";

function SearchPostsPage() {
  const crumbsRoutes = [
    AppRoute.Main,
    {title: 'Поиск', url: AppRoute.SearchPostsPage.url},
    AppRoute.SearchPostsPage
  ];
  const searchedPosts = useAppSelector(getSearchedPosts);
  const searchedPostsError = useAppSelector(getSearchedPostsError);
  const searchedPostsLoading = useAppSelector(getSearchedPostsLoading);
  const searchString = useAppSelector(getSearchString);

  return (
    <div className="main__wrap">
      <div className="gap gap--25">
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap">
          <Title title="Поиск"/>
          <div className="gap gap--30">
            <Searching/>
            <ButtonsSearch/>
          </div>
          <Loader hidden={!searchedPostsLoading}/>
          <div className={`gap gap--25 ${searchedPostsLoading ? 'hidden' : ''}`}>
            <NewsList
              posts={searchedPosts || []}
              query={searchString}
            />
            <Texts
              texts="По Вашему запросу новостей не найдено." text="Попробуйте ввести другой."
              hidden={searchedPostsError || (searchedPosts !== undefined && searchedPosts.length > 0) || searchString.length === 0}
            />
            <Texts
              texts="Что-то пошло не так. Пожалуйста, попробуйте еще раз позже."
              hidden={!searchedPostsError}
            />
            <Texts
              texts="Введите ключевое слово или фразу для начала поиска."
              hidden={searchedPostsError || searchString.length > 0}
            />
          </div>
        </div>
      </div>
      <Aside/>
    </div>
  );
}

export default SearchPostsPage;
