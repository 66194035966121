import React from "react";
import RequisiteList from "../../simple/requisite-list/requisite-list";
import Subtitle from "../subtitle/subtitle";

function Requisite({hidden}: { hidden: boolean }) {
  return (
    <div className={`gap gap--20 ${hidden ? 'hidden' : ''}`}>
      <Subtitle title="Реквизиты"/>
      <RequisiteList/>
    </div>
  );
}

export default Requisite
