import React from "react";
import Texts from "../texts/texts";

function TablePages({table}: { table?: Record<string, Record<string, string>> | null }) {
  if (!table || typeof table !== 'object') {
    return <Texts texts="Таблица ещё не сформирована"/>;
  }

  const headers = Object.keys(table[1] || {});

  if (headers.length === 0) {
    return <Texts texts="Отсутствуют данные для отображения в таблице"/>;
  }

  return (
    <div className="table-scroll">
      <div className="table-border">
        <table className="table">
          <thead className="table__thead">
          <tr className="table__row">
            <th className="table__th table__number">
              <p>№</p>
            </th>
            {headers.map((header, index) => (
              <th className="table__th" key={index}>
                <p className="table__text hide-480">{table[1][header]}</p>
                <p className="table__text show-480">{table[1][header]?.charAt(0)}</p>
              </th>
            ))}
          </tr>
          </thead>
          <tbody className="table__tbody">
          {Object.keys(table).map((rowKey, rowIndex) => {
            if (rowIndex < 1) {
              return null;
            }
            const rowData = table[rowKey];
            return (
              <tr className="table__row" key={rowIndex}>
                <td className="table__cell table__number">
                  <p>{rowKey}</p>
                </td>
                {headers.map((header, colIndex) => (
                  <td className="table__cell" key={colIndex}>
                    <p>{rowData[header]}</p>
                  </td>
                ))}
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TablePages;
