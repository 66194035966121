import React, {useEffect} from "react";
import Dropdown from '../dropdown/dropdown'
import Reset from '../reset/reset'
import {
  useAppDispatch,
  useAppSelector
} from '../../../core/hooks'
import {transformSeasonsToOptions} from '../../../core/utils/array-prepare-helpers'
import {getSeasons} from '../../../core/store/seasons-data/selectors'
import {fetchSeasons}from '../../../core/store/api-actions'
import {
  DropdownTypes,
  ItemsForFilter
} from '../../../core/constants/filters'
import SportsFilter from "../sports-filter/sports-filter";
import {resetCompetitionsFilters} from "../../../core/store/competitions-data/competitions-data";

function FilterItem3() {
  const dispatch = useAppDispatch();
  const seasons = useAppSelector(getSeasons);
  const seasonsOptions = transformSeasonsToOptions(seasons);

  useEffect(() => {
    dispatch(fetchSeasons());
    dispatch(resetCompetitionsFilters());
  }, [dispatch]);

  return (
    <>
      <SportsFilter items={ItemsForFilter.Competitions}/>
      <li className="filter__item">
        <Dropdown options={seasonsOptions} type={DropdownTypes.Season} items={ItemsForFilter.Competitions}/>
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.Competitions}/>
      </li>
    </>
  );
}

export default FilterItem3;
