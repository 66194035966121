import React from 'react';
import Title from "../title/title";
import PhotosList from "../photos-list/photos-list";
import Arrows from "../arrows/arrows";
import {Album} from "../../../api";

function Photos({albums}: { albums?: Album[] }) {
  return (
    <div className={`gap gap--30 gap--center ${albums?.length === 0 ? 'hidden' : ''}`}>
      <Title title="Фото"/>
      <div className="photos">
        <PhotosList albums={albums}/>
        <Arrows className="js-albums-controls"/>
      </div>
    </div>
  );
}

export default Photos;
