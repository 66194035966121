import {createSlice} from '@reduxjs/toolkit';
import {fetchActualVote, sendVote} from '../api-actions';
import {VotesState} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {SendRequestStatus} from "../../constants/common";

const initialState: VotesState = {
  isActualVoteLoading: false,
  isActualVoteError: false,
  actualVote: null,
  sendStatus: SendRequestStatus.Normal,
  sendHash: undefined,
  sendErrors: [],
};

export const votesData = createSlice({
  name: NameSpace.Votes,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchActualVote.pending, (state) => {
        state.isActualVoteLoading = true;
      })
      .addCase(fetchActualVote.fulfilled, (state, action) => {
        state.actualVote = action.payload;
        state.isActualVoteLoading = false;
      })
      .addCase(fetchActualVote.rejected, (state) => {
        state.isActualVoteError = true;
        state.isActualVoteLoading = false;
      })
      .addCase(sendVote.pending, (state) => {
        state.sendStatus = SendRequestStatus.Pending;
      })
      .addCase(sendVote.fulfilled, (state, action) => {
        state.sendStatus = SendRequestStatus.Success;
        state.sendHash = action.payload.hash;
        state.sendErrors = action.payload.errors;
      })
      .addCase(sendVote.rejected, (state) => {
        state.sendStatus = SendRequestStatus.UnSuccess;
      });
  }
});