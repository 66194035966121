import React from 'react';
import {Social} from "../../../api";
import {Link} from "react-router-dom";

function SocialItem({social}: { social: Social }) {
  return (
    <li className="social__item">
      <Link className="social__link" to={social.url} target='_blank' rel='noopener noreferrer'>
        <svg className="search__icon" width="25" height="25">
          <use xlinkHref={`images/sprite.svg#${social.social}`}/>
        </svg>
      </Link>
    </li>
  );
}

export default SocialItem;
