import React from "react";
import Select, {
  ActionMeta,
  MultiValue
} from 'react-select';
import {Option} from "../../../core/types/common";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {DropdownPlaceholders} from "../../../core/constants/filters";
import {districts} from "../../../core/constants/common";
import {filteringOrganizationsByDistrict} from "../../../core/store/organizations-data/organizations-data";
import {getOrganizationsFilterDistrictStatus} from "../../../core/store/organizations-data/selectors";

function DropdownMulti() {
  const dispatch = useAppDispatch();
  const filterDistrictStatus = useAppSelector(getOrganizationsFilterDistrictStatus);

  const handleFilterChange = (selectedOptions: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    if (actionMeta.action === 'select-option' || actionMeta.action === 'remove-value') {
      const selectedValues = selectedOptions as Option[];
      dispatch(filteringOrganizationsByDistrict({filterDistrictStatus: selectedValues}));
    }
  };

  return (
    <Select
      defaultValue={districts[0]}
      options={districts}
      placeholder={DropdownPlaceholders.District}
      onChange={handleFilterChange}
      classNamePrefix="select"
      className="select"
      value={filterDistrictStatus}
      isMulti={true}
      isClearable={false}
    />
  );
}

export default DropdownMulti;