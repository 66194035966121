import React, {
  FormEvent,
  useEffect,
  useState
} from "react";
import BallotCheckbox from "../ballot-checkbox/ballot-checkbox";
import {Vote} from "../../../api";
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {
  initialVoteFormState,
  SendRequestStatus
} from "../../../core/constants/common";
import {getSendVoteStatus} from "../../../core/store/votes-data/selectors";
import {
  fetchIpAddress,
  sendVote
} from "../../../core/store/api-actions";
import {validateVoteForm} from "../../../core/utils/form-validate-helpers";

function BallotForm({vote, hidden}: { vote: Vote | null, hidden: boolean }) {
  const dispatch = useAppDispatch();
  const [userIpAddress, setIpAddress] = useState<string | null>(null);
  const [formData, setFormData] = useState(initialVoteFormState);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const sendVoteStatus = useAppSelector(getSendVoteStatus);

  useEffect(() => {
    if (sendVoteStatus === SendRequestStatus.UnSuccess) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        general: "Что-то пошло не так. Пожалуйста, попробуйте еще раз позже."
      }));
    } else {
      setErrors({});
    }
  }, [sendVoteStatus]);

  useEffect(() => {
    const fetchUserIpAddress = async () => {
      try {
        const ipAddress = await fetchIpAddress();
        setIpAddress(ipAddress);
      } catch (error) {
        console.error('Ошибка при получении IP-адреса:', error);
        setErrors((prevErrors) => ({
          ...prevErrors,
          ipAddress: 'Ошибка при получении IP-адреса, проголосовать не получится',
        }));
      }
    };

    fetchUserIpAddress();
  }, []);

  useEffect(() => {
    if (userIpAddress) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ip: userIpAddress.toString(),
      }));
    }
  }, [userIpAddress]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newErrors = validateVoteForm(formData, userIpAddress);
    setErrors((prevErrors) => ({...prevErrors, ...newErrors}));

    if (Object.keys(newErrors).length === 0) {
      dispatch(sendVote(formData));
    }
  };

  return (
    <>
      <form className={`ballot__form ballot__form--option ${hidden ? 'hidden' : ''}`} onSubmit={handleSubmit}>
        <div className="ballot__content">
          {vote?.options?.map((option) => (
            <BallotCheckbox key={option?.option_id} option={option} setFormData={setFormData}/>
          ))}
        </div>
        <button className="button ballot__button" type="submit">Проголосовать</button>
      </form>
      <div className={`ballot__helps ${Object.keys(errors).length === 0 ? 'hidden' : ''}`}>
        {Object.entries(errors).map(([fieldName, errorMessage]) => (
          <p className="ballot__help" key={fieldName}>{errorMessage}</p>
        ))}
      </div>
    </>
  );
}

export default BallotForm;