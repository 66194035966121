import HashLink from "../hash-link/hash-link";
import Button from "../button/button";
import React from "react";
import {Post} from "../../../api";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {AppRoute} from "../../../core/constants/routes";

function PromoItem({post}: { post: Post }) {
  const publishedDate: string | undefined = post.published_dt;
  const formattedDate: string | null = publishedDate ? simpleDateFormat(new Date(publishedDate), 'dd MMMM yyyy') : 'Дата не известна';

  return (
    <div>
      <div className="promo">
        <img className="promo__background" src="/images/background-promo.png" width="830" height="337" alt="Задний фон промо"/>
        <div className="promo__container">
          <p className="promo__date">{formattedDate}</p>
          <div className="promo__content">
            <h2 className="promo__title">{post.title}</h2>
            <p className="promo__subtitle">{post.lead}</p>
            <HashLink tags={post.tags}/>
            <Button url={`${AppRoute.NewsPage.url}/${post.post_id}`}/>
          </div>
        </div>
        <div className="promo__wrap">
          <img className="page__img" src={post._links?.image?.href?.includes('image_placeholder_thumb.svg') ? '/images/defaults/news1.svg' : post._links?.image?.href} width="785" height="440" alt={post.title}/>
          <div className="promo__line"></div>
        </div>
      </div>
    </div>
  );
}

export default PromoItem;
