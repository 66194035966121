import {createSlice} from '@reduxjs/toolkit';
import {fetchSeasons} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {SeasonsState} from "../../types/state";

const initialState: SeasonsState = {
  isSeasonsLoading: false,
  isSeasonsError: false,
  seasons: [],
};

export const seasonsData = createSlice({
  name: NameSpace.Seasons,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSeasons.pending, (state) => {
        state.isSeasonsLoading = true;
      })
      .addCase(fetchSeasons.fulfilled, (state, action) => {
        state.seasons = action.payload;
        state.isSeasonsLoading = false;
      })
      .addCase(fetchSeasons.rejected, (state) => {
        state.isSeasonsError = true;
        state.isSeasonsLoading = false;
      })
  }
});