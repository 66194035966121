import React from "react";
import HeaderPage from "../header-page/header-page";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";
import { Post } from "../../../api";

function RedactorStyles({ post }: { post?: Post | null }) {
  return (
    <div className="new-page gap gap--20">
      <HeaderPage
        title={post?.title}
        author={post?.author}
        date={post?.publishedDt}
      />
      <div className="gap gap--30">
        <img
          className="new-page__img"
          src={
            post?._links?.image870x490?.href
              ? post._links.image870x490.href.includes(
                  "image_placeholder_870x490.svg"
                )
                ? "/images/defaults/news1.svg"
                : post._links.image870x490.href
              : post?._links?.image?.href || "/images/defaults/news1.svg"
          }
          alt={post?.title}
        />
        <div className="redactor-styles">
          <HtmlRenderer htmlString={post?.text || "Информация отсутствует"} />
        </div>
      </div>
    </div>
  );
}

export default RedactorStyles;
