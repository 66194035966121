import React from "react"
import NavigationItem from "../../ordinary/navigation-item/navigation-item"
import {Navigations} from "../../../core/constants/navigations"
import {AppRoute} from "../../../core/constants/routes"
import {useAppSelector} from "../../../core/hooks"
import {getPagesMainAlias} from "../../../core/store/pages-data/selectors"

function NavigationList() {
  const mainAlias = useAppSelector(getPagesMainAlias)

  return (
    <ul className="navigation__list">
      <NavigationItem navigation={{
        title: 'Главная',
        url: AppRoute.Main.url,
        icon: 'home'
      }}/>
      <NavigationItem navigation={{
        title: 'О нас',
        url: `${AppRoute.AboutStaticPages.url}/${mainAlias}`,
        icon: 'person'
      }}/>
      {Navigations.map((navigation) =>
        <NavigationItem navigation={navigation} key={navigation.icon}/>
      )}
    </ul>
  )
}

export default NavigationList
