import React, {
  useEffect,
  useMemo,
  useState
} from "react";
import Aside from "../../components/simple/aside/aside";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import CompetitionList from "../../components/ordinary/competition-list/competition-list";
import Filter3 from "../../components/ordinary/filter-3/filter-3";
import {AppRoute} from "../../core/constants/routes";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  getCompetitions,
  getCompetitionsErrorStatus,
  getCompetitionsFilterSeasonStatus,
  getCompetitionsFilterSportStatus,
  getCompetitionsLoadingStatus,
} from "../../core/store/competitions-data/selectors";
import {fetchCompetitions} from "../../core/store/api-actions";
import {COMPETITIONS_PER_PAGE} from "../../core/constants/common";
import Pagination from "../../components/ordinary/pagination/pagination";
import {
  sortByStartDate,
  filterItemsBySeason,
  filterItemsByOneSport,
} from "../../core/utils/array-prepare-helpers";
import {getSeasonsLoadingStatus} from "../../core/store/seasons-data/selectors";
import Loader from "../../components/simple/loader/loader";

function Competitions() {
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.Competitions];
  const competitions = useAppSelector(getCompetitions);
  const competitionsErrorStatus = useAppSelector(getCompetitionsErrorStatus);
  const filterSportStatus = useAppSelector(getCompetitionsFilterSportStatus);
  const filterSeasonStatus = useAppSelector(getCompetitionsFilterSeasonStatus);

  useEffect(() => {
    dispatch(fetchCompetitions());
  }, [dispatch]);

  const sortedCompetitions = sortByStartDate(competitions);

  const createProcessedCompetitions = useMemo(() => {
    let processedCompetitions = competitions;
    if (filterSportStatus?.label && filterSportStatus.value !== "all") {
      processedCompetitions = filterItemsByOneSport(
        processedCompetitions,
        filterSportStatus.label
      );
    }
    if (filterSeasonStatus?.label && filterSeasonStatus.value !== "all") {
      processedCompetitions = filterItemsBySeason(
        processedCompetitions,
        filterSeasonStatus.label
      );
    }

    return processedCompetitions;
  }, [sortedCompetitions, filterSportStatus, filterSeasonStatus]);

  const competitionsPerPage = COMPETITIONS_PER_PAGE;
  const pageCount = Math.ceil(
    (createProcessedCompetitions?.length || 0) / competitionsPerPage
  );
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfLastCompetition = (currentPage + 1) * competitionsPerPage;
  const indexOfFirstCompetition = indexOfLastCompetition - competitionsPerPage;
  const currentCompetitions = sortedCompetitions
    ? createProcessedCompetitions.slice(indexOfFirstCompetition, indexOfLastCompetition)
    : [];

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo(0, 0);
  };

  const competitionsLoading = useAppSelector(getCompetitionsLoadingStatus);
  const seasonsLoading = useAppSelector(getSeasonsLoadingStatus);
  const competitionsPageLoading = competitionsLoading || seasonsLoading;

  return (
    <div className="main__wrap">
      <Loader hidden={!competitionsPageLoading}/>
      <div className={`gap gap--35 ${competitionsPageLoading ? 'hidden' : ''}`}>
        <Crumbs routes={crumbsRoutes}/>
        <div className="gap">
          <Title title="Соревнования"/>
          <Filter3
            competitions={sortedCompetitions}
            competitionsErrorStatus={competitionsErrorStatus}
            filteredCompetitions={createProcessedCompetitions}
          />
          <div
            className={`gap gap--20 ${
              currentCompetitions.length === 0 ? "hidden" : ""
            }`}
          >
            <CompetitionList competitions={currentCompetitions}/>
          </div>
        </div>
        <div hidden={pageCount < 2}>
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            onPageClick={handlePageClick}
          />
        </div>
      </div>
      <Aside/>
    </div>
  );
}

export default Competitions;
