import React, {useEffect, useMemo, useState} from "react";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import AsideNews from "../../components/simple/aside-news/aside-news";
import News from "../../components/ordinary/news/news";
import Filter from "../../components/ordinary/filter/filter";
import {AppRoute} from "../../core/constants/routes";
import {useAppDispatch, useAppSelector} from "../../core/hooks";
import {fetchDefaultPosts} from "../../core/store/api-actions";
import {
  getDefaultPosts,
  getDefaultPostsErrorStatus,
  getDefaultPostsLoadingStatus,
  getFilterDateRangeStatus,
  getPostsFilterSportStatus,
  getSorterExclusiveStatus,
  getSorterTopStatus,
} from "../../core/store/default-posts-data/selectors";
import {POSTS_PER_PAGE} from "../../core/constants/common";
import Pagination from "../../components/ordinary/pagination/pagination";
import {filterItemsByDateRange, filterItemsByTag, sortItemsByTag,} from "../../core/utils/array-prepare-helpers";
import {Sorters} from "../../core/constants/filters";
import Loader from "../../components/simple/loader/loader";

function NewsPage() {
  const dispatch = useAppDispatch();
  const crumbsRoutes = [AppRoute.Main, AppRoute.NewsPage];
  const postsPerPage = POSTS_PER_PAGE;
  const posts = useAppSelector(getDefaultPosts);
  const postsErrorStatus = useAppSelector(getDefaultPostsErrorStatus);
  const filterSportStatus = useAppSelector(getPostsFilterSportStatus);
  const sorterTopStatus = useAppSelector(getSorterTopStatus);
  const sorterExclusiveStatus = useAppSelector(getSorterExclusiveStatus);
  const dateRangeStatus = useAppSelector(getFilterDateRangeStatus);

  const createProcessedPosts = useMemo(() => {
    let processedPosts = posts;
    if (filterSportStatus?.label && filterSportStatus.value !== "all") {
      processedPosts = filterItemsByTag(
        processedPosts,
        filterSportStatus.label
      );
    }
    if (sorterTopStatus) {
      processedPosts = sortItemsByTag(processedPosts, Sorters.Top.label);
    } else if (sorterExclusiveStatus) {
      processedPosts = sortItemsByTag(processedPosts, Sorters.Exclusive.label);
    }

    if (dateRangeStatus) {
      const { startDate, endDate } = dateRangeStatus;
      processedPosts = filterItemsByDateRange(
        processedPosts,
        startDate,
        endDate
      );
    }

    return processedPosts;
  }, [
    posts,
    filterSportStatus,
    sorterTopStatus,
    sorterExclusiveStatus,
    dateRangeStatus,
  ]);

  const pageCount = Math.ceil(
    (createProcessedPosts?.length || 0) / postsPerPage
  );
  const [currentPage, setCurrentPage] = useState(0);
  const indexOfLastPost = (currentPage + 1) * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = useMemo(() => {
    if (!posts) return [];
    return createProcessedPosts.slice(indexOfFirstPost, indexOfLastPost);
  }, [createProcessedPosts, indexOfFirstPost, indexOfLastPost]);

  const newsPageLoading = useAppSelector(getDefaultPostsLoadingStatus);

  useEffect(() => {
    dispatch(fetchDefaultPosts());
  }, [dispatch]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    setCurrentPage(selectedPage.selected);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!newsPageLoading} />
      <div className={`gap gap--25 ${newsPageLoading ? "hidden" : ""}`}>
        <Crumbs routes={crumbsRoutes} />
        <div className="gap">
          <Title title="Новости" />
          <Filter
            postsErrorStatus={postsErrorStatus}
            posts={posts}
            filteredPosts={createProcessedPosts}
          />
          <News posts={currentPosts} />
        </div>
        <Pagination
          pageCount={pageCount}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </div>
      <AsideNews />
    </div>
  );
}

export default NewsPage;
