import React from 'react';
import Data from "../data/data";
import Subtitle from "../subtitle/subtitle";
import Sharing from "../sharing/sharing";

function HeaderPage({title, author, date}: { title?: string, author?: string, date?: string }) {
  const currentURL = window.location.href;

  return (
    <div className="gap gap--30">
      <Subtitle title={title}/>
      <div className="header-page">
        <Data author={author} date={date}/>
        <Sharing shareUrl={currentURL} title={title || ''}/>
      </div>
    </div>
  );
}

export default HeaderPage;
