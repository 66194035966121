import React from "react";
import {AlbumPhoto} from "../../../api";
import {Link} from "react-router-dom";

function ImagesItem({photo}: { photo: AlbumPhoto }) {
  return (
    <li className="images__item">
      <Link className="images__link" data-fancybox="gallery" to={photo._links?.image?.href || ''}>
        <img className="images__img" src={photo._links?.image?.href || '/images/defaults/photo.svg'} alt={photo.description}/>
      </Link>
    </li>
  );
}

export default ImagesItem
