import React from 'react';
import {Album} from "../../../api";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function PhotosItem({album}: { album: Album }) {
  return (
    <li className="photos__item">
      <Link className="photos__link" to={`${AppRoute.Albums.url}/${album.album_id}`}>
        <img className="photos__img" src={album._links?.cover_urls?.['370x210']?.href || '/images/defaults/photo.svg'} width="650" height="370" alt={album.title}/>
      </Link>
    </li>
  );
}

export default PhotosItem;
