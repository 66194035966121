import {SocialsState, State} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {Social} from "../../../api";

export const getSocialsLoadingStatus = (state: State): boolean => state[NameSpace.Socials].isSocialsLoading;
export const getSocialLoadingStatus = (state: State): boolean => state[NameSpace.Socials].isSocialLoading;
export const getSocialsErrorStatus = (state: State): SocialsState['isSocialsError'] => state[NameSpace.Socials].isSocialsError;
export const getSocialErrorStatus = (state: State): SocialsState['isSocialError'] => state[NameSpace.Socials].isSocialError;
export const getSocials = (state: State): Social[] => state[NameSpace.Socials].socials;
export const getCurrentSocial = (state: State): Social | null => state[NameSpace.Socials].currentSocial;
