import React from "react";
import Hashtags from "../hashtags/hashtags";
import { DefaultPost, Post } from "../../../api";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { Sorters } from "../../../core/constants/filters";
import { highlightSearchQuery } from "../../../core/utils/common-helpers";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function NewsItem({
  post,
  query,
}: {
  post: DefaultPost | Post;
  query?: string;
}) {
  const publishedDate: string | undefined = post.published_dt;
  const formattedDate: string | null = publishedDate
    ? simpleDateFormat(new Date(publishedDate), "dd MMMM yyyy")
    : "Дата не известна";

  const isTop = post.tags?.some((tag) => tag.name === Sorters.Top.label);
  const isExclusive = post.tags?.some(
    (tag) => tag.name === Sorters.Exclusive.label
  );

  return (
    <li className="news__item">
      <Link
        className="news__ref"
        to={`${AppRoute.NewsPage.url}/${post.post_id}`}
      >
        <div className="news__block">
          <img
            className="news__img"
            src={
              post?._links?.image870x490?.href
                ? post._links.image870x490.href.includes(
                    "image_placeholder_870x490.svg"
                  )
                  ? "/images/defaults/news1.svg"
                  : post._links.image870x490.href
                : post?._links?.image?.href || "/images/defaults/news1.svg"
            }
            width="750"
            height="400"
            alt={post.title}
          />
          <ul className={`sort ${!(isTop || isExclusive) ? "hidden" : ""}`}>
            <li className={`sort__item ${!isTop ? "hidden" : ""}`}>
              <svg className="sort__icon" width="10" height="10">
                <use xlinkHref="images/sprite.svg#top" />
              </svg>
              <div className="sort__text">
                <HtmlRenderer htmlString={highlightSearchQuery("Топ", query)} />
              </div>
            </li>
            <li className={`sort__item ${!isExclusive ? "hidden" : ""}`}>
              <svg className="sort__icon" width="10" height="10">
                <use xlinkHref="images/sprite.svg#star" />
              </svg>
              <div className="sort__text">
                <HtmlRenderer
                  htmlString={highlightSearchQuery("Эксклюзив", query)}
                />
              </div>
            </li>
          </ul>
        </div>
        <div className="news__content">
          <div className="news__date">
            <HtmlRenderer
              htmlString={highlightSearchQuery(formattedDate || "", query)}
            />
          </div>
          <div className="news__texts">
            <div className="news__title">
              <HtmlRenderer
                htmlString={highlightSearchQuery(post.title || "", query)}
              />
            </div>
            <div className="news__subtitle">
              <HtmlRenderer
                htmlString={highlightSearchQuery(post.lead || "", query)}
              />
            </div>
            <Hashtags tags={post.tags} query={query} />
          </div>
        </div>
      </Link>
    </li>
  );
}

export default NewsItem;
