import {createSlice} from '@reduxjs/toolkit';
import {fetchSocial, fetchSocials} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {SocialsState} from "../../types/state";

const initialState: SocialsState = {
  isSocialsLoading: false,
  isSocialLoading: false,
  isSocialsError: false,
  isSocialError: false,
  socials: [],
  currentSocial: null,
};

export const socialsData = createSlice({
  name: NameSpace.Socials,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSocials.pending, (state) => {
        state.isSocialsLoading = true;
      })
      .addCase(fetchSocials.fulfilled, (state, action) => {
        state.socials = action.payload;
        state.isSocialsLoading = false;
      })
      .addCase(fetchSocials.rejected, (state) => {
        state.isSocialsError = true;
        state.isSocialsLoading = false;
      })
      .addCase(fetchSocial.pending, (state) => {
        state.isSocialLoading = true;
      })
      .addCase(fetchSocial.fulfilled, (state, action) => {
        state.currentSocial = action.payload;
        state.isSocialLoading = false;
      })
      .addCase(fetchSocial.rejected, (state) => {
        state.isSocialError = true;
        state.isSocialLoading = false;
      });
  }
});
