import React from "react";
import ContentPlay from "../../simple/content-play/content-play";
import {Video} from "../../../api";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function ContentItemVideo({video}: { video: Video }) {
  const publishedDate: string | undefined = video.published_dt;
  const formattedDate: string | null = publishedDate ? simpleDateFormat(new Date(publishedDate), 'dd MMMM yyyy') : 'Дата не известна';

  return (
    <li className="content__item">
      <Link className="content__link" to={`${AppRoute.Videos.url}/${video.video_id}`}>
        <div className="content__imgs">
          <img className="content__img" src={video._links?.previewUrl?.href || '/images/defaults/video.svg'} alt={video.title}/>
          <ContentPlay/>
        </div>
        <div className="content__texts">
          <p className="content__date">{formattedDate}</p>
          <p className="content__title">{video.title}</p>
        </div>
      </Link>
    </li>
  );
}

export default ContentItemVideo
