import React from "react";
import MatchList from "../match-list/match-list";
import {MatchPost} from "../../../api";

function Match({matches, query}: {
  matches?: MatchPost[],
  query?: string
}) {
  return (
    <div className={`gap gap--40 ${matches?.length === 0 ? 'hidden' : ''}`}>
      {matches && <MatchList matches={matches} query={query}/>}
    </div>
  );
}

export default Match;
