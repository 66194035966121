import React from "react";
import LogoProject from "../../ordinary/logo-project/logo-project";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";
import {ApiPagesAliasGetAliasEnum} from "../../../api";

function FooterNav() {
  return (
    <div className="footer__nav">
      <LogoProject className="logo-project--big"/>
      <div className="footer__block">
        <Link className="footer__navigation" to={`${AppRoute.AboutStaticPages.url}/${ApiPagesAliasGetAliasEnum.Redaction}`}>О нас</Link>
        <Link className="footer__navigation" style={{ visibility: 'hidden' }} to={`${AppRoute.StaticPages.url}/${ApiPagesAliasGetAliasEnum.Vacancy}`}>Вакансии</Link>
        <Link className="footer__navigation" style={{ visibility: 'hidden' }} to={`${AppRoute.StaticPages.url}/${ApiPagesAliasGetAliasEnum.Advertisement}`}>Реклама</Link>
      </div>
    </div>
  );
}

export default FooterNav;
