import React from "react";
import Hashtags from "../hashtags/hashtags";
import { EventPost } from "../../../api";
import {
  isFutureDate,
  simpleDateFormat,
} from "../../../core/utils/date-time-helpers";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { highlightSearchQuery } from "../../../core/utils/common-helpers";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function CalendarItem({ event, query }: { event: EventPost; query?: string }) {
  const eventDate: string | undefined = event.event_date;
  const formattedDate: string | null = eventDate
    ? simpleDateFormat(new Date(eventDate), "dd MMMM yyyy")
    : "Дата не известна";
  const isFuture = isFutureDate(eventDate);

  return (
    <li className="events__item">
      <Link
        className="events__link"
        key={event.post_id}
        to={`${AppRoute.NewsPage.url}/${event.post_id}`}
      >
        <div className="events__images">
          <img
            className="events__img"
            src={
              event?._links?.image460x260?.href || "/images/defaults/event.svg"
            }
            width="460"
            height="245"
            alt={event?.title}
          />
          <div
            className={`events__icons ${
              isFuture ? "events__icons--green" : ""
            }`}
          >
            <div className="events__date">
              <HtmlRenderer
                htmlString={highlightSearchQuery(formattedDate || "", query)}
              />
            </div>
            <svg className="events__icon" width="15" height="15">
              <use xlinkHref="images/sprite.svg#events" />
            </svg>
          </div>
        </div>
      </Link>
      <div className="events__content">
        <div className="events__text">
          <HtmlRenderer
            htmlString={highlightSearchQuery(event?.lead || "", query)}
          />
        </div>
        <Hashtags tags={event?.tags} query={query} />
      </div>
    </li>
  );
}

export default CalendarItem;
