import React from "react";
import {CompetitionTable} from "../../../api";
import TeamTable from "../team-table/team-table";

function TeamTables({tables}: { tables: CompetitionTable[] }) {
  return (
    <div className={`gap gap--20 ${tables.length === 0 ? "hidden" : ""}`}>
      {tables.map((table) => (
        <TeamTable key={table.table_id} table={table}/>
      ))}
    </div>
  );
}

export default TeamTables;
