import React from "react";
import FilterItemDrop from "../../smart/filter-item-drop/filter-item-drop";
import {EventPost} from "../../../api";
import Texts from "../texts/texts";

function FilterDrop({eventsErrorStatus, events, filteredEvents}: {
  eventsErrorStatus: boolean,
  events: EventPost[],
  filteredEvents: EventPost[]
}) {
  return (
    <div className="gap">
      <ul className="filter filter--drop">
        <FilterItemDrop/>
      </ul>
      <Texts
        texts="Здесь находятся мероприятия нашего портала, но на данный момент они еще не опубликованы."
        text="Ожидайте, так как в скором времени они появятся!"
        hidden={eventsErrorStatus || events.length > 0 || filteredEvents.length > 0}
      />
      <Texts texts="Произошла ошибка при загрузке новостей." hidden={!eventsErrorStatus}/>
      <Texts
        texts="По заданным параметрам информации не найдено."
        text="Попробуйте другие."
        hidden={!(events.length > 0 && !filteredEvents.length)}
      />
    </div>
  );
}

export default FilterDrop;
