import React from 'react';
import Layout from './components/smart/layout/layout';
import RoutesComponent from './core/routes/routes';
import './assets/css/redactor-styles.css';
import './assets/css/custom-fancybox.css';
import './assets/css/styles.css';
import './assets/css/styles-1180.css';
import './assets/css/styles-780.css';
import './assets/css/styles-480.css';
import './assets/plugins/bvi/css/bvi.min.css';
import './assets/plugins/bvi/css/bvi-font.min.css';
import './assets/plugins/bvi/js/bvi.min.js';
import './assets/plugins/bvi/js/bvi-init.js';

function App() {
  return (
    <Layout>
      <RoutesComponent/>
    </Layout>
  );
}

export default App;
