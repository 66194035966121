import React from "react";
import {Post} from "../../../api";

function PromoNumbers({sortedPosts, currentIndex}: {
  sortedPosts: Post[],
  currentIndex: number
}) {
  return (
    <div className="helper__numbers">
      <p className="helper__big">{currentIndex + 1}</p>
      <p className="helper__line">/</p>
      <p className="helper__small">{sortedPosts.length}</p>
    </div>
  );
}

export default PromoNumbers;