import React, { useEffect } from "react";
import Title from "../../ordinary/title/title";
import PartnersList from "../../ordinary/partners-list/partners-list";
import { fetchPartners } from "../../../core/store/api-actions";
import { useAppDispatch, useAppSelector } from "../../../core/hooks";
import {
  getPartners,
  getPartnersErrorStatus,
} from "../../../core/store/partners-data/selectors";
import Texts from "../../ordinary/texts/texts";
import {
  filterItemsByMain,
  sortPartnersByOrder,
} from "../../../core/utils/array-prepare-helpers";

function Partners() {
  const partners = useAppSelector(getPartners);
  const filteredPartners = filterItemsByMain(partners);
  const sortedPartners = sortPartnersByOrder(filteredPartners);
  const partnersError = useAppSelector(getPartnersErrorStatus);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPartners());
  }, [dispatch]);

  return (
    <div
      className={`container container--center ${partnersError || sortedPartners.length === 0 ? "hidden" : ""}`}
    >
      <Title title={"Наши партнеры"} />
      <PartnersList partners={sortedPartners} />
      <Texts
        texts="Произошла ошибка при загрузке партнеров."
        hidden={!partnersError}
      />
    </div>
  );
}

export default Partners;
