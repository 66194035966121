import {createSlice} from '@reduxjs/toolkit';
import {fetchPartner, fetchPartners} from '../api-actions';
import {NameSpace} from "../../constants/api-constants";
import {PartnersState} from "../../types/state";

const initialState: PartnersState = {
    isPartnersLoading: false,
    isPartnerLoading: false,
    isPartnersError: false,
    isPartnerError: false,
    partners: [],
    currentPartner: null,
};

export const partnersData = createSlice({
    name: NameSpace.Partners,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchPartners.pending, (state) => {
                state.isPartnersLoading = true;
            })
            .addCase(fetchPartners.fulfilled, (state, action) => {
                state.partners = action.payload;
                state.isPartnersLoading = false;
            })
            .addCase(fetchPartners.rejected, (state) => {
                state.isPartnersError = true;
                state.isPartnersLoading = false;
            })
            .addCase(fetchPartner.pending, (state) => {
                state.isPartnerLoading = true;
            })
            .addCase(fetchPartner.fulfilled, (state, action) => {
                state.currentPartner = action.payload;
                state.isPartnerLoading = false;
            })
            .addCase(fetchPartner.rejected, (state) => {
                state.isPartnerError = true;
                state.isPartnerLoading = false;
            });
    }
});
