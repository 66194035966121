import React from "react";
import FooterItem from "../footer-item/footer-item";

function FooterList() {
  return (
    <ul className="footer__list">
      <FooterItem/>
    </ul>
  );
}

export default FooterList;
