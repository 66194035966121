import React from "react";
import {PostsTypeType} from "../../../core/types/common";
import {Link, useLocation} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";
import {useAppSelector} from "../../../core/hooks";
import {
  getSearchedEventsTotal,
  getSearchedMatchesTotal,
  getSearchedPostsTotal
} from "../../../core/store/searches-data/selectors";

function ButtonsSearchItem({type}: { type: PostsTypeType }) {
  const location = useLocation();
  const buttonUrl = `${AppRoute.SearchPages.url}/${type.value}`;
  const postsTotal = useAppSelector(getSearchedPostsTotal);
  const eventsTotal = useAppSelector(getSearchedEventsTotal);
  const matchesTotal = useAppSelector(getSearchedMatchesTotal);

  const getTotal = () => {
    switch (type.value) {
      case 'posts':
        return postsTotal;
      case 'events':
        return eventsTotal;
      case 'matches':
        return matchesTotal;
      default:
        return 0;
    }
  };

  return (
    <li className={`buttons__item ${location.pathname === buttonUrl ? 'buttons__item--active' : ''}`}>
      <Link className="buttons__link" to={buttonUrl}>{type.label} <span className="buttons__number">{getTotal()}</span></Link>
    </li>
  )
}

export default ButtonsSearchItem;
