import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector
} from "../../core/hooks";
import {
  getCurrentPage,
  getPageErrorStatus,
  getPageLoadingStatus
} from "../../core/store/pages-data/selectors";
import {AppRoute} from "../../core/constants/routes";
import {
  getPartners,
  getPartnersErrorStatus,
  getPartnersLoadingStatus
} from "../../core/store/partners-data/selectors";
import {sortPartnersByOrder} from "../../core/utils/array-prepare-helpers";
import {fetchPageAlias} from "../../core/store/api-actions";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import AboutBlock from "../../components/ordinary/about-block/about-block";
import AboutTexts from "../../components/ordinary/about-texts/about-texts";
import Texts from "../../components/ordinary/texts/texts";
import PartnersList from "../../components/ordinary/partners-list/partners-list";
import MapComponent from "../../components/ordinary/map-component/map-component";
import Requisite from "../../components/ordinary/requisite/requisite";
import Loader from "../../components/simple/loader/loader";

function StaticPage() {
  const {alias: alias} = useParams();
  const dispatch = useAppDispatch();
  const page = useAppSelector(getCurrentPage);
  const pageError = useAppSelector(getPageErrorStatus);
  const pageLoading = useAppSelector(getPageLoadingStatus);
  const currentURL = window.location.href;
  const crumbsRoutes = [AppRoute.Main, {title: page?.title || '', url: currentURL}];
  const partners = useAppSelector(getPartners);
  const partnersError = useAppSelector(getPartnersErrorStatus);
  const partnersLoading = useAppSelector(getPartnersLoadingStatus);
  const sortedPartners = sortPartnersByOrder(partners);
  const staticPageLoading = pageLoading || partnersLoading;

  useEffect(() => {
    if (alias != null) {
      dispatch(fetchPageAlias(alias));
    }
  }, [alias]);

  return (
    <>
      <Loader hidden={!staticPageLoading}/>
      <div className={`gap gap--60 ${staticPageLoading ? 'hidden' : ''}`}>
        <div className="gap gap--30">
          <Crumbs routes={crumbsRoutes}/>
          <div className={`gap gap--40 ${alias !== 'redaction' ? 'hidden' : ''}`}>
            <AboutBlock title="Редакция" type="redaction"/>
            <AboutBlock title="Авторы" type="authors"/>
          </div>
        </div>
        <div className={`gap gap--30 ${pageError ? 'hidden' : ''}`}>
          <Title title={page?.title || ''}/>
          <div className={`new-page ${!page?.need_image ? 'hidden' : ''}`}>
            <img className="new-page__img" src={page?._links?.image1130x636?.href || '/images/defaults/news.svg'} alt={page?.title}/>
          </div>
          <AboutTexts info={page?.text}/>
        </div>
        <Texts texts={`Произошла ошибка при загрузке страницы с алиасом ${alias}`} hidden={!pageError}/>
        <div className={alias !== 'partners' ? 'hidden' : ''}>
          <PartnersList className={`partners--four `} partners={partners}/>
          <Texts texts="На данный момент партнеры отсутствуют." hidden={partnersError || sortedPartners.length > 0}/>
          <Texts texts="Произошла ошибка при загрузке партнеров." hidden={!partnersError}/>
        </div>
        <MapComponent hidden={!page?.need_address} page={page}/>
        <Requisite hidden={alias !== 'contacts'}/>
      </div>
    </>
  );
}

export default StaticPage;
