import React from "react";
import Contacts from "../contacts/contacts";
import {
  Map,
  Placemark,
  YMaps
} from "@pbe/react-yandex-maps";
import {Page} from "../../../api";

function MapComponent({hidden, page}: { hidden: boolean, page: Page | null }) {
  const yandexMapsApiKey = process.env.REACT_APP_YANDEX_MAPS_API_KEY;

  if (!yandexMapsApiKey) {
    console.error("API ключ Yandex Maps не найден. Убедитесь, что он установлен в переменных окружения.");
    return null;
  }

  const defaultState = {
    center: [59.939042, 30.315817],
    zoom: 12,
    controls: [],
  };
  return (
    <div className={`map ${hidden ? 'hidden' : ''}`}>
      <div className="map__container">
        <YMaps query={{apikey: yandexMapsApiKey}}>
          <Map
            defaultState={defaultState}
            width="100%"
            height="100%"
            modules={["control.ZoomControl", "control.FullscreenControl"]}
          >
            <Placemark
              key={page?.page_id}
              geometry={[parseFloat(page?.latitude || '0'), parseFloat(page?.longitude || '0')]}
              modules={["geoObject.addon.balloon"]}
              options={{
                hideIconOnBalloonOpen: false,
                iconLayout: 'default#image',
                iconImageHref: '/images/address.svg',
                iconImageSize: window.innerWidth <= 530 ? [30, 38] : [60, 68],
                iconImageOffset: window.innerWidth <= 530 ? [-15, -38] : [-30, -68],
                balloonOffset: window.innerWidth <= 530 ? [0, -33] : [0, -63]
              }}
              properties={{
                balloonContentBody: `<div class="address">${page?.address}</div>`,
              }}
            />
          </Map>
        </YMaps>
      </div>
      <Contacts address={page?.address}/>
    </div>
  );
}

export default MapComponent
