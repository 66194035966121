import React from "react";
import {Link} from "react-router-dom";

function ButtonLink({to, hidden}: { to?: string, hidden?: boolean }) {
  return (
    <Link to={to || '/'} className={`button-link button ${hidden ? 'hidden' : ''}`}>
      <p className="button-link__text">Показать еще</p>
      <svg className="button-link__icon" width="25" height="10">
        <use xlinkHref="images/sprite.svg#arrow"/>
      </svg>
    </Link>
  );
}

export default ButtonLink
