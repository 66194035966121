import React from "react";
import { MatchPost } from "../../../api";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";
import { highlightSearchQuery } from "../../../core/utils/common-helpers";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function MatchItem({ match, query }: { match?: MatchPost; query?: string }) {
  if (!match || !match.match) {
    console.error("Ошибка: Неверные данные матча:", match);
    return null;
  }

  const { team1, team2, gf, ga } = match.match;
  const publishedDate: string | undefined = match.published_dt;
  const formattedDate: string | null = publishedDate
    ? simpleDateFormat(new Date(publishedDate), "dd MMMM yyyy")
    : "Дата не известна";
  const gfClass: string =
    gf && ga !== undefined
      ? gf > ga
        ? "match__number"
        : gf === ga
        ? "match__number match__number--draw"
        : "match__number match__number--lose"
      : "match__number";

  const gaClass: string =
    gf && ga !== undefined
      ? gf < ga
        ? "match__number"
        : gf === ga
        ? "match__number match__number--draw"
        : "match__number match__number--lose"
      : "match__number";

  return (
    <li className="match">
      <Link
        className="match__link"
        to={`${AppRoute.CompetitionNewsPage.url
          .replace(":competitionId", String(match?.competition_id ?? ""))
          .replace(":postId", String(match?.post_id ?? ""))}`}
      >
        <div className="match__content">
          <div className="match__name">
            <HtmlRenderer
              htmlString={highlightSearchQuery(team1 || "", query)}
            />
          </div>
          <div className="match__numbers">
            <p className={gfClass}>{gf}</p>
            <p className="match__points">:</p>
            <p className={gaClass}>{ga}</p>
          </div>
          <div className="match__name">
            <HtmlRenderer
              htmlString={highlightSearchQuery(team2 || "", query)}
            />
          </div>
        </div>
        <div className="match__container">
          <svg className="match__icon" width="15" height="15">
            <use xlinkHref="images/sprite.svg#calendar-blue" />
          </svg>
          <div className="match__date">
            <HtmlRenderer
              htmlString={highlightSearchQuery(formattedDate || "", query)}
            />
          </div>
        </div>
      </Link>
    </li>
  );
}

export default MatchItem;
