import React from "react";
import ContentHint from "../content-hint/content-hint";
import { Album } from "../../../api";
import { simpleDateFormat } from "../../../core/utils/date-time-helpers";
import { Link } from "react-router-dom";
import { AppRoute } from "../../../core/constants/routes";

function ContentItemImg({ album }: { album: Album }) {
  const publishedDate: string | undefined = album.published_dt;
  const formattedDate: string | null = publishedDate
    ? simpleDateFormat(new Date(publishedDate), "dd MMMM yyyy")
    : "Дата не известна";

  return (
    <li className="content__item">
      <Link
        className="content__link"
        to={
          album.source === "internal"
            ? `${AppRoute.Albums.url}/${album.album_id}`
            : `${album.source_url}`
        }
      >
        <div className="content__imgs">
          <img
            className="content__img"
            src={
              album._links?.cover_urls?.["370x210"]?.href ||
              "/images/defaults/photo.svg"
            }
            alt={album.title}
          />
          <ContentHint length={album.photos?.length} />
        </div>
        <div className="content__texts">
          <p className="content__date">{formattedDate}</p>
          <p className="content__title">{album.title}</p>
        </div>
      </Link>
    </li>
  );
}

export default ContentItemImg;
