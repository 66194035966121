import React from "react";
import HtmlRenderer from "../../smart/html-renderer/html-renderer";

function AboutTexts({info}: { info?: string }) {
  return (
    <div className="redactor-styles">
      <HtmlRenderer htmlString={info || 'Информация отсутствует'}/>
    </div>
  )
}

export default AboutTexts
