import React from "react";
import FilterItem3 from "../../smart/filter-item-3/filter-item-3";
import {Competition} from "../../../api";
import Texts from '../texts/texts'

function Filter3({competitionsErrorStatus, competitions, filteredCompetitions}: {
  competitionsErrorStatus: boolean;
  competitions: Competition[];
  filteredCompetitions: Competition[];
}) {
  return (
    <div className="gap">
      <ul className="filter filter--3">
        <FilterItem3/>
      </ul>
      <Texts
        texts="Здесь находятся соревнования нашего портала, но на данный момент они еще не опубликованы."
        text="Ожидайте, так как в скором времени они появятся!"
        hidden={competitionsErrorStatus || competitions.length > 0 || filteredCompetitions.length > 0}
      />
      <Texts texts="Произошла ошибка при загрузке соревнований." hidden={!competitionsErrorStatus}/>
      <Texts
        texts="По заданным параметрам информации не найдено."
        text="Попробуйте другие."
        hidden={!(competitions.length > 0 && !filteredCompetitions.length)}
      />
    </div>
  );
}

export default Filter3;
