import React from 'react';
import {HdbkSportKind} from "../../../api";
import {useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {filteringPostsBySport} from "../../../core/store/default-posts-data/default-posts-data";
import {Option} from "../../../core/types/common";

function TagsItem({sport}: { sport: HdbkSportKind }) {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleTagClick = (selectedOption: Option) => {
    dispatch(filteringPostsBySport({filterSportStatus: selectedOption}));
    navigate('/news')
  };

  return (
    <li className="tags__item">
      <a
        className="tags__link"
        onClick={() => handleTagClick({value: sport.alias, label: sport.title})}
      >
        <p className="tags__blue">#</p>
        <p className="tags__text">{sport.title}</p>
      </a>
    </li>
  );
}

export default TagsItem;
