import { SportsState, State } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";
import { HdbkSportKind } from "../../../api";

export const getSportsLoadingStatus = (state: State): boolean =>
  state[NameSpace.Sports].isSportsLoading;
export const getSportsErrorStatus = (
  state: State
): SportsState["isSportsError"] => state[NameSpace.Sports].isSportsError;
export const getHeaderSports = (state: State): HdbkSportKind[] =>
  state[NameSpace.Sports].headerSports;
export const getFilterSports = (state: State): HdbkSportKind[] =>
  state[NameSpace.Sports].filterSports;
