import React, { useEffect, useState } from "react";
import Crumbs from "../../components/ordinary/crumbs/crumbs";
import Title from "../../components/ordinary/title/title";
import Aside from "../../components/simple/aside/aside";
import Texts from "../../components/ordinary/texts/texts";
import { AppRoute } from "../../core/constants/routes";
import ContentListVideo from "../../components/ordinary/content-list-video/content-list-video";
import { useAppDispatch, useAppSelector } from "../../core/hooks";
import {
  getVideos,
  getVideosErrorStatus,
  getVideosLoadingStatus,
} from "../../core/store/videos-data/selectors";
import { fetchVideos } from "../../core/store/api-actions";
import { VIDEOS_PER_PAGE } from "../../core/constants/common";
import Pagination from "../../components/ordinary/pagination/pagination";
import Loader from "../../components/simple/loader/loader";
import ButtonsPlaylist from "../../components/smart/buttons-playlist/buttons-playlist";
import { useLocation, useNavigate } from "react-router-dom";

function VideosPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const crumbsRoutes = [AppRoute.Main, AppRoute.Videos];
  const videosPerPage = VIDEOS_PER_PAGE;

  const searchParams = new URLSearchParams(location.search);
  const savedPage = parseInt(searchParams.get("page") || "1", 10) - 1;

  const [currentPage, setCurrentPage] = useState(savedPage);
  const [hasMoreVideos, setHasMoreVideos] = useState(true);

  const videos = useAppSelector(getVideos);
  const videosError = useAppSelector(getVideosErrorStatus);
  const videosLoading = useAppSelector(getVideosLoadingStatus);

  useEffect(() => {
    const fetchGetVideos = async () => {
      const result = await dispatch(
        fetchVideos({ page: currentPage, pageSize: videosPerPage })
      ).unwrap();
      if (result.length < videosPerPage) {
        setHasMoreVideos(false);
      } else {
        setHasMoreVideos(true);
      }
    };
    fetchGetVideos();
  }, [dispatch, currentPage]);

  const handlePageClick = (selectedPage: { selected: number }) => {
    const newPage = selectedPage.selected;
    setCurrentPage(newPage);
    navigate(`${location.pathname}?page=${newPage + 1}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="main__wrap">
      <Loader hidden={!videosLoading} />
      <div className={`gap gap--35 ${videosLoading ? "hidden" : ""}`}>
        <Crumbs routes={crumbsRoutes} />
        <div className="gap">
          <ButtonsPlaylist />
          <Title title="Видео" />
          <ContentListVideo videos={videos} />
          <Texts
            texts="Здесь находятся видео нашего портала, но на данный момент они еще не опубликованы. Ожидайте, так как в скором времени они появятся!"
            hidden={videosError || videos.length > 0}
          />
          <Texts
            texts="Произошла ошибка при загрузке видео."
            hidden={!videosError}
          />
        </div>
        <Pagination
          pageCount={hasMoreVideos ? currentPage + 2 : currentPage + 1}
          currentPage={currentPage}
          onPageClick={handlePageClick}
        />
      </div>
      <Aside />
    </div>
  );
}

export default VideosPage;
