import React from "react";
import ButtonsSearchItem from "../../smart/buttons-search-item/buttons-search-item";
import {PostsType} from "../../../core/constants/common";

function ButtonsSearch() {
  return (
    <ul className="buttons">
      {Object.values(PostsType).map((type) => (
        <ButtonsSearchItem key={type.value} type={type}/>
      ))}
    </ul>
  )
}

export default ButtonsSearch;
