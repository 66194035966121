import React from "react";
import {Competition} from "../../../api";
import {Link} from "react-router-dom";
import {AppRoute} from "../../../core/constants/routes";

function CompetitionItem({competition}: { competition: Competition }) {
  return (
    <li className="competition__item">
      <Link className="competition__link" to={`${AppRoute.Competitions.url}/${competition.competition_id}`}>
        <img className="competition__img" src={competition?._links?.logo?.href?.includes('logo_placeholder_thumb.png') ? '/images/defaults/tournament.svg' : competition?._links?.logo?.href} width="200" height="200" alt={competition?.short_name}/>
        <p className="competition__text">{competition?.full_name}</p>
      </Link>
    </li>
  );
}

export default CompetitionItem;
