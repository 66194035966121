import React, {useEffect} from 'react';
import SocialItem from '../../ordinary/social-item/social-item';
import {
  useAppDispatch,
  useAppSelector
} from "../../../core/hooks";
import {fetchSocials} from "../../../core/store/api-actions";
import {getSocials} from "../../../core/store/socials-data/selectors";
import {sortItemsByRank} from "../../../core/utils/array-prepare-helpers";

function Social() {
  const dispatch = useAppDispatch();
  const socials = useAppSelector(getSocials);
  const rankedSocials = sortItemsByRank(socials);

  useEffect(() => {
    dispatch(fetchSocials());
  }, [dispatch]);

  return (
    <ul className={`social ${socials.length === 0 ? 'hidden' : ''}`}>
      {rankedSocials.map((social) => <SocialItem key={social.social_id} social={social}/>)}
    </ul>
  );
}

export default Social;
