import { createSlice } from "@reduxjs/toolkit";
import { fetchMatchPost, fetchMatchPosts } from "../api-actions";
import { MatchPostsState } from "../../types/state";
import { NameSpace } from "../../constants/api-constants";

const initialState: MatchPostsState = {
  isMatchPostsLoading: false,
  isMatchPostLoading: false,
  isMatchPostsError: false,
  isMatchPostError: false,
  matchPosts: [],
  currentMatchPost: null,
};

export const matchPostsData = createSlice({
  name: NameSpace.MatchPosts,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMatchPosts.pending, (state) => {
        state.isMatchPostsLoading = true;
      })
      .addCase(fetchMatchPosts.fulfilled, (state, action) => {
        state.matchPosts = action.payload;
        state.isMatchPostsLoading = false;
      })
      .addCase(fetchMatchPosts.rejected, (state) => {
        state.isMatchPostsError = true;
        state.isMatchPostsLoading = false;
      })
      .addCase(fetchMatchPost.pending, (state) => {
        state.isMatchPostLoading = true;
      })
      .addCase(fetchMatchPost.fulfilled, (state, action) => {
        state.currentMatchPost = action.payload;
        state.isMatchPostLoading = false;
      })
      .addCase(fetchMatchPost.rejected, (state) => {
        state.isMatchPostError = true;
        state.isMatchPostLoading = false;
      });
  },
});
