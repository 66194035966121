import React from "react";
import BallotResult from "../ballot-result/ballot-result";
import {Vote, VoteOption} from "../../../api";
import {
  countVotes,
  getWinner
} from "../../../core/utils/array-prepare-helpers";
import {useAppSelector} from "../../../core/hooks";
import {getSendErrors} from "../../../core/store/votes-data/selectors";

function BallotFormResult({vote, hidden}: { vote: Vote | null, hidden: boolean }) {
  const options = vote?.options;
  const sendVoteErrors = useAppSelector(getSendErrors);
  let maxVotesOption: VoteOption | undefined;
  let votesCount: number = 0;
  if (options || Array.isArray(options)) {
    maxVotesOption = getWinner(options);
    votesCount = countVotes(options);
  }

  return (
    <>
      <form className={`ballot__form ${hidden ? 'hidden' : ''}`}>
        <div className="ballot__container">
          <div className="ballot__content">
            {options?.map((option) => (
              <BallotResult key={option?.option_id} option={option} isWinner={option === maxVotesOption}/>
            ))}
          </div>
          <p className="ballot__subtitle">проголосовало {votesCount} человека</p>
        </div>
      </form>
      <div className={`ballot__helps ${sendVoteErrors?.length === 0 ? 'hidden' : ''}`}>
        <p className="ballot__help">{sendVoteErrors}</p>
      </div>
    </>
  );
}

export default BallotFormResult
