import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import {logos} from "../../../core/constants/common";
import ModalContent from "../modal-component/modal-component";

function WelcomeModal() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentLogoIndex, setCurrentLogoIndex] = useState<number>(0);

  useEffect(() => {
    const modalShown = sessionStorage.getItem('modalShown');
    if (!modalShown) {
      setShowModal(true);
      sessionStorage.setItem('modalShown', 'true');
    }

    const logoInterval = setInterval(() => {
      setCurrentLogoIndex((prevIndex) => (prevIndex + 1) % logos.length);
    }, 3000);

    return () => {
      clearInterval(logoInterval);
    };
  }, []);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setCurrentLogoIndex(0);
  }, []);

  return (
    <div className={`modal ${!showModal ? 'hidden' : ''}`}>
      <ModalContent
        logo={logos[currentLogoIndex]}
        onClose={handleCloseModal}
        showModal={showModal}
      />
    </div>
  );
}

export default WelcomeModal;