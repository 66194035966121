import React from "react";
import HashLinkItem from "../hash-link-item/hash-link-item";
import {Tag} from "../../../api";

function HashLink({tags}: { tags?: Tag[] }) {
  return (
    <ul className={`hashlink ${(tags?.length === 0 || !tags) ? 'hidden' : ''}`}>
      {tags?.map((tag) => <HashLinkItem key={tag.item_id} tag={tag}/>)}
    </ul>
  );
}

export default HashLink;
