import React from "react";
import Reset from "../reset/reset";
import Sorter from "../sorter/sorter";
import DateRangeFilter from "../date-range-filter/date-range-filter";
import {
  ItemsForFilter,
  Sorters
} from "../../../core/constants/filters";
import SportsFilter from "../sports-filter/sports-filter";

function FilterItem() {
  return (
    <>
      <SportsFilter items={ItemsForFilter.Posts}/>
      <li className="filter__item">
        <DateRangeFilter/>
      </li>
      <li className="filter__item">
        <Sorter title={Sorters.Top.label} value={Sorters.Top.value} items={ItemsForFilter.Posts}/>
      </li>
      <li className="filter__item">
        <Sorter title={Sorters.Exclusive.label} value={Sorters.Exclusive.value} items={ItemsForFilter.Posts}/>
      </li>
      <li className="filter__item">
        <Reset items={ItemsForFilter.Posts}/>
      </li>
    </>
  );
}

export default FilterItem;
