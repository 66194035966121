import {VideosState, State} from '../../types/state';
import {NameSpace} from "../../constants/api-constants";
import {Video} from "../../../api";

export const getVideosLoadingStatus = (state: State): boolean => state[NameSpace.Videos].isVideosLoading;
export const getVideoLoadingStatus = (state: State): boolean => state[NameSpace.Videos].isVideoLoading;
export const getVideosErrorStatus = (state: State): VideosState['isVideosError'] => state[NameSpace.Videos].isVideosError;
export const getVideoErrorStatus = (state: State): VideosState['isVideoError'] => state[NameSpace.Videos].isVideoError;
export const getVideos = (state: State): Video[] => state[NameSpace.Videos].videos;
export const getCurrentVideo = (state: State): Video | null => state[NameSpace.Videos].currentVideo;
export const getPlayerVideos = (state: State): Video[] => state[NameSpace.Videos].playerVideos;
